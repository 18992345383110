import React from 'react';
import { Resizable } from 're-resizable';
import { Box } from '@mui/material';

interface Props {
  id: string;
}

const ProCaaSo = (props: Props) => (
  <Box
    component={Resizable}
    defaultSize={{
      width: 255,
      height: 91,
    }}
    lockAspectRatio
    enable={{
      top: false,
      right: false,
      bottom: false,
      left: false,
      topRight: false,
      bottomRight: false,
      bottomLeft: false,
      topLeft: false,
    }}
    handleClasses={{
      bottomRight: 'resize-handle',
    }}
    sx={{
      backgroundImage: `url('/images/library/paracloud.png')`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      '&:hover .resize-handle': {
        display: 'inline-block',
      },
      '&:hover .edit-btn': {
        opacity: 1,
      },
      '& .resize-handle': {
        width: '12% !important',
        height: '30% !important',
        right: '-6% !important',
        bottom: '20% !important',
        display: 'none',
      },
    }}
  />
);

export default ProCaaSo;
