import {
  alpha,
  Box,
  Button,
  CircularProgress,
  IconButton,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';

export const Root = styled(Box)(({ theme }) => ({
  width: '100%',
  border: '1px solid',
  background:
    theme.palette.mode === 'dark'
      ? theme.palette.gradient.main
      : theme.palette.background.paper,
  borderColor: theme.palette.divider,
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  boxSizing: 'border-box',
  [theme.breakpoints.down('md')]: {
    padding: 0,
    paddingTop: theme.palette.mode === 'dark' ? '0.95rem' : 0,
    border: 0,
    background: theme.palette.background.default,
  },
}));

export const Head = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(1),
}));

export const RightAction = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

export const PagerWrap = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginRight: theme.spacing(2),
}));

export const PagerBtn = styled(IconButton)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  width: '2.75rem',
  height: '2.75rem',
  margin: '0 2px',
  '&:first-of-type': {
    marginLeft: 0,
  },
  '&:last-of-type': {
    marginRight: 0,
  },
}));

export const PickerInput = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    height: '36px',
    paddingRight: '1rem',
  },
  '& input': {
    paddingTop: '0.7rem !important',
    paddingBottom: '0.7rem !important',
  },
  [theme.breakpoints.down('md')]: {
    flexBasis: '50%',
    maxWidth: '50%',
    marginLeft: theme.spacing(1),
  },
  '& button': {
    maxWidth: '2.5rem',
    maxHeight: '2.5rem',
    marginRight: 0,
  },
  '&.hidden': {
    maxWidth: 0,
    opacity: 0,
    margin: 0,
  },
}));

export const ScheduleWrap = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  display: 'flex',
  [theme.breakpoints.down('sm')]: {
    overflow: 'auto',
  },
}));

export const Aside = styled(Box)(({ theme }) => ({
  flexGrow: 0,
  borderRight: `1px solid ${theme.palette.divider}`,
  position: 'relative',
}));

export const ScheduleTitle = styled(Box)(({ theme }) => ({
  height: '72px',
  borderBottom: `1px solid ${theme.palette.divider}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.text.secondary,
  fontSize: '1.25rem',
  fontWeight: 600,
}));

export const ScheduleContent = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  overflow: 'hidden',
  [theme.breakpoints.down('sm')]: {
    flex: 'none',
  },
}));

export const ScheduleHead = styled(Box)(({ theme }) => ({
  height: '40px',
  fontSize: '1.125rem',
  color: theme.palette.text.primary,
  textTransform: 'uppercase',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const EmptyWrap = styled(Box)({
  minHeight: '180px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  transform: 'translateX(-40px)',
  '& .icon': {
    color: '#95A3B8',
    fontSize: '2rem',
  },
});

export const EmptyText = styled(Typography)(({ theme }) => ({
  fontSize: '1.25rem',
  color: theme.palette.text.primary,
  fontWeight: 600,
  marginBottom: 0,
}));

export const Time = styled('span')(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '54px',
  // minWidth: '54px',
  height: '32px',
  border: `1px solid ${theme.palette.divider}`,
  borderLeft: 0,
  color: theme.palette.text.secondary,
  fontSize: '1.125rem',
  '@media(min-width: 2032px)': {
    width: '100%',
  },
}));

export const GridWrap = styled(Box)({
  overflow: 'auto',
  minHeight: '180px',
  '&.empty': {
    overflow: 'hidden',
  },
});

export const Row = styled(Box)({
  display: 'flex',
  flexWrap: 'nowrap',
  position: 'relative',
  width: '1296px',
  '@media(min-width: 2032px)': {
    width: '100%',
  },
});
export const Projects = styled(Box)(({ theme }) => ({
  '&.empty': {
    position: 'absolute',
    top: '72px',
    left: 0,
    bottom: 0,
    right: -2,
    borderTop: `1px solid ${theme.palette.divider}`,
  },
}));
export const RowTitle = styled(Link)(({ theme }) => ({
  height: '36px',
  whiteSpace: 'nowrap',
  borderBottom: `1px solid ${theme.palette.divider}`,
  display: 'flex',
  padding: '0 0.75rem',
  alignItems: 'center',
  justifyContent: 'left',
  color: theme.palette.text.secondary,
  fontSize: '1.25rem',
  textDecoration: 'none',
  '@media(min-width: 2032px)': {
    height: '48px',
  },
}));
export const Col = styled('span')(({ theme }) => ({
  height: '36px',
  borderBottom: `1px solid ${theme.palette.divider}`,
  borderRight: `1px solid ${theme.palette.divider}`,
  display: 'flex',
  width: '54px',
  '@media(min-width: 2032px)': {
    width: '4.16667%',
    height: '48px',
  },
}));

export const Progress = styled(CircularProgress)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  '&:before': {
    content: 'close-quote',
    display: 'block',
    width: '12px',
    height: '12px',
    position: 'absolute',
    zIndex: -1,
    borderRadius: '50%',
    border: `2px solid ${theme.palette.divider}`,
  },
}));

export const Now = styled(Box)(() => ({}));

export const Run = styled(Box)(({ theme }) => ({
  position: 'absolute',
  backgroundColor: theme.palette.bg.light,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0 8px',
  borderRadius: theme.shape.borderRadius,
  top: '4px',
  bottom: '4px',
  color: theme.palette.primary.text,
  fontSize: '1rem',
  border: `1px solid ${theme.palette.divider}`,
  borderLeft: `6px solid ${theme.palette.divider}`,
  '& a': {
    textDecoration: 'none',
    color: theme.palette.text.primary,
  },
}));

export const SwitchWrap = styled(Box)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.secondary.main, 0.1),
  borderRadius: theme.shape.borderRadius,
  minHeight: '36px',
  padding: '0 2px',
  display: 'inline-flex',
  marginLeft: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    marginLeft: 0,
  },
}));

export const SwitchBtn = styled(Button)(({ theme }) => ({
  height: '32px',
  minHeight: '2rem',
  marginTop: '2px',
  padding: '4px 10px',
  color: theme.palette.text.primary,
  '& span': {
    fontWeight: 600,
  },
  [theme.breakpoints.down('md')]: {
    padding: '6px 8px',
    minHeight: '32px',
    height: '32px',
  },
  '&.active': {
    backgroundColor:
      theme.palette.mode === 'light'
        ? theme.palette.background.default
        : theme.palette.bg.dark,
  },
  '&:first-of-type': {
    marginRight: '4px',
    [theme.breakpoints.down('md')]: {
      marginRight: '1px',
    },
  },
  '&:last-of-type': {
    marginLeft: '4px',
    [theme.breakpoints.down('md')]: {
      marginLeft: '1px',
    },
  },
  '& .icon': {
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
}));

export const LoaderWrap = styled(Box)({
  width: '100%',
  height: '100%',
  minHeight: '200px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
});
