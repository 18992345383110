import { rootApi } from '..';
import { apiBaseUrlV1 } from '../../../env';
import { IProject } from '../../../typescript/interfaces/project.interface';
import { setNewState } from '../../modules/state';

const BASE_URL = `${apiBaseUrlV1('structure/v1')}/projects`;

export const extendedApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getProjects: builder.query<IProject[], void>({
      query: () => `${BASE_URL}`,
      providesTags: ['Projects'],
      transformResponse: (res: IProject[]) =>
        [...res].sort((a, b) => {
          if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
          if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
          return 0;
        }),
    }),
    getProjectById: builder.query<IProject, string>({
      query: (id: string) => `${BASE_URL}/${id}`,
      providesTags: ['CurrentProject'],
    }),
    getProjectDocument: builder.query<IProject, string>({
      query: (id: string) => `${BASE_URL}/${id}/document`,
      providesTags: ['CurrentProject'],
    }),
    createProject: builder.mutation<IProject, IProject>({
      query: (data: IProject) => ({
        url: `${BASE_URL}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Projects', 'Components', 'Connectors'],
      async onQueryStarted(_arg, api) {
        try {
          const { data } = await api.queryFulfilled;
          // `onSuccess` side-effect
          api.dispatch(
            setNewState({ projects: { action: 'create', id: data.id } }),
          );
        } catch (_err) {
          // `onError` side-effect
        }
      },
    }),
    updateProject: builder.mutation<IProject, { id: string; data: IProject }>({
      query: ({ id, data }) => ({
        url: `${BASE_URL}/${id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['Projects', 'CurrentProject'],
    }),
    updateProjectYAML: builder.mutation<IProject, { id: string; data: string }>(
      {
        query: ({ id, data }) => ({
          url: `${BASE_URL}/${id}`,
          method: 'PUT',
          body: data,
          headers: { 'Content-Type': 'application/yaml' },
        }),
        invalidatesTags: ['Projects', 'CurrentProject'],
      },
    ),
    deleteProject: builder.mutation<IProject, string>({
      query: (id: string) => ({
        url: `${BASE_URL}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Projects'],
      async onQueryStarted(arg, api) {
        try {
          // `onSuccess` side-effect
          api.dispatch(
            setNewState({ projects: { action: 'delete', id: arg } }),
          );
        } catch (_err) {
          // `onError` side-effect
        }
      },
    }),
  }),
});

export const {
  useGetProjectsQuery,
  useGetProjectByIdQuery,
  useGetProjectDocumentQuery,
  useCreateProjectMutation,
  useUpdateProjectMutation,
  useUpdateProjectYAMLMutation,
  useDeleteProjectMutation,
} = extendedApi;
