import React from 'react';
import { Box } from '@mui/material';

interface Props {
  children?: React.ReactNode;
  index: string;
  value: string;
  className?: string;
  sx?: any;
}

function TabPanel(props: Props) {
  const { sx, children, className, value, index, ...other } = props;

  if (value !== index) return null;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      className={className}
      sx={sx}
      style={{ height: '100%' }}
      {...other}
    >
      {children}
    </Box>
  );
}

TabPanel.defaultProps = {
  className: '',
  children: '',
  sx: {},
};
export default TabPanel;
