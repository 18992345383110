import { handleActions } from 'redux-actions';
import {
  userLogout,
  setUser,
  updateUser,
  setSelectedProjectId,
  setSelectedSystemId,
  userCleanout,
  setPasswordExpirationInDays,
} from './actions';

const initialState = {
  loading: false,
  updating: false,
  error: null,
  message: null,
  auth: false,
  user: null,
  passwordExpirationInDays: null,
  selectedProjectId: null,
  selectedSystemId: null,
};

const userReducer = handleActions(
  {
    [userCleanout]: (state) => ({ ...state, user: undefined }),
    [setUser]: (state, { payload }) => ({
      ...state,
      user: {
        ...payload,
      },
    }),
    [updateUser]: (state, { payload }) => ({
      ...state,
      user: {
        ...state.user,
        email: payload.email,
        name: `${payload.name.first} ${payload.name.last}`,
      },
    }),
    [setPasswordExpirationInDays]: (state, { payload }) => ({
      ...state,
      passwordExpirationInDays: payload,
    }),
    [setSelectedProjectId]: (state, { payload }) => ({
      ...state,
      selectedProjectId: payload,
    }),
    [setSelectedSystemId]: (state, { payload }) => ({
      ...state,
      selectedSystemId: payload,
    }),
  },
  initialState,
);

export default userReducer;
