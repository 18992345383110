import { rootApi } from '..';
import { apiBaseUrlV1 } from '../../../env';
import { ISystem } from '../../../typescript/interfaces/system.interface';

const BASE_URL = `${apiBaseUrlV1('structure/v1')}/systems`;

export const extendedApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getSystems: builder.query<ISystem[], void>({
      query: () => `${BASE_URL}`,
      providesTags: ['Systems'],
      transformResponse: (res: ISystem[]) =>
        [...res].sort((a, b) => {
          if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
          if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
          return 0;
        }),
      merge(currentCacheData, responseData) {
        return responseData.map((s) => ({
          ...s,
          display: currentCacheData.find(({ id }) => s.id === id)?.display,
          state: currentCacheData.find(({ id }) => s.id === id)?.state,
          machineState: currentCacheData.find(({ id }) => s.id === id)
            ?.machineState,
        }));
      },
    }),
    getSystemById: builder.query<ISystem, string>({
      query: (id) => `${BASE_URL}/${id}`,
      providesTags: ['System'],
      merge(currentCacheData, responseData) {
        return responseData;
      },
    }),
    createSystem: builder.mutation<ISystem, any>({
      query: (data: ISystem) => ({
        url: `${BASE_URL}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [
        'System',
        'Systems',
        'Components',
        'Connectors',
        'CurrentProject',
      ],
    }),
    updateSystem: builder.mutation<ISystem, { id: string; data: ISystem }>({
      query: ({ id, data }) => ({
        url: `${BASE_URL}/${id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['System', 'Systems'],
    }),
    deleteSystem: builder.mutation<ISystem, string>({
      query: (id) => ({
        url: `${BASE_URL}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['System', 'Systems'],
    }),
    updateSystemLock: builder.mutation<any, { id: string; lock: boolean }>({
      query: ({ id, lock }) => ({
        url: `${BASE_URL}/${id}/${lock ? 'lock' : 'unlock'}`,
        method: 'POST',
      }),
      invalidatesTags: ['System', 'Systems', 'CurrentProject'],
    }),
  }),
});

export const {
  useGetSystemsQuery,
  useGetSystemByIdQuery,
  useCreateSystemMutation,
  useUpdateSystemMutation,
  useDeleteSystemMutation,
  useUpdateSystemLockMutation,
} = extendedApi;
