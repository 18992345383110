import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query';
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { environment, ssoStorage } from '../../env';
import { authExpiredRedirectIfNeeded } from '../../utils/helper';
import { tokenParserStorage } from '../../hoc/utils';

const env = environment();

export function prepareHeaders(headers: Headers) {
  if (env !== 'edge') {
    const accessToken = tokenParserStorage({
      storage: localStorage,
      entityName: ssoStorage(),
      searchValue: 'tokens',
    })?.accessToken;
    if (accessToken) {
      headers.set('Authorization', `Bearer ${accessToken}`);
    }
  }
  if (env === 'edge') {
    headers.set('credentials', 'include');
  }
  return headers;
}

const baseQuery = fetchBaseQuery({
  baseUrl: '',
  prepareHeaders,
});

/**
 * Wrapper around fetchBaseQuery that sets Authorization header and handles 401 by redirecting to home screen.
 */
export const wrappedBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);
  authExpiredRedirectIfNeeded(
    result.meta.request.url,
    result.meta.response?.status || result?.error?.status,
  );
  return result;
};

export const isFulfilled = <T>(
  input: PromiseSettledResult<T>,
): input is PromiseFulfilledResult<T> => input.status === 'fulfilled';
