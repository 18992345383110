import { Box, styled, Typography } from '@mui/material';

export const EmptyBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius: theme.shape.borderRadius,
  height: 'calc(100vh - 190px)',
  width: '100%',
  boxSizing: 'border-box',
  '& .icon': {
    color: theme.palette.text.disabled,
    fontSize: '2rem',
  },
}));

export const Bold = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 600,
  marginTop: theme.spacing(1),
  fontSize: '1.25rem',
}));

export const Light = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  textAlign: 'center',
  fontSize: '1.125rem',
  maxWidth: 260,
}));
