import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setEvent } from '../redux/modules/events';

export interface IError {
  data: unknown;
  status: number;
}

export default function useSnackbar() {
  const dispatch = useDispatch();

  const showError = useCallback(
    (message: string, error?: IError | undefined) => {
      if (error && error.status === 403) return;
      dispatch(
        setEvent({
          snackbar: { type: 'Error', message },
        }),
      );
    },
    [],
  );

  const showSuccess = useCallback((message: string) => {
    dispatch(
      setEvent({
        snackbar: { type: 'Success', message },
      }),
    );
  }, []);

  const showInfo = useCallback((message: string) => {
    dispatch(
      setEvent({
        snackbar: { type: 'Info', message },
      }),
    );
  }, []);

  return { showError, showSuccess, showInfo };
}
