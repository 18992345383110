/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

export const identity = (data: any) => data;

export const isFetchBaseQueryErrorType = (
  error: any,
): error is FetchBaseQueryError => error && 'status' in error;

export const isForbiddenError = (error: any) =>
  isFetchBaseQueryErrorType(error) && error.status === 403;

export const isBadGatewayError = (error: any) =>
  isFetchBaseQueryErrorType(error) &&
  (error.status === 502 ||
    ('originalStatus' in error && error.originalStatus === 502));
