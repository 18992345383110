import { useEffect, useMemo } from 'react';
import { useGetTaskDeploymentsQuery } from '../redux/services/packages/api';
import {
  useGetFrameworkDeploymentsQuery,
  useGetSystemStatusQuery,
} from '../redux/services/polling';
import { ISystem } from '../typescript/interfaces/system.interface';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { extendedApi } from '../redux/services/systems/api';
import { IAppState } from '../typescript/interfaces/appstate.interface';
import { setMachineSubscriptionStatusesLoaded } from '../redux/modules/view';

interface ISubscriptionProps {
  system: ISystem;
}

const MachineSubscriptionComponent = (props: ISubscriptionProps) => {
  const { system } = props;

  const isEdgeEnv = useAppSelector(
    (state: IAppState) => state.environment === 'edge',
  );
  const machineStatusLoaded = useAppSelector(
    (state: IAppState) => state.view.machineStatusLoaded,
  );

  const {
    data: frameworkDeployments,
    isLoading: isFrameworkDeploymentsLoading,
  } = useGetFrameworkDeploymentsQuery(undefined, {
    skip: isEdgeEnv,
    selectFromResult: (res) => ({
      ...res,
      data: res?.data?.filter(({ targets }) => targets.includes(system.id)),
    }),
  });
  const { data: heartbeats, isLoading: isHeartbeatsLoading } =
    useGetSystemStatusQuery(undefined, {
      skip: isEdgeEnv,
      selectFromResult: (res) => ({
        ...res,
        data: res.data?.find(({ systemId }) => systemId === system.id),
        error: res.error,
      }),
    });

  const { data: tasksDeployments, isLoading: isTasksDeploymentsLoading } =
    useGetTaskDeploymentsQuery(
      { task: null, target: system.id },
      {
        selectFromResult: (res) => ({
          ...res,
          data: res?.data?.filter(
            ({ targetSystem }) => targetSystem === system.id,
          ),
        }),
        skip: !heartbeats?.online,
      },
    );

  const title = useMemo(() => {
    if (
      heartbeats ||
      frameworkDeployments?.find(({ status }) => status === 'SUCCESS')
    ) {
      if (!frameworkDeployments) return 'Connected';
      if (
        heartbeats &&
        frameworkDeployments.length > 0 &&
        tasksDeployments?.length === 0
      )
        return 'Awaiting ENS';
      return heartbeats?.online ? 'Online' : 'Offline';
    }
    return 'Unpaired';
  }, [frameworkDeployments, tasksDeployments, heartbeats]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (
      !isFrameworkDeploymentsLoading &&
      !isHeartbeatsLoading &&
      !isTasksDeploymentsLoading &&
      !machineStatusLoaded
    )
      dispatch(setMachineSubscriptionStatusesLoaded(true));
  }, [
    isFrameworkDeploymentsLoading,
    isHeartbeatsLoading,
    isTasksDeploymentsLoading,
  ]);

  useEffect(() => {
    dispatch(
      extendedApi.util.updateQueryData(
        'getSystems',
        undefined,
        (systemsDraft) => {
          const newData = systemsDraft.map((s) => {
            if (s.id === system.id) {
              if (s.machineState === title) return s;
              return { ...s, machineState: title };
            }
            return s;
          });
          return newData;
        },
      ),
    );
  }, [title]);

  return null;
};

export default MachineSubscriptionComponent;
