import { AvailableDependencies, PiletMetadataV1 } from 'piral-base/lib/types';
import { createContainersApi } from 'piral-containers';
import { createProtoPiletApi } from 'pc-piral-proto-api';

export function shareDependencies(
  dependencies: AvailableDependencies,
): AvailableDependencies {
  /* eslint-disable global-require */
  /* eslint-disable import/no-extraneous-dependencies */
  /*  The below list of dependencies should roughly match what is
        passed through `pc-piral-instance/packge.json pilet.externals` list and what is 
        found at https://docs.piral.io/guidelines/tutorials/15-share-dependencies#implicit-sharing-from-the-app-shell
    */
  return {
    ...dependencies,
    // Piral default shares
    react: require('react'),
    'react-dom': require('react-dom'),
    'react-router': require('react-router'),
    'react-router-dom': require('react-router-dom'),
    history: require('history'),
    tslib: require('tslib'),
    'path-to-regexp': require('path-to-regexp'),
    '@libre/atom': require('@libre/atom'),
    '@dbeining/react-atom': require('@dbeining/react-atom'),
    // Paracloud specific shares
    'pc-piral-proto-api': require('pc-piral-proto-api'),
    '@mui/lab': require('@mui/lab'),
    '@mui/material': require('@mui/material'),
    '@mui/x-date-pickers': require('@mui/x-date-pickers'),
    graphql: require('graphql'),
    'graphql-tag': require('graphql-tag'),
    moment: require('moment'),
    'react-redux': require('react-redux'),
    recharts: require('recharts'),
    redux: require('redux'),
    'redux-actions': require('redux-actions'),
    'redux-promise-middleware': require('redux-promise-middleware'),
    uplot: require('uplot'),
    'uplot-react': require('uplot-react'),
    'subscriptions-transport-ws': require('subscriptions-transport-ws'),
  };
  /* eslint-enable import/no-extraneous-dependencies */
  /* eslint-enable global-require */
}

export const defaultPlugins = [createContainersApi(), createProtoPiletApi()];

export type ParacloudPiletMetadataConfig = {
  context: Record<string, any>;
} & Record<string, any>;

export interface ParacloudPiletMetadataV1 extends PiletMetadataV1 {
  config: ParacloudPiletMetadataConfig;
}
