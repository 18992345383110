export const capitalizeFirstLetter = (val: string): string =>
  `${val[0].toUpperCase()}${val.substring(1, val.length)}`;

type MainAction = 'create' | 'update';
export const notifications = {
  success: (action: MainAction) => `Site was ${action}d successfully`,
  defaultError: (action: MainAction) => `Failed to ${action} site`,
  permissionsError: (action: MainAction) =>
    `You do not have permission to ${action} a site`,
};

export const findCaseInsensitive = (findIn: string, find: string): boolean =>
  findIn?.toLocaleLowerCase().indexOf(find.toLocaleLowerCase()) > -1;
