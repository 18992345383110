import React from 'react';
import { Resizable } from 're-resizable';
import { Handle, Position } from 'react-flow-renderer';
import { styled, Box } from '@mui/material';

const SVG = styled('svg')({
  width: '100%',
  height: '100%',
});

interface Props {
  id: string;
}

const ControlValve = (props: Props) => (
  <Box
    component={Resizable}
    defaultSize={{
      width: 62.25,
      height: 64.5,
    }}
    lockAspectRatio
    enable={{
      top: false,
      right: false,
      bottom: false,
      left: false,
      topRight: false,
      bottomRight: false,
      bottomLeft: false,
      topLeft: false,
    }}
    handleClasses={{
      bottomRight: 'resize-handle',
    }}
    sx={{
      display: 'flex',
      alignItems: 'flex-start',
      '&:hover .edit-btn': {
        opacity: 1,
      },
      '&:hover .resize-handle': {
        display: 'inline-block',
      },
      '& .resize-handle': {
        width: '20% !important',
        height: '20% !important',
        right: '-10% !important',
        bottom: '5% !important',
        display: 'none',
      },
    }}
  >
    <div>
      <Handle
        className="point"
        type="target"
        position={Position.Left}
        style={{ top: '58%' }}
      />
      <Handle
        className="point"
        type="source"
        position={Position.Right}
        id="a"
        style={{ top: '58%' }}
      />
      <SVG
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 834.18 723.426"
      >
        <defs>
          <linearGradient
            id="linear-gradient"
            x1="0.5"
            y1="1"
            x2="0.5"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stopColor="#ccc" />
            <stop offset="0.16" stopColor="#dbdbdb" />
            <stop offset="0.44" stopColor="#fff" />
            <stop offset="0.61" stopColor="#fbfbfb" />
            <stop offset="0.76" stopColor="#f1f1f1" />
            <stop offset="0.9" stopColor="#dfdfdf" />
            <stop offset="1" stopColor="#ccc" />
          </linearGradient>
        </defs>
        <g
          id="control_valve"
          data-name="control valve"
          transform="translate(-332.91 -252.82)"
        >
          <path
            id="Path_162"
            data-name="Path 162"
            d="M749.79,734.99,333.41,494.59V975.38Z"
            stroke="#000"
            strokeMiterlimit="10"
            strokeWidth="1"
            fill="url(#linear-gradient)"
          />
          <path
            id="Path_163"
            data-name="Path 163"
            d="M750.21,734.99l416.38-240.4V975.38Z"
            stroke="#000"
            strokeMiterlimit="10"
            strokeWidth="1"
            fill="url(#linear-gradient)"
          />
          <path
            id="Path_164"
            data-name="Path 164"
            d="M749.79,734.99,740,729.34V325.64h20v403.7Z"
          />
          <path
            id="Path_165"
            data-name="Path 165"
            d="M903.18,336.11H596.78L620.2,310.3A175.61,175.61,0,0,1,750,252.82h.15A175.66,175.66,0,0,1,880,310.51Zm-260.75-20H857.59a155.58,155.58,0,0,0-107.45-43.29H750a155.62,155.62,0,0,0-107.57,43.29Z"
          />
        </g>
      </SVG>
    </div>
  </Box>
);

export default ControlValve;
