import { tokenParserStorage } from './hoc/utils';
import { EEnvironment } from './typescript/interfaces/environment.enum';

export type EnvVars = {
  apiPort: string;
  apiProtocol: string;
  apiDomain: string;
  apiDomainV1: string;
  apiGraphqlSubscriptionProtocol: string;
  environment: EEnvironment;
  oauth2ClientId: string;
  demoMode: boolean;
  mockApi: boolean;
  fnsSystemId: string;
};

const isLocalhost: boolean = window.location.hostname === 'localhost';
export const token = tokenParserStorage({
  storage: localStorage,
  entityName: ssoStorage(),
  searchValue: 'tokens',
})?.accessToken;

function getEnv(name: string, fallback?: string): string {
  /* eslint-disable dot-notation */
  const windowEnv = window['_env_'] && window['_env_']?.[name];
  if (windowEnv && windowEnv !== 'null' && windowEnv !== 'undefined')
    return windowEnv;
  const processEnv = process.env[name];
  if (processEnv && processEnv !== 'null' && processEnv !== 'undefined')
    return processEnv;
  return fallback;
}

export const envVars: EnvVars = {
  apiPort: getEnv('REACT_APP_API_PORT'),
  apiProtocol: getEnv('REACT_APP_API_PROTOCOL'),
  apiDomain: getEnv('REACT_APP_API_DOMAIN'),
  apiDomainV1: getEnv('REACT_APP_API_DOMAIN_V1'),
  apiGraphqlSubscriptionProtocol: getEnv(
    'REACT_APP_API_GRAPHQL_SUBSCRIPTION_PROTOCOL',
  ),
  environment: getEnv('REACT_APP_ENVIRONMENT', 'cloud') as EEnvironment,
  oauth2ClientId: getEnv('REACT_APP_OAUTH2_CLIENT_ID', 'procaaso-cloud-ui'),
  demoMode: getEnv('REACT_APP_DEMO_MODE', 'false')?.toLowerCase() === 'true',
  mockApi: getEnv('REACT_APP_MOCK_API')?.toLowerCase() === 'true',
  fnsSystemId: getEnv('REACT_APP_FNS_SYSTEM_ID'),
};

type OriginDefaults = {
  defaultProto?: string;
  protoEnvVar?: string;
};
const originDefaults: OriginDefaults = {
  defaultProto: 'http',
  protoEnvVar: 'REACT_APP_API_PROTOCOL',
};

export function getOrigin(defaults: OriginDefaults = originDefaults): string {
  const { defaultProto, protoEnvVar }: OriginDefaults = {
    ...originDefaults,
    ...defaults,
  };
  const proto = getEnv(protoEnvVar, defaultProto);
  const domain =
    envVars.environment !== 'edge'
      ? getEnv('REACT_APP_API_DOMAIN', window.location.hostname || 'localhost')
      : window.location.hostname || 'localhost';
  const port = getEnv('REACT_APP_API_PORT', '');

  return `${proto}://${domain}${port}`;
}

export function apiBaseUrl(serviceAlias: string): string {
  // Exactly the same as apiBaseUrlV1,
  // but keeping the separate definition for now.
  return `${getOrigin()}/api/${serviceAlias}`;
}

export function apiBaseUrlV1(serviceAlias: string): string {
  // Exactly the same as apiBaseUrl,
  // but keeping the separate definition for now.
  return `${getOrigin()}/api/${serviceAlias}`;
}

export function graphqlQueryUrl(serviceAlias: string): string {
  return `${getOrigin()}/api/${serviceAlias}/graphql/`;
}

export function graphqlSubscriptionUrl(serviceAlias: string): string {
  const tokenLink = `?token=${token}`;
  return `${getOrigin({
    protoEnvVar: 'REACT_APP_API_GRAPHQL_SUBSCRIPTION_PROTOCOL',
    defaultProto: 'ws',
  })}/api/${serviceAlias}/graphql${isLocalhost ? tokenLink : ''}`;
}

export function ssoUrl(): string {
  const proto = getEnv('REACT_APP_API_PROTOCOL', 'http');
  const domain = getEnv(
    'REACT_APP_API_DOMAIN',
    window.location.hostname || 'localhost',
  );
  return `${proto}://sso.${domain}`;
}

/** Depending on environment, Atreus API will be accessible either on a subdomain or not.
 * - cloud: API is accessible in `sso` subdomain
 * - edge: API is accessible in the base domain
 *
 * Default to cloud behavior.
 */
export function atreusApiUrl(): string {
  const isEdge = envVars.environment === 'edge';
  const ssoSubdomain = isEdge ? '' : 'sso.';
  const proto = getEnv('REACT_APP_API_PROTOCOL', 'http');
  const domain = isEdge
    ? window.location.hostname || 'localhost'
    : getEnv('REACT_APP_API_DOMAIN', window.location.hostname || 'localhost');
  return `${proto}://${ssoSubdomain}${domain}/api/atreus/v1`;
}

export function oauth2ClientId(): string {
  return getEnv('REACT_APP_OAUTH2_CLIENT_ID', 'procaaso-cloud-ui');
}

export function environment(): EEnvironment {
  return getEnv('REACT_APP_ENVIRONMENT', 'cloud') as EEnvironment;
}

export function ssoStorage(): string {
  return `oidc.default`;
}
