import { rootApi } from '..';
import { apiBaseUrlV1 } from '../../../env';

interface IControlSystem {
  id: string;
  name: string;
  structureId: string;
}

interface IControlSystemFeed {
  name: string;
  link: string;
  config: {
    context: {
      [_: string]: {
        id: string;
      };
    };
  };
  view: {
    name: string;
    description: string;
  };
}

export const BASE_URL = `${apiBaseUrlV1('control/v1')}/systems`;

export const extendedApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getControlSystems: builder.query<IControlSystem[], void>({
      query: () => `${BASE_URL}`,
      providesTags: ['ControlSystems'],
    }),
    getControlSystemById: builder.query<IControlSystem, string>({
      query: (id) => `${BASE_URL}/${id}`,
      providesTags: ['ControlSystem'],
    }),
    getControlSystemFeedById: builder.query<IControlSystemFeed[], string>({
      query: (id) => `${BASE_URL}/${id}/feed?mode=CUSTOM`,
      providesTags: ['ControlSystemFeed'],
    }),
  }),
});

export const {
  useGetControlSystemsQuery,
  useGetControlSystemByIdQuery,
  useGetControlSystemFeedByIdQuery,
} = extendedApi;
