import React, { useMemo } from 'react';
import { Resizable } from 're-resizable';
import { Handle, Position } from 'react-flow-renderer';
import { Box, styled } from '@mui/material';
import { useGetComponentsQuery } from '../../../../redux/services/components/api';
import { OldComponentData } from '../../../../typescript/interfaces/oldComponentData.interface';

const SVG = styled('svg')({
  width: '100%',
  height: '100%',
});

interface Props {
  id: string;
  data?: OldComponentData;
}

function SolenoidValve(props: Props) {
  const { id, data } = props;

  const { data: components } = useGetComponentsQuery(
    {
      systemId: data?.data?.systemId,
    },
    {
      skip: data?.data?.systemId,
    },
  );

  const component = useMemo(
    () => components?.find((c: any) => c.id === id),
    [components, id],
  );
  const { name } = component || { name: 'Solenoid Valve' };

  return (
    <Box
      component={Resizable}
      defaultSize={{
        width: 65,
        height: 65,
      }}
      lockAspectRatio
      enable={{
        top: false,
        right: false,
        bottom: false,
        left: false,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false,
      }}
      handleClasses={{
        bottomRight: 'resize-handle',
      }}
      sx={{
        position: 'relative',
        display: 'flex',
        alignItems: 'flex-start',
        '&:hover .edit-btn': {
          opacity: 1,
        },
        '&:hover .resize-handle': {
          display: 'inline-block',
        },
        '& .resize-handle': {
          width: '20% !important',
          height: '20% !important',
          right: '-10% !important',
          bottom: '-16% !important',
          display: 'none',
        },
      }}
    >
      <div>
        <Handle
          className="point"
          type="target"
          position={Position.Left}
          style={{ top: '72%' }}
        />
        <Handle
          className="point"
          type="source"
          position={Position.Right}
          id="a"
          style={{ top: '72%' }}
        />
        <SVG
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          // width="65"
          // height="65"
          viewBox="0 0 834.18 866.016"
        >
          <defs>
            <linearGradient
              id="linear-gradient"
              x1="0.5"
              y1="1"
              x2="0.5"
              gradientUnits="objectBoundingBox"
            >
              <stop offset="0" stopColor="#ccc" />
              <stop offset="0.16" stopColor="#dbdbdb" />
              <stop offset="0.44" stopColor="#fff" />
              <stop offset="0.61" stopColor="#fbfbfb" />
              <stop offset="0.76" stopColor="#f1f1f1" />
              <stop offset="0.9" stopColor="#dfdfdf" />
              <stop offset="1" stopColor="#ccc" />
            </linearGradient>
          </defs>
          <g
            id="solenoid_Solenoid"
            data-name="solenoid valve"
            transform="translate(-332.91 -110.23)"
          >
            <path
              id="Path_162"
              data-name="Path 162"
              d="M749.79,734.99,333.41,494.59V975.38Z"
              stroke="#000"
              strokeMiterlimit="10"
              strokeWidth="1"
              fill="url(#linear-gradient)"
            />
            <path
              id="Path_163"
              data-name="Path 163"
              d="M750.21,734.99l416.38-240.4V975.38Z"
              stroke="#000"
              strokeMiterlimit="10"
              strokeWidth="1"
              fill="url(#linear-gradient)"
            />
            <path
              id="Path_164"
              data-name="Path 164"
              d="M750,734.99l-9.79-5.65V391.64h20v337.7Z"
            />
            <rect
              id="Rectangle_448"
              data-name="Rectangle 448"
              width="258.21"
              height="277.4"
              transform="translate(621.11 114.23)"
              fill="#fff"
              stroke="#000"
              strokeMiterlimit="10"
              strokeWidth="8"
            />
          </g>
        </SVG>

        <Box
          sx={{
            display: 'block',
            position: 'absolute',
            zIndex: 99,
            left: '25%',
            right: '25%',
            top: '8%',
            lineHeight: 1,
            textAlign: 'center',
            color: 'primary.main',
            '& svg': {
              width: '100%',
            },
          }}
        >
          <svg viewBox="0 0 38 18">
            <text
              fontFamily="Source Sans Pro', sans-serif"
              fill="#334155"
              x="50%"
              y="50%"
              dominantBaseline="middle"
              textAnchor="middle"
            >
              {name}
            </text>
          </svg>
        </Box>
      </div>
    </Box>
  );
}
SolenoidValve.defaultProps = {
  data: {},
};

export default SolenoidValve;
