import { handleActions } from 'redux-actions';
import {
  setBreadcrumb,
  setBreadcrumbsBasedOnLayer,
  setBreadcrumbsInitialState,
} from '.';
import { ROUTES_HIERARCHY } from '../../../constants';

const storedState = JSON.parse(sessionStorage.getItem('breadcrumbs')) || [];

const initialState = storedState;

const payloadWithLayer = (payload) => {
  console.info({ payload });
  const payloadLayer = Object.entries(ROUTES_HIERARCHY).find(
    ([_, layerHierrarchy]) =>
      layerHierrarchy.find((layerUrl) => payload?.url.indexOf(layerUrl) > -1),
  );
  console.info({ payloadLayer });
  return { ...payload, layer: +payloadLayer[0] };
};

const breadcrumbsReducer = handleActions(
  {
    [setBreadcrumb]: (state, { payload }) => {
      const newEntry = payloadWithLayer(payload[0]);
      if (!state) return [payload];
      if (state?.find(({ url }) => url === payload.url)) return state;

      const filteredState = state?.filter(
        ({ layer }) => layer < newEntry.layer,
      );

      const sortedState = [...filteredState, newEntry].sort((a, b) => {
        if (a.layer > b.layer) return 1;
        if (a.layer < b.layer) return -1;
        return 0;
      });

      return sortedState;
    },
    [setBreadcrumbsInitialState]: (_, { payload }) => {
      if (payload?.length === 0) return payload;
      const newEntry = payloadWithLayer(payload[0]);

      return [newEntry];
    },
    [setBreadcrumbsBasedOnLayer]: (state, { payload }) => {
      const newState = state.filter(({ layer }) => layer <= payload);
      return newState;
    },
  },
  initialState,
);

export default breadcrumbsReducer;
