export default [
  {
    deployedAt: '1632845933711',
    files: [
      {
        lastModified: 1622134263879,
        name: 'CNS1.zip',
        path: 'CNS1.zip',
        size: 6493174,
        type: 'application/x-zip-compressed',
        webkitRelativePath: '',
      },
    ],
    name: 'CNS 1',
    systemDeployedId: [],
    _id: '1632845933711',
  },
  {
    deployedAt: '1632845933712',
    files: [
      {
        lastModified: 1622134263879,
        name: 'CNS2.zip',
        path: 'CNS2.zip',
        size: 6493174,
        type: 'application/x-zip-compressed',
        webkitRelativePath: '',
      },
    ],
    name: 'CNS 2',
    systemDeployedId: [],
    _id: '1632845933712',
  },
];
