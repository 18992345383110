import React from 'react';
import { Tabs, Tab, styled, alpha, Box } from '@mui/material';
import { a11yProps } from '../../../utils/helper';

interface Props {
  onChange: any;
  value: string;
}

const TabsWrap = styled(Box)(({ theme }) => ({
  bottom: 0,
  backgroundColor: theme.palette.background.default,
  left: 0,
  position: 'fixed',
  right: 0,
  zIndex: 99,
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'light'
      ? alpha(theme.palette.secondary.main, 0.1)
      : theme.palette.background.default,
  borderBottom: 0,
  border:
    theme.palette.mode === 'light' ? 0 : `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  minHeight: '3.1rem',
  padding: '4px',
  display: 'inline-flex',
  marginBottom: 0,
  [theme.breakpoints.down('md')]: {
    borderBottom: 0,
    borderLeft: 0,
    borderRadius: 0,
    borderRight: 0,
    padding: 0,
    width: '100%',
  },
  '& .MuiTabs-indicator': {
    display: 'none',
    padding: 0,
    [theme.breakpoints.down('md')]: {
      bottom: 'auto',
      top: 0,
      display: 'inline',
    },
  },
  '& .MuiTabs-scroller': {
    overflow: 'auto !important',
  },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  minHeight: '2.5rem',
  padding: '2px 12px',
  fontSize: '1.125rem !important',
  color: theme.palette.text.secondary,
  '&.Mui-selected': {
    backgroundColor:
      theme.palette.mode === 'light'
        ? theme.palette.background.default
        : theme.palette.bg.dark,
    borderRadius: theme.shape.borderRadius,
    color:
      theme.palette.mode === 'light'
        ? theme.palette.secondary.main
        : theme.palette.text.primary,
    border:
      theme.palette.mode === 'light' ? 0 : `1px solid ${theme.palette.divider}`,
    opacity: 1,
    fontWeight: 600,
  },
  '&.hidden': {
    opacity: 0,
    width: 0,
    padding: 0,
    minWidth: '0px !important',
  },
  [theme.breakpoints.down('md')]: {
    minHeight: '3.1rem',
    flexGrow: 1,
    '&.Mui-selected': {
      borderRadius: 0,
    },
  },
}));

function ConfigTabs(props: Props) {
  const { onChange, value } = props;
  return (
    <TabsWrap>
      <StyledTabs
        sx={{ display: 'flex' }}
        value={value}
        onChange={onChange}
        aria-label="project config tabs"
        variant="fullWidth"
      >
        <StyledTab label="Editor" {...a11yProps('0')} />
        <StyledTab label="Diagram" {...a11yProps('1')} />
      </StyledTabs>
    </TabsWrap>
  );
}

export default ConfigTabs;
