import React, { ReactNode } from 'react';
import {
  Box,
  DialogActions,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { CancelBtn, Modal, SaveBtn } from './styles';

interface DialogComponentProps {
  isOpened: boolean;
  closeModal: () => void;
  title?: string | ReactNode | React.JSX.Element;
  actionTitle: string;
  titleRightSideComponent?: ReactNode;
  handleAction?: () => void;
  children: ReactNode;
  thirdAction?: ReactNode | React.JSX.Element;
  isCancelButtonDisplayed?: boolean;
  isActionButtonDisabled?: boolean;
  isActionButtonDisplayed?: boolean;
  sx?: any;
  disableFullscreen?: boolean;
  cancelId?: string;
  actionId?: string;
  cancelButtonTitle?: string;
  forFullscreen?: boolean;
}

const DialogComponent = (props: DialogComponentProps) => {
  const {
    isOpened,
    closeModal,
    handleAction,
    title,
    actionTitle,
    titleRightSideComponent,
    cancelButtonTitle,
    isCancelButtonDisplayed,
    isActionButtonDisabled,
    isActionButtonDisplayed,
    children,
    disableFullscreen,
    sx,
    cancelId,
    actionId,
    thirdAction,
    forFullscreen,
  } = props;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Modal
      open={isOpened}
      fullScreen={disableFullscreen ? false : fullScreen}
      onClose={closeModal}
      aria-labelledby="dialog-component"
      aria-describedby="dialog-component-description"
      container={
        forFullscreen ? () => document.querySelector('.fullscreen') : undefined
      }
      sx={sx}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        {title && (
          <DialogTitle
            id="dialog-component"
            sx={{ display: 'flex', justifyContent: 'space-between' }}
          >
            {title}
          </DialogTitle>
        )}
        {titleRightSideComponent && (
          <Box sx={{ pt: '2rem', pr: '2rem' }}>{titleRightSideComponent}</Box>
        )}
      </Box>
      {children}
      <DialogActions>
        {thirdAction}
        {isCancelButtonDisplayed && (
          <CancelBtn
            className={disableFullscreen && 'fullscreen-disabled'}
            variant="outlined"
            color="inherit"
            onClick={closeModal}
            id={cancelId}
          >
            {cancelButtonTitle}
          </CancelBtn>
        )}
        {isActionButtonDisplayed && (
          <SaveBtn
            variant="contained"
            onClick={handleAction}
            disabled={isActionButtonDisabled}
            className={disableFullscreen && 'fullscreen-disabled'}
            id={actionId}
          >
            {actionTitle}
          </SaveBtn>
        )}
      </DialogActions>
    </Modal>
  );
};

DialogComponent.defaultProps = {
  isCancelButtonDisplayed: true,
  isActionButtonDisabled: false,
  isActionButtonDisplayed: true,
  disableFullscreen: false,
  sx: {},
  cancelButtonTitle: 'Cancel',
  cancelId: '',
  actionId: '',
  handleAction: () => null,
  title: undefined,
  titleRightSideComponent: undefined,
  thirdAction: undefined,
  forFullscreen: false,
};

export default React.memo(DialogComponent);
