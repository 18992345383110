import { styled } from '@mui/material';
import ReactFlow, { ControlButton } from 'react-flow-renderer';
import { SURFACE } from '../../../colors';

export const ControlBtn = styled(ControlButton)(({ theme }) => ({
  width: '2.125rem !important',
  height: '2.125rem !important',
  color: theme.palette.text.primary,
  '& .fullscreen-icon': {
    fontSize: '26px',
  },
}));

export const ReactFlowStyled = styled(ReactFlow)({
  backgroundColor: SURFACE.main,
  '& .react-flow__attribution': {
    display: 'none',
  },
  '& .react-flow__handle': {
    '&.nodrag': {
      cursor: 'auto',
    },
  },
});
