export enum SoftwareType {
  ens = 'ens',
  ensp = 'ensp',
  cns = 'cns',
  uns = 'uns',
}

export const softwareTypeName = {
  [SoftwareType.ens]: 'ENS',
  [SoftwareType.ensp]: 'ENSp',
  [SoftwareType.cns]: 'CNS',
  [SoftwareType.uns]: 'UNS',
};

export const softwareTypeDescription = {
  [SoftwareType.ens]: 'Extremity Nervous System (edge control and IO)',
  [SoftwareType.ensp]: 'Extremity Nervous System Prosthetic (ENS simulator)',
  [SoftwareType.cns]:
    'Central Nervous System (batch control, ENS orchestration)',
  [SoftwareType.uns]: 'Union Nervous System (cloud hosted software)',
};

export type ControlPackageState =
  | 'CREATED'
  | 'PROCESSING'
  | 'DEPLOYABLE'
  | 'ERROR'
  | 'NOT DEPLOYABLE';
export interface ControlPackage {
  archived: boolean;
  id: string;
  name: string;
  createdAt: string;
  type: SoftwareType;
  state: ControlPackageState;
  description?: string;
  artifactLabels?: {
    ecrImageVersion?: string;
    ecrRepositoryName?: string;
    hash?: string;
    version: string;
  };
  project?: string;
}

export interface ControlTask {
  id: string;
  createdAt: string;
  createdBy: string;
  modifiedAt: string;
  modifiedBy: string;
  version: number;
  name: string;
  description: string;
  package: string;
  taskType: string;
  taskMode: string;
  ecrRepositoryName: string;
  ecrImageTag: string;
}

export interface ControlView {
  id: string;
  createdAt: string;
  createdBy: string;
  modifiedAt?: string;
  modifiedBy?: string;
  version: number;
  name: string;
  description?: string;
  package: string;
  typeCategory: ControlViewType;
  contextMap?: any;
  bundle: string;
}

export enum ControlViewType {
  CUSTOM = 'CUSTOM',
  HEADER = 'HEADER',
  DOCK_BOTTOM_CUSTOM = 'DOCK_BOTTOM_CUSTOM',
  DOCK_LEFT_CUSTOM = 'DOCK_LEFT_CUSTOM',
  DOCK_RIGHT_CUSTOM = 'DOCK_RIGHT_CUSTOM',
  DOCK_BOTTOM_SYSTEM = 'DOCK_BOTTOM_SYSTEM',
  DOCK_LEFT_SYSTEM = 'DOCK_LEFT_SYSTEM',
  DOCK_RIGHT_SYSTEM = 'DOCK_RIGHT_SYSTEM',
  SYSTEM = 'SYSTEM',
  COMPONENT = 'COMPONENT',
  CONNECTOR = 'CONNECTOR',
  INSTRUMENT = 'INSTRUMENT',
  ATTRIBUTE = 'ATTRIBUTE',
}

export interface ControlPackageDetails {
  pkg: ControlPackage;
  tasks: ControlTask[];
  views: ControlView[];
}

export type ControlTaskDeploymentState =
  | 'INITIATED'
  | 'CREATED'
  | 'PULLING'
  | 'STARTING'
  | 'RUNNING'
  | 'STOPPING'
  | 'TERMINATED';

export interface ControlTaskDeployment {
  id: string;
  createdAt: string;
  createdBy: string;
  modifiedAt: string;
  modifiedBy: string;
  version: number;
  name: string;
  description: string;
  task: string;
  targetSystem: string;
  state: ControlTaskDeploymentState;
  status: string;
  configuration: any;
  terminatedAt: string;
  terminatedDetail: string;
  schemaDocument: any;
  deleted: boolean;
}

export interface ControlTaskDeploymentCreateCommand {
  name: string;
  description?: string;
  task: string;
  targetSystem: string;
  configuration: any;
}

export interface AggregateObject {
  actorId: string;
  aggregateId: string;
  aggregateVersion: number;
  payload: {
    name: string;
    description: string;
    task: string;
    targetSystem: string;
    state: string;
    configuration: any;
  };
  payloadSchemaId: string;
  timestamp: string;
}

export interface TaskDeploymentParams {
  task?: string;
  target?: string;
  deploymentDate?: string;
}

export interface TasksQueryParams {
  taskId?: string;
  packageId?: string;
  deploymentDate?: string;
}

export interface TaskDetails {
  data: {
    data: ControlTaskDeployment[];
    meta: {
      request: any;
      response: any;
    };
  }[];
}
interface ChartDescriptor {
  name: string;
  version: string;
}
export interface CreateFrameworkDeploymentCommand {
  name: string;
  description: string;
  charts: ChartDescriptor[];
  targets: string[];
}

export interface TaskTargets {
  docks: string[];
  headers: string[];
  fullscreen: string[];
  system: string[];
  components: string[];
  connectors: string[];
  instruments: string[];
  attributes: string[];
}

export interface PackageState {
  archiveable: boolean;
}
