import React from 'react';
import { Box } from '@mui/material';

interface Props {
  className?: string;
}

const width = 22;
const height = 150;

function Bar(props: Props) {
  const { className } = props;

  return (
    <Box
      className={className}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        '& svg': {
          maxHeight: '100%',
          maxWidth: '100%',
        },
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width + 2}
        height={height + 2}
      >
        <g transform="translate(0 0)">
          <rect
            width={width}
            height={height}
            rx="4"
            transform="translate(0 0)"
            fill="#4c9fc8"
            opacity="0.15"
          />
          <rect
            width={width}
            height={0}
            rx="4"
            transform="translate(0 0) rotate(180 11 75)"
            fill="#4c9fc8"
          />
        </g>
      </svg>
    </Box>
  );
}

Bar.defaultProps = {
  className: '',
};

export default Bar;
