import React from 'react';
import { Route } from 'react-router-dom';
import { withAuth } from '../hoc';
import withSubscription from '../hoc/withSubscription';

interface IPrivateRouteProps {
  component: typeof React.Component;
  [key: string]: any;
}

const PrivateRoute = ({
  component: Component,
  ...rest
}: IPrivateRouteProps) => (
  <Route {...rest} render={(props) => <Component {...props} />} />
);

export default withAuth(withSubscription(PrivateRoute));
