import { createActions } from 'redux-actions';
import { identity } from '../../utils';

export const {
  setBreadcrumb,
  setBreadcrumbsInitialState,
  setBreadcrumbsBasedOnLayer,
} = createActions({
  SET_BREADCRUMB: identity,
  SET_BREADCRUMBS_INITIAL_STATE: identity,
  SET_BREADCRUMBS_BASED_ON_LAYER: identity,
});
