import React from 'react';
import {
  FormControl,
  OutlinedInput,
  Tooltip,
  Box,
  styled,
} from '@mui/material';
import {
  CloseRounded,
  CodeRounded,
  FullscreenRounded,
} from '@mui/icons-material';
import { SystemIcon } from '../../../icons';
import { SwitchBtn, SwitchWrap } from '../../../common/styles/switch';

const Control = styled(FormControl)(({ theme }) => ({
  width: '100%',
  marginBottom: 0,
  '& .MuiOutlinedInput-root': {
    backgroundColor: theme.palette.mode === 'dark' ? 'transparent' : '',
  },
}));

const LeftContentBlock = ({
  fullscreenLeft,
  configNameInvalid,
  configName,
  handleChange,
  handleEditorMode,
  isEditor,
  isIOS,
  handleLeft,
  setFullscreenLeft,
  FullscreenBtn,
  isEditable,
}: any) => (
  <>
    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
      <Control>
        <OutlinedInput
          error={configNameInvalid}
          value={configName}
          onChange={(event) => handleChange(`${event.target.value}`)}
          placeholder="Enter configuration name"
          id="name"
          fullWidth
          sx={{ height: '44px' }}
          disabled={!isEditable}
        />
      </Control>
      <SwitchWrap sx={{ height: '44px' }}>
        <Tooltip title="Editor view">
          <SwitchBtn
            onClick={handleEditorMode}
            className={isEditor && 'active'}
            color="inherit"
            sx={{ height: '38px !important', width: '38px' }}
          >
            <CodeRounded />
          </SwitchBtn>
        </Tooltip>
        <Tooltip title="Table view">
          <SwitchBtn
            onClick={handleEditorMode}
            className={!isEditor && 'active'}
            color="inherit"
            sx={{ height: '38px !important', width: '38px' }}
          >
            <SystemIcon />
          </SwitchBtn>
        </Tooltip>
      </SwitchWrap>
      {!isIOS ? (
        <>
          {handleLeft.active ? (
            <FullscreenBtn
              className="active"
              onClick={handleLeft.exit}
              id="EditorView_Button-ExitFullscreenView"
            >
              <CloseRounded />
            </FullscreenBtn>
          ) : (
            <FullscreenBtn
              onClick={handleLeft.enter}
              id="EditorView_Button-EnterFullscreenView"
            >
              <FullscreenRounded />
            </FullscreenBtn>
          )}
        </>
      ) : (
        <>
          {fullscreenLeft ? (
            <FullscreenBtn
              className="active"
              onClick={() => setFullscreenLeft(false)}
            >
              <CloseRounded />
            </FullscreenBtn>
          ) : (
            <FullscreenBtn onClick={() => setFullscreenLeft(true)}>
              <FullscreenRounded />
            </FullscreenBtn>
          )}
        </>
      )}
    </Box>
  </>
);

export default LeftContentBlock;
