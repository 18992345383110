import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';
import { AddRounded } from '@mui/icons-material';
import { useGetProjectsQuery } from '../../redux/services/projects/api';
import DialogComponent from './DialogComponent';
import { ModalContent, StyledSelect } from './styles';

interface Props {
  isOpened: boolean;
  closeModal: () => void;
  templateId: string;
  onSelect: (projectId: string) => void;
  onCreate: () => void;
}

const titleModal = 'Select a project';

const SelectProject = (props: Props) => {
  const { isOpened, closeModal, templateId, onSelect, onCreate } = props;

  const [selectedProject, setSelectedProject] = useState<string>('');
  const [validProjects, setValidProjects] = useState([]);

  const { data: projects } = useGetProjectsQuery(undefined);

  const handleChange = (e: SelectChangeEvent<unknown>) => {
    setSelectedProject(e.target.value as string);
  };

  const handleConfirm = () => {
    onSelect(selectedProject);
  };

  useEffect(() => {
    setValidProjects(projects?.filter((p) => p.basedOn === templateId));
  }, [templateId]);

  useEffect(() => {
    if (validProjects?.length === 1 && validProjects[0]) {
      setSelectedProject(validProjects[0].id);
    }
  }, [validProjects]);

  return (
    <DialogComponent
      isOpened={isOpened}
      closeModal={closeModal}
      title={titleModal}
      actionTitle="Select"
      handleAction={handleConfirm}
      isActionButtonDisabled={!selectedProject}
    >
      <ModalContent>
        <Box sx={{ display: 'flex', alignItems: 'flex-end', mb: 4 }}>
          <FormControl sx={{ mb: 0 }} fullWidth>
            <InputLabel shrink htmlFor="site">
              Project
            </InputLabel>
            <StyledSelect
              id="site"
              variant="outlined"
              value={selectedProject}
              onChange={handleChange}
              disabled={validProjects?.length === 0}
            >
              {validProjects?.length > 0 ? (
                validProjects?.map((p) => (
                  <MenuItem key={p.id} value={p.id}>
                    {p.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value={0}>No available projects</MenuItem>
              )}
            </StyledSelect>
          </FormControl>
          <Button
            startIcon={<AddRounded />}
            variant="outlined"
            color="inherit"
            onClick={onCreate}
            sx={{
              minWidth: '6.5rem',
              ml: 1,
              minHeight: '3.2rem',
            }}
          >
            Create
          </Button>
        </Box>
      </ModalContent>
    </DialogComponent>
  );
};

export default SelectProject;
