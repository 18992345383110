import React from 'react';
import { Box } from '@mui/material';

interface Props {
  className?: string;
}

function BooleanIndicator(props: Props) {
  const { className } = props;

  return (
    <Box
      className={className}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        '& svg': {
          maxHeight: '100%',
          maxWidth: '100%',
          width: '10px',
          height: '10px',
        },
      }}
    >
      <svg width="10" height="10" viewBox="0 0 10 10">
        <rect width="10" height="10" />
      </svg>
    </Box>
  );
}

BooleanIndicator.defaultProps = {
  className: '',
};

export default BooleanIndicator;
