import React, { useState, useEffect } from 'react';
import { Handle, Position } from 'react-flow-renderer';
import { Resizable } from 're-resizable';
import { Box, styled } from '@mui/material';
import { useGetPortsQuery } from '../../../../redux/services/ports/api';

const SVG = styled('svg')({
  width: '100%',
  height: '100%',
});

interface Props {
  id: string;
}

const FlowMeterSensor = (props: Props) => {
  const { id } = props;

  const { data: ports } = useGetPortsQuery(undefined);

  const [port, setPort] = useState([]);
  useEffect(() => {
    if (ports) {
      setPort(ports.filter(({ parentAggregate }) => parentAggregate === id));
    }
  }, [ports]);

  return (
    <Box
      component={Resizable}
      defaultSize={{
        width: 80,
        height: 80,
      }}
      lockAspectRatio
      resizeRatio={1}
      enable={{
        top: false,
        right: false,
        bottom: false,
        left: false,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false,
      }}
      handleClasses={{
        bottomRight: 'resize-handle',
      }}
      sx={{
        '&:hover .edit-btn': {
          opacity: 1,
        },
        '&:hover .resize-handle': {
          display: 'inline-block',
        },
        '& .resize-handle': {
          width: '35% !important',
          height: '35% !important',
          right: '0 !important',
          bottom: '-60% !important',
          display: 'none',
        },
      }}
    >
      <div>
        {port.length ? (
          port.map((p, i) => (
            <Handle
              key={p._id}
              className="point"
              type={p.name === 'Outlet' ? 'target' : 'source'}
              position={
                [Object.values(Position)[0], Object.values(Position)[2]][i]
              }
              style={
                [
                  { left: '-2%', top: '70%' },
                  { right: '24%', top: '70%' },
                ][i]
              }
            />
          ))
        ) : (
          <>
            <Handle
              className="point"
              type="target"
              position={Position.Left}
              style={{ left: '-2%', top: '70%' }}
            />
            <Handle
              className="point"
              type="source"
              position={Position.Right}
              id="a"
              style={{ right: '24%', top: '70%' }}
            />
          </>
        )}
        <SVG
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          // width="1007.11"
          // height="1008.4"
          viewBox="0 0 1007.11 1008.4"
        >
          <defs>
            <linearGradient
              id="linear-gradient"
              x2="1"
              y1="0.5"
              y2="0.5"
              gradientUnits="objectBoundingBox"
            >
              <stop offset="0" stopColor="#ccc" />
              <stop offset="0.1" stopColor="#dfdfdf" />
              <stop offset="0.24" stopColor="#f1f1f1" />
              <stop offset="0.39" stopColor="#fbfbfb" />
              <stop offset="0.56" stopColor="#fff" />
              <stop offset="0.84" stopColor="#dbdbdb" />
              <stop offset="1" stopColor="#ccc" />
            </linearGradient>
            <linearGradient
              id="linear-gradient-2"
              x1="0"
              x2="1"
              y1="0.5"
              y2="0.5"
              xlinkHref="#linear-gradient"
            />
            <linearGradient
              id="linear-gradient-3"
              x1="0"
              x2="1"
              y1="-0.94"
              y2="-0.94"
              xlinkHref="#linear-gradient"
            />
            <linearGradient
              id="linear-gradient-4"
              x1="0"
              x2="1"
              y1="0.5"
              y2="0.5"
              xlinkHref="#linear-gradient"
            />
          </defs>
          <g
            stroke="#000"
            data-name="1-02"
            transform="translate(-278.59 -171.28)"
          >
            <path
              fill="url(#linear-gradient)"
              strokeMiterlimit="10"
              strokeWidth="6"
              d="M0 0H91.23V132.9H0z"
              data-name="Rectangle 1"
              transform="translate(743.32 605.92)"
            />
            <circle
              cx="227.7"
              cy="227.7"
              r="227.7"
              fill="url(#linear-gradient-2)"
              strokeMiterlimit="10"
              strokeWidth="6"
              data-name="Ellipse 1"
              transform="translate(554.29 174.28)"
            />
            <path
              fill="url(#linear-gradient-3)"
              strokeMiterlimit="10"
              strokeWidth="6"
              d="M0 0H694.2V307.3H0z"
              data-name="Rectangle 2"
              transform="translate(435.49 812.88)"
            />
            <path
              fill="url(#linear-gradient-4)"
              strokeMiterlimit="10"
              strokeWidth="6"
              d="M1267.19 1176.68H1145a15.51 15.51 0 01-15.5-15.5V782a15.51 15.51 0 0115.5-15.5h122.2a15.51 15.51 0 0115.5 15.5v379.1a15.47 15.47 0 01-15.51 15.58z"
              data-name="Path 1"
            />
            <path
              fill="url(#linear-gradient-2)"
              strokeMiterlimit="10"
              strokeWidth="6"
              d="M419.39 1176.68h-122.3a15.51 15.51 0 01-15.5-15.5V782a15.51 15.51 0 0115.5-15.5h122.2a15.51 15.51 0 0115.5 15.5v379.1a15.45 15.45 0 01-15.4 15.58z"
              data-name="Path 2"
            />
            <path
              fill="url(#linear-gradient-2)"
              strokeMiterlimit="10"
              strokeWidth="6"
              d="M0 0H334.8V105.1H0z"
              data-name="Rectangle 3"
              transform="translate(617.59 717.48)"
            />
            <path
              fill="url(#linear-gradient-3)"
              strokeMiterlimit="10"
              strokeWidth="6"
              d="M0 0H420.1V307.3H0z"
              data-name="Rectangle 4"
              transform="translate(572.49 812.88)"
            />
            <path
              fill="none"
              strokeMiterlimit="10"
              strokeWidth="12"
              d="M0 90.4L0 0"
              data-name="Line 1"
              transform="translate(701.39 723.78)"
            />
            <path
              fill="none"
              strokeMiterlimit="10"
              strokeWidth="12"
              d="M0 90.3L0 0"
              data-name="Line 2"
              transform="translate(792.79 723.18)"
            />
            <path
              fill="none"
              strokeMiterlimit="10"
              strokeWidth="12"
              d="M0 90.3L0 0"
              data-name="Line 3"
              transform="translate(882.19 723.18)"
            />
            <path
              fill="none"
              strokeMiterlimit="10"
              strokeWidth="12"
              d="M152.49 0.74L0 0"
              data-name="Line 4"
              transform="translate(279.99 894.88)"
            />
            <path
              fill="none"
              strokeMiterlimit="10"
              strokeWidth="12"
              d="M152.49 0.84L0 0"
              data-name="Line 5"
              transform="translate(279.99 1043.18)"
            />
            <path
              fill="none"
              strokeMiterlimit="10"
              strokeWidth="12"
              d="M148.5 0L0 0"
              data-name="Line 6"
              transform="translate(1130.49 894.88)"
            />
            <path
              fill="none"
              strokeMiterlimit="10"
              strokeWidth="12"
              d="M148.5 0.1L0 0"
              data-name="Line 7"
              transform="translate(1130.49 1043.18)"
            />
            <circle
              cx="174.9"
              cy="174.9"
              r="174.9"
              fill="#fff"
              strokeMiterlimit="10"
              strokeWidth="12"
              data-name="Ellipse 2"
              transform="translate(609.89 228.58)"
            />
            <path
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="12"
              d="M0 0L19.7 21.8"
              data-name="Line 8"
              transform="translate(885.49 501.88)"
            />
            <path
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="12"
              d="M0 41.8L0 0"
              data-name="Line 9"
              transform="translate(786.19 230.58)"
            />
            <path
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="12"
              d="M31.1 26.4L0 0"
              data-name="Line 10"
              transform="translate(663.69 281.68)"
            />
            <path
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="12"
              d="M0 24.6L22.5 0"
              data-name="Line 11"
              transform="translate(884.79 282.58)"
            />
            <circle
              cx="27.4"
              cy="27.4"
              r="27.4"
              fill="none"
              strokeMiterlimit="10"
              strokeWidth="12"
              data-name="Ellipse 3"
              transform="translate(762.29 408.78)"
            />
            <path
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="12"
              d="M0 51.2L0 0"
              data-name="Line 12"
              transform="translate(788.79 353.98)"
            />
            <path
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="12"
              d="M45.3 0L0 0.8"
              data-name="Line 13"
              transform="translate(611.19 404.28)"
            />
            <path
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="12"
              d="M0 0L35.8 0.7"
              data-name="Line 14"
              transform="translate(914.59 402.98)"
            />
            <path
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="12"
              d="M26.4 0L0 27"
              data-name="Line 15"
              transform="translate(665.69 502.38)"
            />
          </g>
        </SVG>
      </div>
    </Box>
  );
};

export default FlowMeterSensor;
