import React, { useEffect, useRef, useState } from 'react';
import { useCreateIOMapsByTemplateMutation } from '../../redux/services/ioMaps/api';
import { useGetProjectsQuery } from '../../redux/services/projects/api';
import CreateProject from '../../common/Dialogs/CreateProject';
import CreateSystem from '../../common/Dialogs/CreateSystem';
import SelectProject from '../../common/Dialogs/SelectProject';
import useSnackbar from '../../hooks/useSnackbar';

type SetupStep =
  | 'CLOSED'
  | 'SCAN'
  | 'SELECT_PROJECT'
  | 'CREATE_PROJECT'
  | 'CREATE_SYSTEM';

const SystemScanButton = () => {
  const { showSuccess, showError } = useSnackbar();

  const [setupStep, setSetupStep] = useState<SetupStep>('CLOSED');
  const [scanResult] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState<string>(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [createdProjectId, setCreatedProjectId] = useState<string>(null);
  const projectsFetchIntervalRef = useRef<number>();

  const { data: projects, refetch } = useGetProjectsQuery(undefined);

  const [createIOMapsByTemplate, { isError, isSuccess, error }] =
    useCreateIOMapsByTemplateMutation();

  const handleCancel = () => {
    setSelectedProject(null);
    setSelectedTemplate(null);
    setSetupStep('CLOSED');
  };

  useEffect(() => {
    if (selectedTemplate) {
      setSetupStep('SELECT_PROJECT');
    }
  }, [selectedTemplate]);

  const handleProjectSelect = (projectId: string) => {
    setSelectedProject(projectId);
  };

  useEffect(() => {
    if (selectedProject) {
      setSetupStep('CREATE_SYSTEM');
    }
  }, [selectedProject]);

  const handleProjectCreate = () => {
    setSetupStep('CREATE_PROJECT');
  };

  useEffect(() => {
    if (selectedProject) {
      setSetupStep('CREATE_SYSTEM');
    }
  }, [selectedProject]);

  const handleProjectCreated = (projectId: string) => {
    setCreatedProjectId(projectId);
  };

  useEffect(() => {
    if (createdProjectId) {
      if (projectsFetchIntervalRef.current) {
        clearInterval(projectsFetchIntervalRef.current);
      }
      projectsFetchIntervalRef.current = window.setInterval(() => {
        refetch();
      }, 500);
    }
  }, [createdProjectId]);

  useEffect(() => {
    if (projects?.find((p) => p.id === createdProjectId)) {
      clearInterval(projectsFetchIntervalRef.current);
      setSelectedProject(createdProjectId);
    }
  }, [projects]);

  const handleSystemCreated = (systemId: string) => {
    createIOMapsByTemplate({
      templateName: scanResult.templateName,
      systemId,
      ioPairs: scanResult.ioPairs,
    });
  };

  useEffect(() => {
    if (isSuccess) showSuccess('Config created successfully');
  }, [isSuccess]);

  useEffect(() => {
    if (isError)
      // @ts-ignore
      showError(error?.message || error?.details || 'Config create failed');
  }, [isError]);
  return (
    <>
      <SelectProject
        templateId={selectedTemplate}
        isOpened={setupStep === 'SELECT_PROJECT'}
        closeModal={handleCancel}
        onSelect={handleProjectSelect}
        onCreate={handleProjectCreate}
      />
      <CreateProject
        isOpened={setupStep === 'CREATE_PROJECT'}
        templateId={selectedTemplate}
        closeModal={handleCancel}
        onCreate={handleProjectCreated}
      />
      <CreateSystem
        isOpened={setupStep === 'CREATE_SYSTEM'}
        closeModal={handleCancel}
        projectId={selectedProject}
        onCreate={handleSystemCreated}
      />
    </>
  );
};

SystemScanButton.defaultProps = {};

export default SystemScanButton;
