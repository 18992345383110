import { handleActions } from 'redux-actions';
import { setCnsActive, setCnsNewActive } from './actions';
import CNS_MOCK from './mock';

const initialState = {
  loading: false,
  updating: false,
  error: null,
  cns: CNS_MOCK,
};

const cnsReducer = handleActions(
  {
    [setCnsActive]: (state, { payload }) => ({
      ...state,
      cns: [...state.cns, payload],
    }),
    [setCnsNewActive]: (state, { payload }) => ({
      ...state,
      cns: payload,
    }),
  },
  initialState,
);

export default cnsReducer;
