import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import yaml from 'js-yaml';
import {
  useEdgesState,
  useNodesState,
  MiniMap,
  Node,
  useReactFlow,
  Background,
  addEdge,
  updateEdge,
  useNodes,
  NodeChange,
  applyNodeChanges,
} from 'react-flow-renderer';
import { Box, styled } from '@mui/material';
import Controls from './Controls';
import { ReactFlowStyled } from '../styles';
import { parseValue } from '../../../utils/DiagramUtils';
import { useGetVisualsQuery } from '../../../redux/services/visuals/api';
import { nodesTypes } from '../nodeTypes';
import CustomQEdge from '../nodeTypes/library/utils/CustomQEdge';
import HelperLines from './HelperLines';
import { Context } from './context';
import { getHelperLines } from './utils';

const Root = styled(Box)(({ theme }) => ({
  height: '100%',
  width: '100%',
  position: 'relative',
  backgroundColor: '#fff',
  '& .react-flow__node-default': {
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    boxShadow: 'none',
    '&.selectable:hover': {
      boxShadow: 'none',
    },
    '&.selectable.selected': {
      boxShadow: 'none',
    },
  },
  '& .point.target': {
    backgroundColor: theme.palette.success.main,
  },
  '& .point.source': {
    backgroundColor: theme.palette.error.main,
  },
  '& .point': {
    opacity: 1,
  },
  '&.no-edit': {
    cursor: 'move',
    '& .point': {
      opacity: 0,
    },
    '& .edit-btn': {
      display: 'none',
    },
    '& .resize-handle': {
      display: 'none',
    },
  },
  '& .point-component': {
    '& .react-flow__handle': {
      // display: 'none !important',
    },
  },
}));

interface Props {
  value: string;
  toggleGrid: () => void;
  onFullscreenEnter?: () => void;
  onFullscreenExit?: () => void;
  setIsReFitView?: (flag: boolean) => void;
  isEditable?: boolean;
  withFullscreen?: boolean;
  isFullscreen?: boolean;
  isReFitView?: boolean;
  isInteractive: boolean;
  textValue: string;
  handleNodes: (nodes: any, node: any, edges: any) => void;
  handleEdges: (edges: any, operationType: string) => void;
  handleClick: (event: any, node: Node & { id: string }) => void;
  handleConfigNameChange: (name: string) => void;
  handleErrorSet: (error: any) => void;
  setTextValue: (value: React.SetStateAction<string>) => void;
  setIsImport: (bool: boolean) => void;
}

interface Position {
  x: number;
  y: number;
}
interface EdgeDot {
  data: { isHidden: boolean; handleNodeLoading: () => void };
  dragging: boolean;
  height: number;
  id: string;
  position: Position;
  positionAbsolute: Position;
  selected: boolean;
  type: string;
  width: number;
}

const Listner = ({ handleNodesSelector }: any) => {
  const nodesSelector = useNodes();

  useEffect(() => {
    handleNodesSelector(nodesSelector);
  }, [JSON.stringify(nodesSelector)]);

  return null;
};

const isDisabledHelperLines = true;

const CreateTemplateDiagram = (props: Props) => {
  const {
    value,
    isFullscreen,
    isReFitView,
    textValue,
    setIsReFitView,
    onFullscreenExit,
    onFullscreenEnter,
    withFullscreen,
    handleClick,
    handleConfigNameChange,
    handleErrorSet,
    handleNodes,
    handleEdges,
    setTextValue,
    setIsImport,
    isEditable,
  } = props;

  const reactFlowWrapper = useRef(null);
  const [jsonData, setJsonData] = useState();

  useEffect(() => {
    try {
      const jsonValue = yaml.load(value);
      setJsonData(jsonValue);
    } catch (e) {
      handleErrorSet(e);
    }
  }, [value]);

  const [initPosition, setInitPosition] = useState({
    x: undefined,
    y: undefined,
  });

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [helperLineHorizontal, setHelperLineHorizontal] = useState<
    number | undefined
  >(undefined);
  const [helperLineVertical, setHelperLineVertical] = useState<
    number | undefined
  >(undefined);
  const [showGrid, setShowGrid] = useState(false);
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const [nodesSelector, setNodesSelector] = useState([]);
  const [isLoading, setIsLoading] = useState({});

  const { data: visualsSelector } = useGetVisualsQuery();

  const handleNodeLoading = (node: string): void => {
    setIsLoading((prev) => ({ ...prev, [node]: false }));
  };

  useEffect(() => {
    try {
      const jsonValue = yaml.load(value);
      if (!jsonValue) {
        setNodes([]);
        setEdges([]);
        handleConfigNameChange('');
        return undefined;
      }
      const {
        nodes: nodesLocal,
        edges: edgesLocal,
        initialPosition,
      } = parseValue(jsonData, initPosition);

      if (
        initialPosition?.x !== undefined &&
        initialPosition?.x !== initPosition?.x
      ) {
        setInitPosition(initialPosition);
      }

      setNodes((prev) => {
        if (JSON.stringify(prev) !== JSON.stringify(nodesLocal)) {
          if (nodesLocal.length !== prev.length)
            setIsLoading((prevLoadingState) =>
              Object.assign(
                {},
                ...nodesLocal
                  .filter(({ id }) => !id.includes('edgeDot_'))
                  .map(({ id }) => ({ [id]: prevLoadingState[id] !== false })),
              ),
            );
          return nodesLocal.map((n) => {
            if (!n.id.includes('edgeDot_')) {
              return {
                ...n,
                data: {
                  ...n.data,
                  handleNodeLoading,
                  isLoading: isLoading[n.id],
                },
              };
            }
            const [, edgeId] = n.id.split('_');
            const allEdgeNodes = prev.filter((node) =>
              node.id.includes(`edgeDot_${edgeId}`),
            );
            const isHidden =
              allEdgeNodes.findIndex(
                (node) => node?.data.isHidden === false,
              ) === -1;

            return {
              ...n,
              data: {
                ...n.data,
                isHidden,
                handleNodeLoading,
                isLoading: isLoading[n.id],
              },
            };
          });
        }
        return prev;
      });
      setEdges((prev) => {
        if (JSON.stringify(prev) !== JSON.stringify(edgesLocal)) {
          return edgesLocal.map((c) => ({
            ...c,
            type: 'custom',
            data: {
              ...c.data,
              handleClick: (id) => handleDeleteEdge(id),
            },
          }));
        }
        return prev;
      });
    } catch (e) {
      handleErrorSet(e);
    }

    return undefined;
  }, [jsonData, initPosition]);

  const handleDeleteEdge = (id: string) => {
    setEdges((prev) => prev.filter((e) => e.id !== id));
    handleEdges(id, 'remove');
  };

  const handleNodesSelector = (passedNodes: any) => {
    setNodesSelector(passedNodes);
  };

  const onEdgeUpdate = useCallback(
    (oldEdge, newConnection) =>
      setEdges((els) => {
        const newEdges = updateEdge(oldEdge, newConnection, els);
        return newEdges;
      }),
    [],
  );

  const customApplyNodeChanges = useCallback(
    (changes: NodeChange[], ns: Node[]): Node[] => {
      // reset the helper lines (clear existing lines, if any)
      setHelperLineHorizontal(undefined);
      setHelperLineVertical(undefined);

      // this will be true if it's a single node being dragged
      // inside we calculate the helper lines and snap position for the position where the node is being moved to
      if (
        changes.length === 1 &&
        changes[0].type === 'position' &&
        changes[0].dragging &&
        changes[0].position
      ) {
        const helperLines = getHelperLines(changes[0], ns);

        // if we have a helper line, we snap the node to the helper line position
        // this is being done by manipulating the node position inside the change object
        changes[0].position.x =
          helperLines.snapPosition.x ?? changes[0].position.x;
        changes[0].position.y =
          helperLines.snapPosition.y ?? changes[0].position.y;

        // if helper lines are returned, we set them so that they can be displayed
        setHelperLineHorizontal(helperLines.horizontal);
        setHelperLineVertical(helperLines.vertical);
      }

      return applyNodeChanges(changes, ns);
    },
    [],
  );

  const handleNodesChange = (changes: NodeChange[]) => {
    // @ts-ignore
    const { id, type, dragging } = changes[0];
    if (type === 'position' && dragging === false) {
      setIsDraggingOver(true);
      const edgeId = changes[0]?.id.split('_')?.[1];

      if (id.includes(`edgeDot`) && id.includes('extra')) {
        setNodes((prev: any) => {
          const allNodes = prev.filter(
            (node) => !node.id.includes(`edgeDot_${edgeId}`),
          );
          const extraNodes = prev.filter(
            (node) =>
              node.id.includes(`edgeDot_${edgeId}`) &&
              node.id.includes('extra'),
          );
          const mainNodes = prev.filter(
            (node) =>
              node.id.includes(`edgeDot_${edgeId}`) &&
              !node.id.includes('extra'),
          );

          const foundNewMainPoint = extraNodes.find((node) => node.id === id);

          if (foundNewMainPoint) {
            const { prevDot, nextDot } = foundNewMainPoint.data;

            const foundIndexPrevDot = mainNodes.findIndex(
              (node) => node.id === prevDot,
            );
            const foundIndexNextDot = mainNodes.findIndex(
              (node) => node.id === nextDot,
            );

            const newMainPoint = {
              id: `edgeDot_${edgeId}_${mainNodes.length}`,
              position: foundNewMainPoint.position,
              type: 'edgeDot',
              data: { isHidden: false },
            };

            if (foundIndexPrevDot !== -1 && foundIndexNextDot === -1) {
              mainNodes.splice(foundIndexPrevDot + 1, 0, newMainPoint);
            } else if (foundIndexNextDot !== -1) {
              mainNodes.splice(foundIndexNextDot, 0, newMainPoint);
            } else if (foundIndexPrevDot === -1 && foundIndexNextDot === -1) {
              return [...allNodes, ...mainNodes, newMainPoint];
            }

            return [
              ...allNodes,
              ...mainNodes.map((node, i) => {
                const [, eId] = node.id.split('_');

                return {
                  ...node,
                  id: `edgeDot_${eId}_${i}`,
                };
              }),
            ];
          }

          return prev;
        });
      }
      if (!isDisabledHelperLines) {
        setNodes((prev) => customApplyNodeChanges(changes, prev));
      }
    }
    onNodesChange(changes);
  };

  const reactFlowInstance = useReactFlow();

  useEffect(() => {
    setIsDraggingOver(false);
    handleNodes('', nodesSelector, edges);
  }, [nodesSelector.length, isDraggingOver]);

  const nodeTypes = useMemo(() => nodesTypes, []);
  const edgeTypes = useMemo(() => ({ custom: CustomQEdge }), []);

  const handleConnect = useCallback(
    (params) =>
      // @ts-ignore
      setEdges((eds) => {
        handleEdges(params, 'add');
        return addEdge(params, eds);
      }),
    [setEdges],
  );

  useEffect(() => {
    if (reactFlowInstance || isReFitView) {
      setTimeout(() => {
        reactFlowInstance.fitView();
        setIsReFitView(false);
      }, 50);
    }
  }, [isFullscreen, isReFitView]);

  useEffect(() => {
    if (reactFlowInstance) {
      setTimeout(() => {
        reactFlowInstance.fitView();
      }, 50);
    }
  }, [
    JSON.stringify(
      Object.entries(isLoading)?.filter(([key]) => key.indexOf('edgeDot_')),
    ),
  ]);

  const [currentZoom, setCurrentZoom] = useState(1);
  const onMove = useCallback((e, { zoom }) => {
    setCurrentZoom(zoom);
  }, []);

  const contextValue = useMemo(
    () => ({ setNodes, zoom: currentZoom, nodesDraggable: true }),
    [setNodes, currentZoom],
  );

  const onPaneClick = useCallback(() => {
    setNodes((prev: any) =>
      prev.map((node) =>
        node.id.includes('edgeDot')
          ? { ...node, data: { ...node.data, isHidden: true } }
          : node,
      ),
    );
    handleClick('', undefined);
  }, [handleClick]);

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();

      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const newVisualId = event.dataTransfer.getData('application/reactflow');

      // check if the dropped element is valid
      if (typeof newVisualId === 'undefined' || !newVisualId) {
        return;
      }

      const { x, y } = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });

      setIsImport(true);
      const selectedComponent = visualsSelector.find(
        (v) => newVisualId === v.id,
      );

      const { components, visuals } = yaml.load(textValue);

      const isNodeExists = Object.keys(components).find(
        (name) => name === selectedComponent.name,
      );

      const isVisualsExists = visuals
        ? Object.keys(visuals).find((name) => name === selectedComponent.name)
        : undefined;

      const newNodeText = ` ${
        isNodeExists
          ? `${selectedComponent.name} ${Object.keys(components)?.length}`
          : selectedComponent.name
      }:
    render: 
      - ${Math.round(x)}
      - ${Math.round(y)}
    instruments: {}
    visual:
      $ref: "#/visuals/${selectedComponent.name}"
      input: 
        weight: $.stream.wit01.state.net
        fillBar: $.stream.wit01.state.pv
        fillPercentage: $.stream.wit01.state.pv
        name: $.name`;
      const newVisualsText = isVisualsExists
        ? ''
        : ` ${selectedComponent.name}:
        flairs: {}
        id: ${newVisualId}
        version: ${selectedComponent.version}`;

      const visualsText = `\n ${newVisualsText}`;

      setTextValue((prev: string) =>
        prev
          .replace('components:', `components:\n ${newNodeText}`)
          .replace('visuals:', `visuals:${newVisualsText ? visualsText : ''}`),
      );
    },
    [reactFlowInstance, visualsSelector, textValue],
  );

  return (
    <Root ref={reactFlowWrapper}>
      <Context.Provider value={contextValue}>
        <ReactFlowStyled
          nodes={nodes}
          edges={edges}
          edgeTypes={edgeTypes}
          // @ts-ignore
          nodeTypes={nodeTypes}
          onDrop={onDrop}
          onDragOver={onDragOver}
          onNodesChange={handleNodesChange}
          onEdgesChange={onEdgesChange}
          onEdgeUpdate={onEdgeUpdate}
          fitView
          attributionPosition="top-right"
          minZoom={0.2}
          maxZoom={10}
          onMove={onMove}
          nodesDraggable={isEditable}
          nodesConnectable={isEditable}
          onNodeClick={handleClick}
          onPaneClick={onPaneClick}
          elevateEdgesOnSelect
          onConnect={handleConnect}
          elementsSelectable
          multiSelectionKeyCode="0"
          zoomOnDoubleClick={false}
        >
          <Listner handleNodesSelector={handleNodesSelector} />
          <Box sx={{ display: { xs: 'none', sm: 'inline' } }}>
            <MiniMap nodeColor={() => '#4C9FC8'} nodeStrokeWidth={1} />
          </Box>
          {showGrid && <Background size={0.75} gap={16} color="#ba88ce" />}
          <Controls
            toggleGrid={() => setShowGrid(!showGrid)}
            isInteractive
            toggleInteractive={() => {}}
            showGrid={showGrid}
            isEditable={isEditable}
            withFullscreen={withFullscreen}
            isFullscreen={isFullscreen}
            withGrid={false}
            onFullscreenEnter={onFullscreenEnter}
            onFullscreenExit={onFullscreenExit}
            reactFlowInstance={reactFlowInstance}
          />
          {!isDisabledHelperLines && (
            <HelperLines
              horizontal={helperLineHorizontal}
              vertical={helperLineVertical}
            />
          )}
        </ReactFlowStyled>
      </Context.Provider>
    </Root>
  );
};

CreateTemplateDiagram.defaultProps = {
  isEditable: false,
  isFullscreen: false,
  withFullscreen: false,
  isReFitView: false,
  setIsReFitView: () => {},
  onFullscreenEnter: () => {},
  onFullscreenExit: () => {},
};

export default CreateTemplateDiagram;
