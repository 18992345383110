import { rootApi } from '..';
import { atreusApiUrl } from '../../../env';
import { IProject } from '../../../typescript/interfaces/project.interface';

const BASE_URL = `${atreusApiUrl()}/preferences`;
// const BASE_URL = `https://sso.stage.dev.paracloud.ai/api/atreus/v1/`;

const extendedApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getPreferences: builder.query<any, void>({
      query: () => `${BASE_URL}`,
      providesTags: ['Preferences'],
    }),
    createPreferences: builder.mutation<any, any>({
      query: (data: IProject) => ({
        url: `${BASE_URL}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Preferences'],
    }),
    patchPreferences: builder.mutation<any, any>({
      query: (data: IProject) => ({
        url: `${BASE_URL}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['Preferences'],
    }),
    deletePreferences: builder.mutation<any, any>({
      query: (data: IProject) => ({
        url: `${BASE_URL}`,
        method: 'DELETE',
        body: data,
      }),
      invalidatesTags: ['Preferences'],
    }),
  }),
});

export const {
  useGetPreferencesQuery,
  useCreatePreferencesMutation,
  usePatchPreferencesMutation,
  useDeletePreferencesMutation,
} = extendedApi;
