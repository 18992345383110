import axios from 'axios';
import { ssoStorage, token, apiBaseUrlV1 as urlv1 } from '../env';
import { tokenParserStorage } from '../hoc/utils';

export const getDockFeed = async (
  systemId: string,
  mode = 'DOCK_RIGHT',
  urlString = 'control/v1',
): Promise<any> => {
  const accessToken = tokenParserStorage({
    storage: localStorage,
    entityName: ssoStorage(),
    searchValue: 'tokens',
  })?.accessToken;
  const response = await axios.get(
    `${urlv1(urlString)}/systems/${systemId}/feed?mode=${mode}`,
    { headers: { Authorization: `Bearer ${accessToken}` } },
  );
  return response.data;
};

export const getComponentFaceplateFeed = async (
  componentId: string,
  urlString = 'control/v1',
): Promise<any> => {
  const accessToken = tokenParserStorage({
    storage: localStorage,
    entityName: ssoStorage(),
    searchValue: 'tokens',
  })?.accessToken;
  const response = await axios.get(
    `${urlv1(urlString)}/components/${componentId}/feed`,
    { headers: { Authorization: `Bearer ${accessToken}` } },
  );
  return response.data;
};
