import React from 'react';
import { OidcProvider } from '@axa-fr/react-oidc';
import {
  axaOidcConfig,
  onEvent,
  onLogoutFromAnotherTab,
  onLogoutFromSameTab,
} from '../utils/oidcConfig';
import Loader from '../common/Loader';
import AuthenticatingErrorComponent from '../common/OIDCComponents/AuthenticatingErrorComponent';
import { environment } from '../env';
import { AppRouterProps } from '../App';

const env = environment();

const withOidcProvider =
  (Component: React.FC<AppRouterProps>) =>
  // eslint-disable-next-line react/display-name
  (props: any) => {
    if (env === 'edge') return <Component {...props} />;

    return (
      <OidcProvider
        configuration={axaOidcConfig}
        onEvent={onEvent}
        loadingComponent={() => <Loader />}
        authenticatingComponent={() => <Loader />}
        callbackSuccessComponent={() => <Loader />}
        authenticatingErrorComponent={() => <AuthenticatingErrorComponent />}
        onLogoutFromAnotherTab={onLogoutFromAnotherTab}
        onLogoutFromSameTab={onLogoutFromSameTab}
        onSessionLost={onLogoutFromAnotherTab}
      >
        <Component {...props} />
      </OidcProvider>
    );
  };

export default withOidcProvider;
