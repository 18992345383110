import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { environment, ssoStorage } from '../env';
import { setPasswordExpirationInDays } from '../redux/modules/user';
import { redirectToReturnPath, setTokensUtil } from '../utils/helper';
import {
  useGetCurrentUserQuery,
  useLazyGetCurrentUserQuery,
} from '../redux/services/atreus/api';
import { useAppDispatch } from '../redux/store';
import Loader from '../common/Loader';
import { REDIRECT_URI } from '../constants';
import useOidcCloud from '../hooks/useOidcCloud';
import { tokenParserStorage } from './utils';
import {
  useLazySilentEdgeLogoutFlowQuery,
  useLazySilentUserLogoutQuery,
} from '../redux/services/edge/api';

const ENVIRONMENT = environment();
/**
 * Check if a valid session cookie exists for Ory Kratos
 * @returns Current user from Redux store
 */
const useEdgeAuth = () => {
  const user = useGetCurrentUserQuery(undefined);

  const [callLogoutFlow, { data: edgeLogoutFlow }] =
    useLazySilentEdgeLogoutFlowQuery();
  const [logoutSession] = useLazySilentUserLogoutQuery();

  useEffect(() => {
    if (!localStorage.getItem('logout')) {
      localStorage.setItem('logout', 'false');
    }

    const handleToken = (e) => {
      if (e.key === 'logout' && e.newValue === 'true') {
        callLogoutFlow(undefined);
      }
    };
    window.addEventListener('storage', handleToken);
    return function cleanup() {
      window.removeEventListener('storage', handleToken);
    };
  }, []);

  useEffect(() => {
    if (edgeLogoutFlow) {
      logoutSession(edgeLogoutFlow).then(() => {
        localStorage.removeItem('logout');
        window.location.href = window.location.origin;
      });
    }
  }, [edgeLogoutFlow]);

  return user;
};

/**
 * Execute OAuth2 flows determined by presence of ID and access tokens in cookies.
 *
 * Note: Consider replacing with a library such as https://github.com/authts/react-oidc-context
 *       as a more complete and hardened implementation.
 * @returns Current user from Redux store
 */
const useCloudAuth = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { logout, accessToken, isAuthenticated } = useOidcCloud();

  const [getCurrentUser, currentUser] = useLazyGetCurrentUserQuery();

  useEffect(() => {
    if (accessToken) {
      getCurrentUser(accessToken);
    }
  }, [accessToken]);

  const updateTokens = () => {
    setTokensUtil(
      tokenParserStorage({
        storage: localStorage,
        entityName: ssoStorage(),
        searchValue: 'tokens',
      }),
    );

    getCurrentUser(
      tokenParserStorage({
        storage: localStorage,
        entityName: ssoStorage(),
        searchValue: 'tokens',
      })?.accessToken,
    );
  };

  const handleToken = (e: StorageEvent | DeviceMotionEvent) => {
    if ('acceleration' in e) updateTokens();
    if ('newValue' in e)
      if (e.key === ssoStorage() && e.newValue !== null) {
        updateTokens();
      }
  };

  useEffect(() => {
    window.addEventListener('storage', handleToken);
    return function cleanup() {
      window.removeEventListener('storage', handleToken);
    };
  }, []);

  useEffect(() => {
    window.addEventListener('devicemotion', handleToken);
    return () => {
      window.removeEventListener('devicemotion', handleToken);
    };
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      sessionStorage.setItem(
        REDIRECT_URI,
        `${window.location.pathname}${window.location.search}`,
      );
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (currentUser?.data) {
      redirectToReturnPath(history);
      const redirectUri = sessionStorage.getItem(REDIRECT_URI);

      if (redirectUri && redirectUri !== '/') {
        window.location.href = `${window.location.origin}${redirectUri}`;
        sessionStorage.removeItem(REDIRECT_URI);
      }

      if (redirectUri === '/') {
        sessionStorage.removeItem(REDIRECT_URI);
      }

      if (history.location.pathname?.includes('dashboard')) {
        history.replace(history.location.pathname);
      }
    }
  }, [currentUser?.data]);

  useEffect(() => {
    if (!currentUser?.data || !currentUser?.data?.password_expires_at) {
      return;
    }
    const now = moment(new Date());
    const expiresAt = moment(currentUser.data.password_expires_at);
    const diffInDays = expiresAt.diff(now, 'days');
    dispatch(setPasswordExpirationInDays(diffInDays));
  }, [currentUser]);

  useEffect(() => {
    if (accessToken) {
      setTokensUtil(
        tokenParserStorage({
          storage: localStorage,
          entityName: ssoStorage(),
          searchValue: 'tokens',
        }),
      );
    }
  }, [accessToken]);

  useEffect(() => {
    if (!currentUser?.error) return;
    if (!('code' in currentUser.error)) return;
    if (`${currentUser.error?.code}` === '401') {
      if (isAuthenticated) logout();
      window.location.href = window.location.origin;
    }
  }, [currentUser?.error, isAuthenticated]);

  useEffect(() => {
    if (currentUser?.isUninitialized && !accessToken) {
      window.location.href = window.location.origin;
    }
  }, [currentUser?.isUninitialized, accessToken]);

  return currentUser;
};

/** Essentially a route guard with different hooks for edge and cloud environments.
 *
 * Cloud auth hook actually executes the whole OAuth2 flow:
 * - Redirect to auth endpoint if no access token is present in cookie
 * - Receive callback with code and exchange that for access and ID token which are stored in cookies
 *
 * Edge auth hook just checks for an active Ory Kratos session and redirects to login page if needed
 */
export function withAuth<T>(WrappedComponent: React.ComponentType<T>) {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || 'Component';

  const EdgeAuth = (props: T) => {
    const user = useEdgeAuth();

    if (user.isError) window.location.href = window.location.origin;
    if (user.isLoading || user.isUninitialized) return <Loader />;
    return user && <WrappedComponent {...props} />;
  };

  const CloudAuth = (props: T) => {
    const user = useCloudAuth();

    if (user.isError) window.location.href = window.location.origin;
    if (user.isLoading || user.isUninitialized) return <Loader />;
    return <WrappedComponent {...props} />;
  };

  CloudAuth.displayName = `withCloudAuth(${displayName})`;
  EdgeAuth.displayName = `withEdgeAuth(${displayName})`;

  return ENVIRONMENT === 'edge' ? EdgeAuth : CloudAuth;
}

export default withAuth;
