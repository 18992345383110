// no-check
import React, { useState, useEffect, useMemo } from 'react';
import {
  Snackbar as MuiSnackbar,
  IconButton,
  styled,
  Typography,
} from '@mui/material';
import {
  CheckCircleOutlineRounded,
  CloseRounded,
  InfoOutlined,
  ReportOutlined,
} from '@mui/icons-material';
import { useSelector, useDispatch } from 'react-redux';
import { IAppState } from '../typescript/interfaces/appstate.interface';
import { dropEvent, setSwState } from '../redux/modules/events';
import useSnackbar from '../hooks/useSnackbar';

const StyledSnackbar = styled(MuiSnackbar)(({ theme }) => ({
  zIndex: 10000,
  [theme.breakpoints.down('md')]: {
    bottom: 48,
  },
  '& .MuiSnackbarContent-root': {
    boxShadow: '0px 6px 16px rgba(74, 85, 104, 0.08)',
    position: 'relative',
  },
  '&.success': {
    '& .MuiSnackbarContent-root': {
      border: `1px solid ${theme.palette.success.main}`,
    },
  },
  '&.info': {
    '& .MuiSnackbarContent-root': {
      border: `1px solid ${theme.palette.info.main}`,
    },
  },
  '&.error': {
    '& .MuiSnackbarContent-root': {
      border: `1px solid ${theme.palette.error.main}`,
    },
  },
  '&.warning': {
    '& .MuiSnackbarContent-root': {
      border: `1px solid ${theme.palette.warning.main}`,
    },
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '1.25rem',
  fontWeight: 600,
}));

const Desc = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: '1.125rem',
}));

const CloseBtn = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.secondary,
  position: 'absolute',
  right: '0.75rem',
  top: '1.15rem',
  left: 'auto',
  bottom: 'auto',
}));

const SuccessType = styled(CheckCircleOutlineRounded)(({ theme }) => ({
  color: theme.palette.success.main,
  position: 'absolute',
  left: '1rem',
  top: '1.35rem',
}));

const InfoType = styled(InfoOutlined)(({ theme }) => ({
  color: theme.palette.info.main,
  position: 'absolute',
  left: '1rem',
  top: '1.35rem',
}));

const ErrorType = styled(ReportOutlined)(({ theme }) => ({
  color: theme.palette.error.main,
  position: 'absolute',
  left: '1rem',
  top: '1.35rem',
}));

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function Snackbar() {
  const dispatch = useDispatch();
  const { showInfo } = useSnackbar();

  const event = useSelector((state: IAppState) => state.event.snackbar);
  const environment = useSelector((state: IAppState) => state.environment);

  const isOnlineLocal = useMemo(() => navigator.onLine, []);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(event.type.length > 0);
  }, [event]);

  const handleClose = (
    _event: React.SyntheticEvent<any> | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(dropEvent());

    setOpen(false);
  };

  useEffect(() => {
    dispatch(setSwState({ online: isOnlineLocal }));
  }, [isOnlineLocal]);

  useEffect(() => {
    window.addEventListener('online', () => {
      if (environment === 'cloud') {
        dispatch(setSwState({ online: true }));
        showInfo('Going to online mode');
      }
    });
    return () =>
      window.removeEventListener('online', () => {
        if (environment === 'cloud') {
          dispatch(setSwState({ online: true }));
          showInfo('Going to online mode');
        }
      });
  }, []);

  useEffect(() => {
    window.addEventListener('offline', () => {
      if (environment === 'cloud') {
        dispatch(setSwState({ online: false }));
        showInfo('Going to offline mode');
      }
    });
    return () =>
      window.removeEventListener('offline', () => {
        if (environment === 'cloud') {
          dispatch(setSwState({ online: false }));
          showInfo('Going to offline mode');
        }
      });
  }, []);

  const broadcast = new BroadcastChannel('sw-messages');

  broadcast.onmessage = (ev) => {
    if (ev.data.payload.msg === 'ONLINE') {
      dispatch(setSwState({ online: true }));
    }
    if (ev.data.payload.msg === 'OFFLINE') {
      dispatch(setSwState({ online: false }));
    }
  };

  const action = (
    <>
      {event.type.toLocaleLowerCase() === 'success' && <SuccessType />}
      {event.type.toLocaleLowerCase() === 'info' && <InfoType />}
      {event.type.toLocaleLowerCase() === 'error' && <ErrorType />}
      <CloseBtn
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
        id="Snackbar-close_button"
      >
        <CloseRounded />
      </CloseBtn>
    </>
  );

  return (
    <StyledSnackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      className={event.type.toLocaleLowerCase()}
      open={open}
      autoHideDuration={10000}
      onClose={handleClose}
      ContentProps={{
        id: 'Sanckbar-notification',
      }}
      message={
        <>
          {event.message && <Title id="Snackbar-title">{event.message}</Title>}
          <Desc>{event.desc}</Desc>
        </>
      }
      action={action}
    />
  );
}

export default Snackbar;
