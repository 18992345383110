//@ts-nocheck
import { combineReducers } from 'redux';
import breadcrumbsReducer from './modules/breadcrumbs/reducer';
import cnsReducer from './modules/cns/reducer';
import environmentReducer from './modules/environment/reducer';
import eventReducer from './modules/events/reducer';
import notificationsReducer from './modules/notifications/reducer';
import userReducer from './modules/user/reducer';
import viewReducer from './modules/view/reducer';
import redirectReducer from './modules/redirect/reducer';
import { rootApi } from './services';
import activeSitesReducer from './modules/sites/slice';
import isDemoReducer from './modules/isDemo/reducer';
import quickAccessReducer from './modules/quickAccess/reducer';
import stateReducer from './modules/state/reducer';

const rootReducer = combineReducers({
  user: userReducer,
  quickAccess: quickAccessReducer,
  cns: cnsReducer,
  event: eventReducer,
  view: viewReducer,
  breadcrumbs: breadcrumbsReducer,
  notifications: notificationsReducer,
  environment: environmentReducer,
  redirect: redirectReducer,
  [rootApi.reducerPath]: rootApi.reducer,
  activeSites: activeSitesReducer,
  isDemo: isDemoReducer,
  state: stateReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
