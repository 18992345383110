/* eslint-disable import/no-cycle */
import React, { lazy, Suspense } from 'react';
import { Redirect } from 'react-router-dom';
import Map from './components/dashboard/Map';
import Loader from './common/Loader';
import SystemPage from './pages/system';
import CreateConfigration from './pages/project-detail/create-config';
import { retry } from './utils/retry';
import Home from './pages/auth';

const ChangePassword = lazy(() =>
  retry(() => import('./pages/auth/ChangePassword')),
);
const ResetPassword = lazy(() =>
  retry(() => import('./pages/auth/ResetPassword')),
);
const LoginWithTemporaryPassword = lazy(() =>
  retry(() => import('./pages/auth/LoginWithTemporaryPassword')),
);

const Dashboard = lazy(() => retry(() => import('./pages/dashboard')));
const ProjectPage = lazy(() => retry(() => import('./pages/project')));
const ProjectDetailPage = lazy(() =>
  retry(() => import('./pages/project-detail')),
);
const SystemDetailPage = lazy(() =>
  retry(() => import('./pages/system-detail')),
);
const SystemExpandedPage = lazy(() =>
  retry(() => import('./pages/system/system-extended')),
);
const UsersRoles = lazy(() => retry(() => import('./pages/users')));
const Settings = lazy(() => retry(() => import('./pages/settings')));
const ExtendedRole = lazy(() =>
  retry(() => import('./pages/users/ExtendedRole')),
);
const QuickAccess = lazy(() =>
  retry(() => import('./components/dashboard/QuickAccess')),
);
const Systems = lazy(() =>
  retry(() => import('./components/dashboard/Systems')),
);
const Runs = lazy(() => retry(() => import('./components/dashboard/Tasks')));
const MORE = lazy(() => retry(() => import('./pages/more')));

const CreateVisuals = lazy(() =>
  retry(() => import('./pages/project-detail/create-visuals')),
);
const SystemConfig = lazy(() =>
  retry(() => import('./components/system/configs/Config')),
);

export const PROJECTS_STATUSES = [
  'All',
  'Validated',
  'Testing',
  'Development',
  'Archived',
];
export const SYSTEMS_STATUSES = [
  'All',
  'Pairing',
  'Unpaired',
  'Connected',
  'Awaiting_ENS',
  'Offline',
  'Online',
  'Unknown',
  'Running',
  'Warning',
  'Error',
  'Idle',
];

export const MACHINE_STATUSES = [
  'pairing',
  'unpaired',
  'connected',
  'awaiting ens',
  'offline',
  'online',
];

export const CONTROL_STATUSES = [
  'unknown',
  'running',
  'warning',
  'error',
  'idle',
];

export const PROJECTS_COMPATIBILITIES = [
  'Any',
  'System 1 - (7)',
  'System 2 - (3)',
  'System 3 - (5)',
  'System 4 - (12)',
  'System 5 - (10)',
];

export const customUIMoreLinks = [
  {
    name: 'BuffersUS Buffer Management',
    customUIurl: 'https://feed.piral.cloud/api/v1/pilet/oem',
    link: '/more/oem',
    image: '/images/buffer-us-icon.svg',
  },
  {
    name: 'App 3',
    customUIurl: undefined,
    link: undefined,
    image: undefined,
  },
];

export const SYSTEM_STATUSES_SKID = [
  {
    value: 0,
    status: 'idle',
  },
  {
    value: 1,
    status: 'running',
  },
  {
    value: 2,
    status: 'running',
  },
  {
    value: 3,
    status: 'running',
  },
  {
    value: 4,
    status: 'running',
  },
  {
    value: 5,
    status: 'idle',
  },
  {
    value: 6,
    status: 'stopping',
  },
];

export const SYSTEM_PHASES_NAMES = {
  PHASE: 'Phase',
  PHASE_STATE: 'Phase State',
};

export const dashboardPanes = {
  QUICK_ACCESS: {
    index: 'QUICK_ACCESS',
    hidden: 'mdDown',
    size: {
      xs: 12,
      md: 4,
    },
    component: () => suspendedComponent(QuickAccess),
  },
  SYSTEMS: {
    index: 'SYSTEMS',
    size: {
      xs: 12,
      sm: 6,
      md: 4,
    },
    component: () => suspendedComponent(Systems),
  },
  RUNS: {
    index: 'RUNS',
    size: {
      xs: 12,
      sm: 6,
      md: 4,
    },
    component: () => suspendedComponent(Runs),
  },
  MAP: {
    index: 'MAP',
    size: {
      xs: 12,
    },
    component: Map,
  },
};

const suspendedComponent = (Component, breadcrumbsProps) => {
  const { isForDesktop, isForMobile } = breadcrumbsProps || {
    isForDesktop: false,
    isForMobile: false,
  };

  return (
    <Suspense fallback={<Loader />}>
      <Component isForDesktop={isForDesktop} isForMobile={isForMobile} />
    </Suspense>
  );
};

export const REDIRECT_URI = 'redirectUri';

export const ROUTES_PATH = {
  MAIN: '/',
  CALLBACK: '/callback',
  RESET_PASSWORD: '/reset-password',
  CHANGE_PASSWORD: '/change-password',
  LOGIN_WITH_TEMPORARY_PASSWORD: '/login-with-temporary-password',
  DASHBOARD: '/dashboard',
  PROJECTS: '/projects',
  PROJECT_DETAIL: '/project',
  CONFIG: '/config',
  CREATE_CONFIG: '/create-configration',
  CREATE_VISUAL: '/create-visual',
  SYSTEMS: '/systems',
  SYSTEM_DETAIL: '/system-detail',
  SYSTEM_EXPANDED: '/system-expanded',
  USERS: '/users',
  SETTINGS: '/settings',
  EXTENDED_ROLE: '/extended-role',
  MORE: '/more',
};

const suspensedSystemPage = () => (
  <Suspense fallback={<Loader />}>
    <SystemPage isForDesktop={false} />
  </Suspense>
);

export const ROUTES_HIERARCHY = {
  0: [ROUTES_PATH.DASHBOARD],
  1: [ROUTES_PATH.PROJECTS, ROUTES_PATH.USERS, ROUTES_PATH.SETTINGS],
  2: [ROUTES_PATH.PROJECT_DETAIL, ROUTES_PATH.SYSTEMS],
  3: [ROUTES_PATH.SYSTEM_EXPANDED, ROUTES_PATH.CREATE_CONFIG],
  4: [ROUTES_PATH.SYSTEM_DETAIL, ROUTES_PATH.CREATE_VISUAL],
  5: [ROUTES_PATH.CONFIG],
};

export const routes = [
  {
    componentParent: 'Route',
    path: ROUTES_PATH.MAIN,
    component: () => <Home />,
    exact: true,
  },
  {
    componentParent: 'PrivateRoute',
    path: ROUTES_PATH.CALLBACK,
    component: Redirect,
    to: ROUTES_PATH.MAIN,
    exact: false,
  },
  {
    componentParent: 'Route',
    path: ROUTES_PATH.RESET_PASSWORD,
    component: () => suspendedComponent(ResetPassword),
    exact: true,
  },
  {
    componentParent: 'Route',
    path: ROUTES_PATH.CHANGE_PASSWORD,
    component: () => suspendedComponent(ChangePassword),
    exact: true,
  },
  {
    componentParent: 'Route',
    path: ROUTES_PATH.LOGIN_WITH_TEMPORARY_PASSWORD,
    component: () => suspendedComponent(LoginWithTemporaryPassword),
    exact: true,
  },
  {
    componentParent: 'PrivateRoute',
    path: ROUTES_PATH.DASHBOARD,
    component: () => suspendedComponent(Dashboard),
    exact: true,
  },
  {
    componentParent: 'PrivateRoute',
    path: `${ROUTES_PATH.PROJECTS}`,
    component: () => suspendedComponent(ProjectPage, { isForMobile: true }),
    exact: true,
  },
  {
    componentParent: 'PrivateRoute',
    path: `${ROUTES_PATH.PROJECT_DETAIL}/:id`,
    component: () =>
      suspendedComponent(ProjectDetailPage, {
        isForDesktop: true,
        isForMobile: true,
      }),
    exact: false,
  },
  {
    componentParent: 'PrivateRoute',
    path: `${ROUTES_PATH.CREATE_CONFIG}/:id`,
    component: () =>
      suspendedComponent(CreateConfigration, {
        isForDesktop: true,
        isForMobile: true,
      }),
    exact: false,
  },
  {
    componentParent: 'PrivateRoute',
    path: `${ROUTES_PATH.CREATE_CONFIG}/`,
    component: () =>
      suspendedComponent(CreateConfigration, {
        isForDesktop: true,
        isForMobile: true,
      }),
    exact: false,
  },
  {
    componentParent: 'PrivateRoute',
    path: `${ROUTES_PATH.CREATE_VISUAL}/:id`,
    component: () =>
      suspendedComponent(CreateVisuals, {
        isForDesktop: true,
        isForMobile: true,
      }),
    exact: false,
  },
  {
    componentParent: 'PrivateRoute',
    path: `${ROUTES_PATH.CREATE_VISUAL}`,
    component: () =>
      suspendedComponent(CreateVisuals, {
        isForDesktop: true,
        isForMobile: true,
      }),
    exact: false,
  },
  {
    componentParent: 'PrivateRoute',
    path: `${ROUTES_PATH.CONFIG}/:id`,
    component: () =>
      suspendedComponent(SystemConfig, {
        isForDesktop: true,
        isForMobile: true,
      }),
    exact: false,
  },
  {
    componentParent: 'PrivateRoute',
    path: `${ROUTES_PATH.SYSTEMS}/:state`,
    component: () => suspensedSystemPage(),
    exact: false,
  },
  {
    componentParent: 'PrivateRoute',
    path: `${ROUTES_PATH.SYSTEM_DETAIL}/:id`,
    component: () =>
      suspendedComponent(SystemDetailPage, {
        isForDesktop: true,
        isForMobile: true,
      }),
    exact: false,
  },
  {
    componentParent: 'PrivateRoute',
    path: `${ROUTES_PATH.SYSTEM_EXPANDED}/:id`,
    component: () =>
      suspendedComponent(SystemExpandedPage, {
        isForDesktop: true,
        isForMobile: true,
      }),
    exact: false,
  },
  {
    componentParent: 'PrivateRoute',
    path: ROUTES_PATH.USERS,
    component: () => suspendedComponent(UsersRoles),
    exact: true,
  },
  {
    componentParent: 'PrivateRoute',
    path: ROUTES_PATH.SETTINGS,
    component: () => suspendedComponent(Settings),
    exact: true,
  },
  {
    componentParent: 'PrivateRoute',
    path: `${ROUTES_PATH.EXTENDED_ROLE}/:id`,
    component: () =>
      suspendedComponent(ExtendedRole, {
        isForDesktop: true,
        isForMobile: true,
      }),
    exact: false,
  },
  {
    componentParent: 'PrivateRoute',
    path: `${ROUTES_PATH.MORE}/:tab`,
    component: () =>
      suspendedComponent(MORE, {
        isForDesktop: true,
        isForMobile: true,
      }),
    exact: false,
  },
];

export const HISTORY_MOCK = [
  {
    type: 'system',
    id: '6048c69a70b5b20025b249d7',
    tabid: 'projectId',
    actionType: 'run',
    actionStartTime: 1615390886624,
    actionEndTimeTime: 1615394524937,
    status: 'running',
  },
];

export const IMPORT_APPS = [
  { id: 1, name: 'ABC App', version: '0.1.1', installed: true, link: '' },
  { id: 2, name: 'XYZ App', version: '2.1.1', installed: true, link: '' },
  {
    id: 3,
    name: 'Another App',
    version: '3.1.0',
    installed: true,
    link: '',
  },
  {
    id: 4,
    name: 'Example App',
    version: '0.1.1',
    installed: false,
    link: '',
  },
  { id: 5, name: 'CDE App', version: '2.1.1', installed: false, link: '' },
  { id: 6, name: 'EFG App', version: '3.1.0', installed: false, link: '' },
  { id: 7, name: 'GHI App', version: '0.1.1', installed: false, link: '' },
  { id: 8, name: 'IJK App', version: '2.1.1', installed: false, link: '' },
  { id: 9, name: 'LMN App', version: '3.1.0', installed: false, link: '' },
];

export const SITES_MOCK = [
  {
    address: 'Reno',
    country: 'US',
    latitude: '39.530895',
    longitude: '-119.814972',
  },
  {
    address: 'Tallinn',
    country: 'EST',
    latitude: '59.43696',
    longitude: '24.75353',
  },
  {
    address: 'INTERPHEX 2022',
    country: 'US',
    latitude: '40.757532',
    longitude: '-74.003002',
  },
];

export const pumpIDS = [
  {
    id: '948989681ab647ac9869edc6498b1ff0',
    isActive: true,
    name: 'p01_feedback_pv',
    color: '#F05452',
  },
  {
    id: '220689d40e5a46d491a10d4f96b52d60',
    isActive: true,
    name: 'p02_feedback_pv',
    color: '#3B82F6',
  },
  {
    id: '3ed8cec126364eb0838b09597eccede7',
    isActive: true,
    name: 'p03_feedback_pv',
    color: '#F59E3B',
  },
  {
    id: 'dd646a6941404d0681517d38ffde5c0f',
    isActive: true,
    name: 'p04_feedback_pv',
    color: '#475569',
  },
  {
    id: 'c93ce09c1418461b95e750138cc2b714',
    isActive: true,
    name: 'p05_feedback_pv',
    color: '#6366F1',
  },
  {
    id: '2185ba677248434bb5bcd0898f9e35f3',
    isActive: true,
    name: 'p06_feedback_pv',
    color: '#10B981',
  },
  {
    id: 'ee9e68bda5834b4faaa3082251a9090c',
    isActive: true,
    name: 'p07_feedback_pv',
    color: '#0D9488',
  },
];

export const scalesIDS = [
  {
    id: 'cd3654f5ff804693bb85581e612856fd',
    isActive: true,
    name: 'wit01_pv',
    color: '#F05452',
  },
  {
    id: 'ebc8cf48a7054137a2997c74d5ebf304',
    isActive: true,
    name: 'wit02_pv',
    color: '#3B82F6',
  },
  {
    id: '6ca651dc6bc24279b2570a9c885c2c1b',
    isActive: true,
    name: 'wit03_pv',
    color: '#F59E3B',
  },
  {
    id: '2f4f19d0d14a4e1a94c9c40c73f476cd',
    isActive: true,
    name: 'wit04_pv',
    color: '#475569',
  },
  {
    id: 'ebed1c4c18134a78b8f67ab24f834cb3',
    isActive: true,
    name: 'wit05_pv',
    color: '#6366F1',
  },
  {
    id: 'ad32f4ad70b34219920280b2c4f3ae70',
    isActive: true,
    name: 'wit06_pv',
    color: '#10B981',
  },
  {
    id: 'b533fa047c7c4c8cb7fc417f9413fbaf',
    isActive: true,
    name: 'wit07_pv',
    color: '#0D9488',
  },
];

export const SAMPLES_RATES_MOCK = [
  '1s',
  '2s',
  '5s',
  '10s',
  '15s',
  '30s',
  '1m',
  '2m',
  '5m',
  '10m',
  '15m',
  '30m',
];

export const MACHINES_MOCK = [
  {
    id: '1',
    name: 'Machine A01',
    state: undefined,
  },
  {
    id: '2',
    name: 'Machine A02',
    state: undefined,
  },
  {
    id: '3',
    name: 'Machine A03',
    state: 'warning',
  },
  {
    id: '4',
    name: 'Machine A04',
    state: undefined,
  },
  {
    id: '5',
    name: 'Machine A05',
    state: undefined,
  },
];

export const passwordSettingsRules = [
  {
    key: 'minimumLength',
    pattern: (min) => `.{${min},}`,
    message: (num) => `be at least ${num} characters in length`,
  },
  {
    key: 'requireCapitalLetter',
    pattern: /(?=.*[A-Z])/,
    message: 'have at least one capital letter',
  },
  {
    key: 'requireNumber',
    pattern: /(?=.*\d)/,
    message: 'have at least one number',
  },
  {
    key: 'requireSpecialCharacter',
    pattern: /(?=.*\W)/,
    message: 'have at least one special character',
  },
];

export const CONFIG_UNCONNECTED_SYSTEM_ID_MOCK =
  'SYST768B1E6F3A394C29BF3376E57CC4DE64';

export const YAML_LANGUAGE = 'yaml';

export const SYSTEM_DETAILS_TABS = [
  {
    id: '0',
    name: 'Details',
  },
  {
    id: '1',
    name: 'DetailsMob',
  },
  {
    id: '2',
    name: 'Trend',
  },
  {
    id: '3',
    name: 'Debug',
  },
];
