import { useEffect, useState } from 'react';
import { envLogoSrc } from '../redux/services/environment/api';

function clearStorage() {
  localStorage.removeItem('envLogo');
}

const REFETCH_INTERVAL_MIN = 10;

export default function useEnvLogo() {
  const [hasEnvLogo, setHasEnvLogo] = useState(false);
  useEffect(() => {
    async function init() {
      const fromStorage = JSON.parse(localStorage.getItem('envLogo'));
      if (fromStorage != null) {
        const diffMs = Date.now() - fromStorage.fetchedAt;
        const diffMin = Math.round(diffMs / 1000 / 60);
        if (diffMin < REFETCH_INTERVAL_MIN) {
          setHasEnvLogo(Boolean(fromStorage.hasLogo));
          return;
        }
      }
      let hasLogo = true;
      try {
        await fetch(envLogoSrc);
      } catch (e: any) {
        hasLogo = false;
      }
      localStorage.setItem(
        'envLogo',
        JSON.stringify({
          fetchedAt: Date.now(),
          hasLogo,
        }),
      );
      setHasEnvLogo(hasLogo);
    }

    init();
  }, []);

  return { hasEnvLogo, clearStorage };
}
