import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Box,
  IconButton,
  TextField,
  useTheme,
} from '@mui/material';
import { AddRounded, EditOutlined } from '@mui/icons-material';
import Input from '../Input';
// eslint-disable-next-line import/no-cycle
import { ROUTES_PATH } from '../../constants';
import { ModalContent, StyledSelect, StyledAutocomplete } from './styles';
import { SEM_VER_VERSIONS } from '../../components/project/builder/mocks';
import DialogComponent from './DialogComponent';
import { setBreadcrumb } from '../../redux/modules/breadcrumbs';
import { useGetSystemConfigurationTemplatesQuery } from '../../redux/services/systemConfigurationTemplate/api';
import { useCreateProjectMutation } from '../../redux/services/projects/api';
import useSnackbar from '../../hooks/useSnackbar';
import Loader from '../Loader';
import { isForbiddenError } from '../../redux/utils';

const filterOptions = (options, state) => {
  const newOptions = [];
  options.forEach((el) => {
    if (el.name.toLowerCase().includes(state.inputValue.toLowerCase()))
      newOptions.push(el);
  });
  return newOptions;
};

interface Props {
  isOpened: boolean;
  closeModal: () => void;
  templateId?: string;
  onCreate?: (projectId: string) => void;
}

const initialState = () => {
  const createProjectData = JSON.parse(
    sessionStorage.getItem('createProjectData'),
  );
  return {
    name: createProjectData?.name || '',
    description: createProjectData?.description || '',
    basedOn: createProjectData?.basedOn || '',
    version: createProjectData?.version || SEM_VER_VERSIONS[0],
  };
};

const CreateProject = (props: Props): JSX.Element => {
  const { isOpened, closeModal, templateId, onCreate } = props;
  const history = useHistory();
  const theme = useTheme();
  const isDark = theme.palette.mode === 'dark';

  const [isNotExists, setIsNotExists] = useState<boolean>(false);

  const {
    data: systemConfigurationTemplates = [],
    isLoading: systemConfigurationLoading,
  } = useGetSystemConfigurationTemplatesQuery(undefined, {
    refetchOnMountOrArgChange: true,
    pollingInterval: isNotExists ? 100 : 0,
  });
  const [
    createProject,
    { isSuccess, data: newCreatedProject, error: createProjectError },
  ] = useCreateProjectMutation();

  const { showError, showSuccess } = useSnackbar();

  const dispatch = useDispatch();

  const [state, setState] = useState(initialState());

  useEffect(() => {
    if (state.basedOn !== '') {
      if (!systemConfigurationTemplates?.find(({ id }) => id === state.basedOn))
        setIsNotExists(true);
      if (systemConfigurationTemplates?.find(({ id }) => id === state.basedOn))
        setIsNotExists(false);
    }
  }, [systemConfigurationTemplates?.length]);

  const isActionButtonDisabled = useMemo(() => {
    const stateName = state.name.trim();
    const description = state.description.trim();
    const versionValue = state?.version?.value;
    const { basedOn } = state;

    return (
      !stateName ||
      stateName.length > 40 ||
      !description ||
      description.length > 1024 ||
      !versionValue ||
      !basedOn
    );
  }, [state]);

  const sortedSystemConfiguration = useMemo(() => {
    if (systemConfigurationTemplates)
      if (systemConfigurationTemplates?.slice())
        return systemConfigurationTemplates
          ?.slice()
          ?.sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          })
          ?.map(({ name, id }) => ({ name, id }));
    return [];
  }, [systemConfigurationTemplates]);

  const handleChange = (event: any) => {
    const { name, value } = event.target;

    setState((prevState) => ({
      ...prevState,
      [name]:
        name === 'version'
          ? SEM_VER_VERSIONS.filter(({ id }) => id === value)[0]
          : value,
    }));
  };

  const handleSave = () => {
    createProject({
      ...state,
      archived: false,
    });
  };

  const handleCloseModal = () => {
    sessionStorage.removeItem('createProjectData');
    setState({
      name: '',
      description: '',
      basedOn: '',
      version: SEM_VER_VERSIONS[0],
    });
    closeModal();
  };

  useEffect(() => {
    if (isSuccess) {
      showSuccess(
        `${newCreatedProject?.name} project has been successfully created`,
      );

      handleCloseModal();
      history.push(`${ROUTES_PATH.PROJECT_DETAIL}/${newCreatedProject?.id}`);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (!createProjectError) return;
    if (isForbiddenError(createProjectError)) {
      showError("You don't have permissions to create a project.");
      return;
    }
    showError("Project hasn't been created. Something went wrong.");
  }, [createProjectError]);

  const handleEditConfiguration = () => {
    sessionStorage.setItem('createProjectData', JSON.stringify({ ...state }));
    dispatch(
      setBreadcrumb([
        {
          name: `New template`,
          url: `${ROUTES_PATH.CREATE_CONFIG}`,
        },
      ]),
    );
    history.push(`${ROUTES_PATH.CREATE_CONFIG}/`, {
      type: 'template',
    });
  };

  useEffect(() => {
    if (templateId) {
      setState((prevState) => ({
        ...prevState,
        basedOn: templateId,
      }));
    }
  }, [templateId]);

  return (
    <DialogComponent
      isOpened={isOpened}
      closeModal={handleCloseModal}
      title="Add a project"
      actionTitle="Add"
      handleAction={handleSave}
      isActionButtonDisabled={isActionButtonDisabled}
      actionId="AddProject-add_button"
      cancelId="AddProject-cancel_button"
    >
      {!systemConfigurationLoading ? (
        <ModalContent>
          <FormControl fullWidth>
            <InputLabel shrink htmlFor="name">
              <span>Project name</span>
              <span>{state.name.length}/40</span>
            </InputLabel>
            <Input
              value={state.name}
              onChange={handleChange}
              name="name"
              placeholder="Enter project name"
              id="name"
              inputProps={{
                maxLength: 40,
              }}
            />
          </FormControl>
          <FormControl fullWidth>
            <InputLabel shrink htmlFor="AddProject-description">
              <span>Description</span>
              <span>{state.description.length}/1024</span>
            </InputLabel>
            <Input
              value={state.description}
              onChange={handleChange}
              placeholder="Enter description"
              id="AddProject-description"
              name="description"
              multiline
              rows={4}
              maxRows={8}
              sx={{ p: 0 }}
              inputProps={{
                maxLength: 1024,
              }}
            />
          </FormControl>
          <FormControl fullWidth>
            <InputLabel shrink htmlFor="version">
              ProCaaSo framework version
            </InputLabel>
            <StyledSelect
              id="version"
              variant="outlined"
              value={state.version.id}
              onChange={handleChange}
              name="version"
              disabled={SEM_VER_VERSIONS.length === 0}
              MenuProps={{
                MenuListProps: {
                  id: 'AddProject-version-list',
                },
              }}
            >
              {SEM_VER_VERSIONS.map((c) => (
                <MenuItem
                  id={`AddProject-version-${c.value}`}
                  key={c.id}
                  value={c.id}
                >
                  {c.value}
                </MenuItem>
              ))}
            </StyledSelect>
          </FormControl>
          <Box display="flex" alignItems="flex-end">
            <FormControl sx={{ mb: 0 }} fullWidth>
              <InputLabel shrink htmlFor="basedOn">
                System configuration
              </InputLabel>
              <StyledAutocomplete
                id="basedOn"
                options={sortedSystemConfiguration}
                getOptionLabel={(option: any) => {
                  const op = sortedSystemConfiguration?.find(
                    (s) => s.id === option,
                  );
                  return op?.name || '';
                }}
                value={state?.basedOn}
                onChange={(e: any, newValue: any) => {
                  setState((prevState) => ({
                    ...prevState,
                    basedOn: (newValue && newValue.id) || '',
                  }));
                }}
                componentsProps={{
                  paper: {
                    sx: {
                      '& .MuiAutocomplete-listbox': { maxHeight: '360px' },
                    },
                  },
                  popper: { placement: 'top' },
                  clearIndicator: { id: 'AddProject-clear-config' },
                  popupIndicator: { id: 'AddProject-open-config' },
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Select configuration"
                  />
                )}
                filterOptions={filterOptions}
                renderOption={(oProps, c: any) => (
                  <Box
                    sx={{
                      display: c.name.length > 0 ? 'flex' : 'none',
                      '&:hover .MuiIconButton-root': { opacity: 1 },
                    }}
                  >
                    <MenuItem
                      {...oProps}
                      key={c.id}
                      value={c.id}
                      sx={{
                        flex: 1,
                        '&.Mui-focused': {
                          backgroundColor: 'transparent !important',
                        },
                      }}
                    >
                      {c.name}
                    </MenuItem>
                  </Box>
                )}
                disabled={
                  systemConfigurationTemplates?.length === 0 ||
                  templateId != null ||
                  (state.basedOn &&
                    !systemConfigurationTemplates?.find(
                      ({ id }) => id === state.basedOn,
                    ))
                }
              />
            </FormControl>
            {!templateId && (
              <Button
                startIcon={<AddRounded />}
                component={Link}
                onClick={() => handleEditConfiguration()}
                variant="outlined"
                sx={{
                  minWidth: '6.5rem',
                  ml: 1,
                }}
                id="CreateProjectModal-Button_CreateTemplate"
              >
                Create
              </Button>
            )}
          </Box>
        </ModalContent>
      ) : (
        <Loader />
      )}
    </DialogComponent>
  );
};

CreateProject.defaultProps = {
  templateId: null,
  onCreate: undefined,
};
export default CreateProject;
