import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { Router } from 'react-router-dom';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { Provider } from 'react-redux';
import App from './App';
import appHistory from './utils/appHistory';
import ThemeProvider from './hoc/ThemeProvider';
import store from './redux/store';
import * as serviceWorker from './serviceWorkerRegistration';
import serviceWorkerConfig from './swConfig';
import { envVars } from './env';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <StrictMode>
    <Provider store={store}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Router history={appHistory}>
          <ThemeProvider>
            {({ darkMode, toggleTheme }) => (
              <App darkMode={darkMode} toggleTheme={toggleTheme} />
            )}
          </ThemeProvider>
        </Router>
      </LocalizationProvider>
    </Provider>
  </StrictMode>,
);

serviceWorker.register(serviceWorkerConfig);

if (envVars.mockApi) {
  import('./mocks/browser').then((workerApi) => {
    workerApi.worker.start({ onUnhandledRequest: 'bypass' });
  });
}
