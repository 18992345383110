import React, { useState, useMemo, useEffect, useRef } from 'react';
import cn from 'classnames';
import { Handle, Position } from 'react-flow-renderer';
import { Box, styled } from '@mui/material';
import { Resizable } from 're-resizable';
import {
  AlertHighIcon,
  AlertUrgentIcon,
  AlertMediumIcon,
  AlertLowIcon,
} from '../../../../icons';
import { useGetComponentsQuery } from '../../../../redux/services/components/api';
import { OldComponentData } from '../../../../typescript/interfaces/oldComponentData.interface';

const Alert = styled(Box)(({ theme }) => ({
  top: '-10%',
  bottom: '-10%',
  left: '-10%',
  right: '-10%',
  position: 'absolute',
  border: '3px solid transparent',
  '&.high': {
    borderColor: theme.palette.warning.main,
  },
  '&.urgent': {
    borderColor: theme.palette.error.main,
  },
  '&.medium': {
    borderColor: '#F5D93B',
  },
  '&.low': {
    borderColor: '#B19EBD',
  },
}));

const AlertHigh = styled(AlertHighIcon)({
  position: 'absolute',
  right: '-18%',
  bottom: '-18%',
  width: '30%',
  height: '30%',
  minWidth: '1.5rem',
  minHeight: '1.5rem',
});

const AlertUrgent = styled(AlertUrgentIcon)({
  position: 'absolute',
  right: '-18%',
  top: '-18%',
  width: '30%',
  height: '30%',
  minWidth: '1.5rem',
  minHeight: '1.5rem',
});

const AlertMedium = styled(AlertMediumIcon)({
  position: 'absolute',
  right: '-18%',
  top: '-18%',
  width: '30%',
  height: '30%',
  minWidth: '1.5rem',
  minHeight: '1.5rem',
});

const AlertLow = styled(AlertLowIcon)({
  position: 'absolute',
  left: '-18%',
  top: '-18%',
  width: '30%',
  height: '30%',
  minWidth: '1.5rem',
  minHeight: '1.5rem',
});

const SpeedWrap = styled(Box)({
  position: 'absolute',
  bottom: '-20%',
  left: '10%',
  right: '10%',
  height: '8%',
  borderRadius: '8%',
});

const CurrentSpeed = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  top: 0,
  left: 0,
  borderRadius: theme.shape.borderRadius,
}));

const SVG = styled('svg')({
  width: '100%',
  height: '100%',
});

const Brand = styled('img')({
  position: 'absolute',
  top: '30%',
  left: 0,
  right: 0,
  width: '40%',
  margin: '0 auto',
});

const Label = styled(Box)(({ theme }) => ({
  position: 'absolute',
  zIndex: 200,
  left: '4%',
  right: '4%',
  bottom: '25%',
  textAlign: 'center',
  color: '#484848',
  lineHeight: '1.5',
  '&.label-system': {
    bottom: '38%',
  },
  '& > div': {
    transform: 'translateY(-50%)',
    position: 'absolute',
    top: '50%',
    left: 0,
    right: 0,
  },
}));
interface Props {
  id: string;
  data?: OldComponentData;
  currentNameTypes?: boolean;
  systemName?: string;
  isTemplate?: boolean;
}

const DEFAULT_SIZES = {
  SYSTEM: {
    width: 150,
    height: 145,
  },
  SOLUTION: {
    width: 64,
    height: 72,
  },
};

function Pixer(props: Props) {
  const { id, data, systemName, isTemplate: propsIsTemplate } = props;
  const isTemplate = Boolean(propsIsTemplate || data.isTemplate);

  const textRef = useRef(null);

  const currentNameTypes = useMemo(
    () => data?.label?.props?.currentNameTypes || undefined,
    [data],
  );
  const rigthLeftContent = currentNameTypes !== undefined ? 6 : 3;
  const bottomContent = currentNameTypes !== undefined ? 8 : 25;

  const progress = 0;

  const [alertType] = useState('');

  const { data: components } = useGetComponentsQuery(
    {
      systemId: data?.data?.systemId,
    },
    {
      skip: data?.data?.systemId,
    },
  );

  const component = useMemo(
    () => components?.find((c: any) => c.id === id),
    [components, id],
  );

  const MAX_VALUE = 3000; // TBD MAX RPM FOR PIXERs

  const name = useMemo(() => {
    if (isTemplate) return id;
    if (component?.name) return component.name;
    return 'Pixer';
  }, [currentNameTypes, component, systemName, data]);

  useEffect(() => {
    const minSize = 8;
    const maxSize = 20;
    const maxBottom = 25;
    let size = maxSize;
    let bottom = maxBottom;
    do {
      size -= 0.1;
      bottom += 0.07;
      textRef.current.style.bottom = `${bottom}%`;
      textRef.current.style.fontSize = `${size}px`;
    } while (
      (textRef.current.clientWidth < textRef.current.scrollWidth ||
        textRef.current.clientHeight < textRef.current.scrollHeight) &&
      size > minSize
    );

    if (
      (textRef.current.clientWidth >= textRef.current.scrollWidth ||
        textRef.current.clientHeight >= textRef.current.scrollHeight) &&
      size.toFixed(0) === `${minSize}`
    ) {
      textRef.current.style.textOverflow = 'ellipsis';
      textRef.current.style.overflow = 'hidden';
      textRef.current.style.whiteSpace = 'nowrap';
      textRef.current.style.width = textRef.current.scrollWidth;
    }
  }, [name]);

  return (
    <Box
      component={Resizable}
      defaultSize={{
        width:
          currentNameTypes !== undefined
            ? DEFAULT_SIZES.SOLUTION.width
            : DEFAULT_SIZES.SYSTEM.width,
        height:
          currentNameTypes !== undefined
            ? DEFAULT_SIZES.SOLUTION.height
            : DEFAULT_SIZES.SYSTEM.height,
      }}
      lockAspectRatio
      enable={{
        top: false,
        right: false,
        bottom: false,
        left: false,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false,
      }}
      handleClasses={{
        bottomRight: 'resize-handle',
      }}
      sx={{
        position: 'relative',
        '&:hover .edit-btn': {
          opacity: 1,
        },
        '&:hover .resize-handle': {
          display: 'inline-block',
        },
        '& .resize-handle': {
          width: '30% !important',
          height: '30% !important',
          right: '-15% !important',
          bottom: '-15% !important',
          display: 'none',
        },
      }}
    >
      <Alert
        className={cn(
          alertType === 'high' && 'high',
          alertType === 'urgent' && 'urgent',
          alertType === 'medium' && 'medium',
          alertType === 'low' && 'low',
        )}
      >
        {alertType === 'high' && <AlertHigh />}
        {alertType === 'urgent' && <AlertUrgent />}
        {alertType === 'medium' && <AlertMedium />}
        {alertType === 'low' && <AlertLow />}
      </Alert>
      <div>
        <>
          <Handle
            className="point"
            type="source"
            position={Position.Right}
            id="t-right-bottom"
            style={{ marginTop: 8 }}
          />
          <Handle
            className="point"
            type="target"
            position={Position.Right}
            id="t-right-bottom"
            style={{ marginTop: 8 }}
          />
          <Handle
            className="point"
            type="target"
            position={Position.Right}
            id="Inlet 3"
            style={{ marginTop: 8 }}
          />
          <Handle
            className="point"
            type="source"
            position={Position.Right}
            id="t-right-center"
          />
          <Handle
            className="point"
            type="target"
            position={Position.Right}
            id="t-right-center"
          />
          <Handle
            className="point"
            type="target"
            position={Position.Right}
            id="Inlet 4"
          />
          <Handle
            className="point"
            type="source"
            position={Position.Right}
            id="t-right-top"
            style={{ marginTop: -8 }}
          />
          <Handle
            className="point"
            type="target"
            position={Position.Right}
            id="t-right-top"
            style={{ marginTop: -8 }}
          />
          <Handle
            className="point"
            type="target"
            position={Position.Right}
            id="Inlet 6"
            style={{ marginTop: -8 }}
          />
          <Handle
            className="point"
            type="source"
            position={Position.Left}
            id="t-left-bottom"
            style={{ marginTop: 8 }}
          />
          <Handle
            className="point"
            type="target"
            position={Position.Left}
            id="t-left-bottom"
            style={{ marginTop: 8 }}
          />
          <Handle
            className="point"
            type="target"
            position={Position.Left}
            id="Inlet 1"
            style={{ marginTop: 8 }}
          />
          <Handle
            className="point"
            type="source"
            position={Position.Left}
            id="t-left-center"
          />
          <Handle
            className="point"
            type="target"
            position={Position.Left}
            id="t-left-center"
          />
          <Handle
            className="point"
            type="target"
            position={Position.Left}
            id="Inlet 2"
          />
          <Handle
            className="point"
            type="source"
            position={Position.Left}
            id="t-left-top"
            style={{ marginTop: -8 }}
          />
          <Handle
            className="point"
            type="target"
            position={Position.Left}
            id="t-left-top"
            style={{ marginTop: -8 }}
          />
          <Handle
            className="point"
            type="target"
            position={Position.Left}
            id="Inlet 3"
            style={{ marginTop: -8 }}
          />
          <Handle
            className="point"
            type="target"
            position={Position.Left}
            id="s-bottom-left"
            style={{
              left: rigthLeftContent,
              bottom: bottomContent,
              top: 'auto',
            }}
          />
          <Handle
            className="point"
            type="source"
            position={Position.Left}
            id="s-bottom-left"
            style={{
              left: rigthLeftContent,
              bottom: bottomContent,
              top: 'auto',
            }}
          />
          <Handle
            className="point"
            type="source"
            position={Position.Left}
            id="Outlet 1"
            style={{
              left: rigthLeftContent,
              bottom: bottomContent,
              top: 'auto',
            }}
          />
          <Handle
            className="point"
            type="target"
            position={Position.Right}
            id="s-bottom-right"
            style={{
              right: rigthLeftContent,
              bottom: bottomContent,
              top: 'auto',
              left: 'auto',
            }}
          />
          <Handle
            className="point"
            type="source"
            position={Position.Right}
            id="s-bottom-right"
            style={{
              right: rigthLeftContent,
              bottom: bottomContent,
              top: 'auto',
              left: 'auto',
            }}
          />
          <Handle
            className="point"
            type="source"
            position={Position.Right}
            id="Outlet 2"
            style={{
              right: rigthLeftContent,
              bottom: bottomContent,
              top: 'auto',
              left: 'auto',
            }}
          />
        </>
        <Label
          ref={textRef}
          className={currentNameTypes !== undefined ? 'label-system' : ''}
        >
          {name}
        </Label>
        <Brand src="/images/library/secondary_logo.svg" alt="" />
        <div>
          <SVG
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 264.371 236.048"
          >
            <defs>
              <linearGradient
                id="linear-gradient"
                y1="0.5"
                x2="1"
                y2="0.5"
                gradientUnits="objectBoundingBox"
              >
                <stop offset="0" stopColor="#ccc" />
                <stop offset="0.59" stopColor="#f2f2f2" />
                <stop offset="0.97" stopColor="#ccc" />
              </linearGradient>
              <linearGradient
                id="linear-gradient-2"
                y1="0.5"
                x2="1"
                y2="0.5"
                gradientUnits="objectBoundingBox"
              >
                <stop offset="0" stopColor="#6e6e6e" />
                <stop offset="0.01" stopColor="#767676" />
                <stop offset="0.05" stopColor="#919191" />
                <stop offset="0.1" stopColor="#a7a7a7" />
                <stop offset="0.16" stopColor="#b8b8b8" />
                <stop offset="0.23" stopColor="#c3c3c3" />
                <stop offset="0.33" stopColor="#cacaca" />
                <stop offset="0.61" stopColor="#ccc" />
                <stop offset="0.97" stopColor="#b3b3b3" />
              </linearGradient>
              <linearGradient
                id="linear-gradient-3"
                x1="0"
                y1="0.5"
                x2="1"
                y2="0.5"
                xlinkHref="#linear-gradient-2"
              />
              <linearGradient
                id="linear-gradient-5"
                x1="0"
                y1="0.5"
                x2="1"
                y2="0.5"
                xlinkHref="#linear-gradient-2"
              />
              <linearGradient
                id="linear-gradient-8"
                x1="0.5"
                y1="1"
                x2="0.5"
                y2="-0.002"
                gradientUnits="objectBoundingBox"
              >
                <stop offset="0" stopColor="#6e6e6e" />
                <stop offset="0.17" stopColor="#8c8c8c" />
                <stop offset="0.38" stopColor="#a8a8a8" />
                <stop offset="0.59" stopColor="#bcbcbc" />
                <stop offset="0.79" stopColor="#c8c8c8" />
                <stop offset="1" stopColor="#ccc" />
              </linearGradient>
              <radialGradient
                id="radial-gradient"
                cx="0.486"
                cy="-0.166"
                fx="0.4463294446468353"
                r="7.52"
                gradientTransform="matrix(-0.136, -0.031, 0.001, -0.12, 0.552, -0.173)"
                gradientUnits="objectBoundingBox"
              >
                <stop offset="0.06" stopColor="#b3b3b3" />
                <stop offset="0.42" stopColor="#cfcfcf" />
                <stop offset="0.73" stopColor="#e0e0e0" />
                <stop offset="0.97" stopColor="#e6e6e6" />
              </radialGradient>
              <linearGradient
                id="linear-gradient-10"
                y1="0.5"
                x2="1"
                y2="0.5"
                gradientUnits="objectBoundingBox"
              >
                <stop offset="0" stopColor="#ccc" />
                <stop offset="0.1" stopColor="#dfdfdf" />
                <stop offset="0.24" stopColor="#f1f1f1" />
                <stop offset="0.39" stopColor="#fbfbfb" />
                <stop offset="0.56" stopColor="#fff" />
                <stop offset="0.84" stopColor="#dbdbdb" />
                <stop offset="1" stopColor="#ccc" />
              </linearGradient>
              <linearGradient
                id="linear-gradient-12"
                x1="0.5"
                y1="1"
                x2="0.5"
                gradientUnits="objectBoundingBox"
              >
                <stop offset="0" stopColor="#b3b3b3" />
                <stop offset="0.57" stopColor="#c5c5c5" />
                <stop offset="1" stopColor="#ccc" />
              </linearGradient>
              <linearGradient
                id="linear-gradient-13"
                x1="0.5"
                y1="1.006"
                x2="0.5"
                gradientUnits="objectBoundingBox"
              >
                <stop offset="0" stopColor="#ccc" />
                <stop offset="0.26" stopColor="#c5c5c5" />
                <stop offset="0.65" stopColor="#b1b1b1" />
                <stop offset="1" stopColor="#999" />
              </linearGradient>
              <linearGradient
                id="linear-gradient-14"
                x1="0.5"
                y1="1"
                x2="0.5"
                gradientUnits="objectBoundingBox"
              >
                <stop offset="0" stopColor="#b3b3b3" />
                <stop offset="0.2" stopColor="#bcbcbc" />
                <stop offset="0.6" stopColor="#c8c8c8" />
                <stop offset="1" stopColor="#ccc" />
              </linearGradient>
              <radialGradient
                id="radial-gradient-2"
                cx="0.5"
                cy="0.5"
                r="0.963"
                gradientTransform="translate(0.303) scale(0.394 1)"
                gradientUnits="objectBoundingBox"
              >
                <stop offset="0" stopColor="#b3b3b3" />
                <stop offset="0.27" stopColor="#b0b0b0" />
                <stop offset="0.5" stopColor="#a6a6a6" />
                <stop offset="0.71" stopColor="#949494" />
                <stop offset="0.91" stopColor="#7c7c7c" />
                <stop offset="1" stopColor="#6e6e6e" />
              </radialGradient>
              <radialGradient
                id="radial-gradient-3"
                cx="0.5"
                cy="0.5"
                r="1.09"
                gradientTransform="translate(0.329 0.25) scale(0.343 0.5)"
                gradientUnits="objectBoundingBox"
              >
                <stop offset="0" stopColor="#b3b3b3" />
                <stop offset="0.41" stopColor="#b0b0b0" />
                <stop offset="0.76" stopColor="#a6a6a6" />
                <stop offset="1" stopColor="#999" />
              </radialGradient>
              <radialGradient
                id="radial-gradient-4"
                cx="0.502"
                cy="0.5"
                r="0.374"
                gradientTransform="matrix(0.627, 0.977, -2.854, 0.215, 1.613, -0.096)"
                xlinkHref="#radial-gradient-3"
              />
              <radialGradient
                id="radial-gradient-5"
                cx="0.5"
                cy="0.445"
                r="1.094"
                gradientTransform="matrix(0.334, 0.224, -0.031, 0.389, 0.349, 0.193)"
                xlinkHref="#radial-gradient-3"
              />
              <radialGradient
                id="radial-gradient-6"
                cx="0.501"
                cy="0.5"
                r="0.356"
                gradientTransform="matrix(3.402, 0.928, -8.475, 0.373, 3.037, -0.15)"
                xlinkHref="#radial-gradient-3"
              />
              <radialGradient
                id="radial-gradient-7"
                cx="0.5"
                cy="0.5"
                r="3.249"
                gradientTransform="matrix(0.102, 0.373, -0.041, 0.928, 0.47, -0.15)"
                xlinkHref="#radial-gradient-3"
              />
            </defs>
            <g id="pixer" transform="translate(-190.864 -185.39)">
              <rect
                id="Rectangle_14"
                data-name="Rectangle 14"
                width="141.313"
                height="9.872"
                transform="translate(252.392 185.89)"
                stroke="#000"
                strokeMiterlimit="10"
                strokeWidth="1"
                fill="url(#linear-gradient)"
              />
              <rect
                id="Rectangle_15"
                data-name="Rectangle 15"
                width="106.614"
                height="2.468"
                transform="translate(269.742 199.918)"
                stroke="#000"
                strokeMiterlimit="10"
                strokeWidth="1"
                fill="url(#linear-gradient-2)"
              />
              <rect
                id="Rectangle_16"
                data-name="Rectangle 16"
                width="106.614"
                height="5.835"
                transform="translate(269.742 202.384)"
                stroke="#000"
                strokeMiterlimit="10"
                strokeWidth="1"
                fill="url(#linear-gradient-3)"
              />
              <rect
                id="Rectangle_17"
                data-name="Rectangle 17"
                width="106.614"
                height="2.468"
                transform="translate(269.742 208.222)"
                stroke="#000"
                strokeMiterlimit="10"
                strokeWidth="1"
                fill="url(#linear-gradient-2)"
              />
              <rect
                id="Rectangle_18"
                data-name="Rectangle 18"
                width="221.702"
                height="2.401"
                transform="translate(212.199 395.587)"
                stroke="#000"
                strokeMiterlimit="10"
                strokeWidth="1"
                fill="url(#linear-gradient-5)"
              />
              <rect
                id="Rectangle_19"
                data-name="Rectangle 19"
                width="221.702"
                height="6.269"
                transform="translate(212.199 397.988)"
                stroke="#000"
                strokeMiterlimit="10"
                strokeWidth="1"
                fill="url(#linear-gradient-2)"
              />
              <rect
                id="Rectangle_20"
                data-name="Rectangle 20"
                width="221.702"
                height="2.65"
                transform="translate(212.199 404.257)"
                stroke="#000"
                strokeMiterlimit="10"
                strokeWidth="1"
                fill="url(#linear-gradient-3)"
              />
              <path
                id="Path_10"
                data-name="Path 10"
                d="M444.7,228.776l17.117,4.1H568.429l17.089-4.085a.032.032,0,0,0,0-.062H444.705a.028.028,0,0,0-.007.046Z"
                transform="translate(-192.059 -32.959)"
                stroke="#000"
                strokeMiterlimit="10"
                strokeWidth="1"
                fill="url(#linear-gradient-8)"
              />
              <rect
                id="Rectangle_21"
                data-name="Rectangle 21"
                width="256"
                height="9.872"
                transform="translate(195.05 411.066)"
                stroke="#000"
                strokeMiterlimit="10"
                strokeWidth="1"
                fill="url(#linear-gradient)"
              />
              <path
                id="Path_11"
                data-name="Path 11"
                d="M195.05,1148.286l17.149-4.156H433.9l17.149,4.156Z"
                transform="translate(0 -737.221)"
                fill="#e2e2e2"
                stroke="#000"
                strokeMiterlimit="10"
                strokeWidth="1"
              />
              <path
                id="Path_12"
                data-name="Path 12"
                d="M451.05,328.356h-256l74.692-34.946H376.358Z"
                transform="translate(0 -82.72)"
                stroke="#000"
                strokeMiterlimit="10"
                strokeWidth="1"
                fill="url(#radial-gradient)"
              />
              <rect
                id="Rectangle_22"
                data-name="Rectangle 22"
                width="256"
                height="59.803"
                transform="translate(195.05 245.636)"
                stroke="#000"
                strokeMiterlimit="10"
                strokeWidth="1"
                fill="url(#linear-gradient-10)"
              />
              <rect
                id="Rectangle_23"
                data-name="Rectangle 23"
                width="256"
                height="76.892"
                transform="translate(195.05 308.417)"
                stroke="#000"
                strokeMiterlimit="10"
                strokeWidth="1"
                fill="url(#linear-gradient-10)"
              />
              <path
                id="Path_13"
                data-name="Path 13"
                d="M449.265,705.7H196.773l-1.654-1.465a.014.014,0,0,1,0-.023H451.078Z"
                transform="translate(-0.049 -398.769)"
                stroke="#000"
                strokeMiterlimit="10"
                strokeWidth="1"
                fill="url(#linear-gradient-12)"
              />
              <path
                id="Path_14"
                data-name="Path 14"
                d="M449.278,710.66H196.793l-1.663,1.462v.016H451.086s.012,0,0-.016Z"
                transform="translate(-0.062 -403.731)"
                stroke="#000"
                strokeMiterlimit="10"
                strokeWidth="1"
                fill="url(#linear-gradient-13)"
              />
              <path
                id="Path_15"
                data-name="Path 15"
                d="M433.9,1060.758H212.2L195.05,1050.48h256Z"
                transform="translate(0 -665.171)"
                stroke="#000"
                strokeMiterlimit="10"
                strokeWidth="1"
                fill="url(#linear-gradient-14)"
              />
              <ellipse
                id="Ellipse_10"
                data-name="Ellipse 10"
                cx="15.454"
                cy="6.096"
                rx="15.454"
                ry="6.096"
                transform="translate(307.486 228.161)"
                stroke="#000"
                strokeMiterlimit="10"
                strokeWidth="1"
                fill="url(#radial-gradient-2)"
              />
              <ellipse
                id="Ellipse_11"
                data-name="Ellipse 11"
                cx="15.454"
                cy="5.3"
                rx="15.454"
                ry="5.3"
                transform="translate(307.486 229.753)"
                stroke="#000"
                strokeMiterlimit="10"
                strokeWidth="1"
                fill="url(#radial-gradient-3)"
              />
              <ellipse
                id="Ellipse_12"
                data-name="Ellipse 12"
                cx="5.174"
                cy="15.117"
                rx="5.174"
                ry="15.117"
                transform="translate(367.144 236.079) rotate(-77.61)"
                stroke="#000"
                strokeMiterlimit="10"
                strokeWidth="1"
                fill="url(#radial-gradient-4)"
              />
              <path
                id="Path_16"
                data-name="Path 16"
                d="M947,380.271s1.631-2.83,8.084-2.235c5.427.5,16.927,2.855,20.574,8.26"
                transform="translate(-578.512 -147.764)"
                fill="none"
                stroke="#000"
                strokeMiterlimit="10"
                strokeWidth="1"
              />
              <ellipse
                id="Ellipse_13"
                data-name="Ellipse 13"
                cx="15.117"
                cy="5.174"
                rx="15.117"
                ry="5.174"
                transform="translate(247 232.438) rotate(-12.39)"
                stroke="#000"
                strokeMiterlimit="10"
                strokeWidth="1"
                fill="url(#radial-gradient-5)"
              />
              <path
                id="Path_17"
                data-name="Path 17"
                d="M456.439,380.271s-1.631-2.83-8.073-2.235c-5.43.5-16.93,2.855-20.576,8.26"
                transform="translate(-179.058 -147.764)"
                fill="none"
                stroke="#000"
                strokeMiterlimit="10"
                strokeWidth="1"
              />
              <ellipse
                id="Ellipse_14"
                data-name="Ellipse 14"
                cx="1.651"
                cy="15.082"
                rx="1.651"
                ry="15.082"
                transform="translate(406.71 230.174) rotate(-68.13)"
                stroke="#000"
                strokeMiterlimit="10"
                strokeWidth="1"
                fill="url(#radial-gradient-6)"
              />
              <path
                id="Path_18"
                data-name="Path 18"
                d="M1119,375.62a119.876,119.876,0,0,1,26.193,10.419"
                transform="translate(-710.84 -145.969)"
                fill="none"
                stroke="#000"
                strokeMiterlimit="10"
                strokeWidth="1"
              />
              <ellipse
                id="Ellipse_15"
                data-name="Ellipse 15"
                cx="15.082"
                cy="1.651"
                rx="15.082"
                ry="1.651"
                transform="translate(210.225 238.342) rotate(-21.87)"
                stroke="#000"
                strokeMiterlimit="10"
                strokeWidth="1"
                fill="url(#radial-gradient-7)"
              />
              <path
                id="Path_19"
                data-name="Path 19"
                d="M293.943,375.62a119.829,119.829,0,0,0-26.193,10.419"
                transform="translate(-55.932 -145.969)"
                fill="none"
                stroke="#000"
                strokeMiterlimit="10"
                strokeWidth="1"
              />
            </g>
          </SVG>
          {progress > 0 && !isTemplate && (
            <SpeedWrap>
              <CurrentSpeed
                sx={{
                  width: `${(progress / MAX_VALUE) * 100}%`,
                }}
              />
            </SpeedWrap>
          )}
          {!isTemplate && (
            <svg
              viewBox="0 0 150 34"
              width="600%"
              style={{ marginLeft: '-250%' }}
            >
              <text
                x="50%"
                y="24"
                dominantBaseline="middle"
                textAnchor="middle"
                fontSize="22"
                fontFamily="Source Sans Pro', sans-serif"
                fill={progress > 0 ? '#10B981' : '#334155'}
              >
                {`${Math.round(progress)} RPM`}
              </text>
            </svg>
          )}
        </div>
      </div>
    </Box>
  );
}

Pixer.defaultProps = {
  data: {},
  currentNameTypes: false,
  systemName: undefined,
  isTemplate: false,
};

export default Pixer;
