/* eslint-disable no-nested-ternary */
import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { useSelector } from 'react-redux';
import yaml from 'js-yaml';
import {
  Box,
  styled,
  IconButton,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { isIOS } from 'react-device-detect';
import { useHistory, useParams } from 'react-router-dom';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { Resizable } from 're-resizable';
import _ from 'lodash';
import { editor } from 'monaco-editor/esm/vs/editor/editor.api';
import { ROUTES_PATH } from '../../constants';
import { useGetVisualsQuery } from '../../redux/services/visuals/api';
import EditArea from '../../common/Diagram/CreateProjectDiagram/EditArea';
import Table from '../../components/project/Table';
import { IAppState } from '../../typescript/interfaces/appstate.interface';
import DeleteConfigTemplate from '../../common/Dialogs/DeleteConfigTemplate';
import { createConfigTemplate } from '../../common/Diagram/CreateProjectDiagram/utils';
import {
  getProjectConfigurationTemplateByIdDocument,
  getSystemConfigurationTemplateByIdDocument,
} from '../../api/index';
import Loader from '../../common/Loader';
import ConfigTabs from '../../components/project/create-config/ConfigTabs';
import { setRedirect } from '../../redux/modules/redirect';
import {
  useDeleteSystemConfigurationTemplateMutation,
  useGetSystemConfigurationTemplatesQuery,
} from '../../redux/services/systemConfigurationTemplate/api';
import ConfigrationActions from '../../components/project/create-config/ConfigrationActions';
import LeftContentBlock from '../../components/project/create-config/LeftContent';
import RightContentBlock from '../../components/project/create-config/RightContent';
import { useDeleteProjectMutation } from '../../redux/services/projects/api';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import useSnackbar from '../../hooks/useSnackbar';
import { getDeletedFav } from '../../utils/getDeletedFav';
import { setQuickAccess } from '../../redux/modules/quickAccess';
import { isForbiddenError } from '../../redux/utils';
import useGetPermissions from '../../hooks/useGetPermissions';

export const InnerWrap = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexFlow: 'row nowrap',
  backgroundColor: theme.palette.background.default,
  marginTop: theme.spacing(2),
  maxHeight: 'calc(100% - 60px)',
  paddingBottom: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    marginBottom: '50px',
    paddingBottom: 0,
  },
}));

export const LeftContent = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  height: '100%',
  padding: theme.spacing(2),
  borderTopLeftRadius: '4px',
  borderBottomLeftRadius: '4px',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1),
  },
  '&.fullscreen': {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 9999,
    backgroundColor: theme.palette.background.default,
  },
}));

export const Sidebar = styled(Box)(({ theme }) => ({
  flex: 1,
  height: '100%',
  maxHeight: '100%',
  overflow: 'hidden',
  width: '100%',
  boxSizing: 'border-box',
  marginLeft: theme.spacing(3),
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: '3px',
  '& + .handle div': {
    right: '-32px !important',
  },
}));

export const Content = styled(Box)(({ theme }) => ({
  flex: 1,
  minWidth: '40%',
  width: '100%',
  padding: theme.spacing(1),
  overflow: 'auto',
  marginLeft: '35px',
  marginRight: theme.spacing(3),
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: '3px',
}));

const Root = styled(Box)(({ theme }) => ({
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  height: 'calc(100% - 60px)',
  maxHeight: 'calc(100% - 60px)',
  [theme.breakpoints.down('md')]: {
    maxHeight: 'unset',
  },
  '& .library': {
    margin: `0 ${theme.spacing(3)}`,
    marginBottom: theme.spacing(2),
  },
}));

const HeadWrap = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(2)} ${theme.spacing(3)} 0 ${theme.spacing(3)}`,
  position: 'relative',
  [theme.breakpoints.down('md')]: {
    padding: 0,
    paddingTop: theme.spacing(1),
  },
  [theme.breakpoints.down('sm')]: {
    paddingRight: 0,
  },
}));

const Head = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  paddingRight: 0,
  [theme.breakpoints.down('md')]: {
    paddingRight: theme.spacing(3),
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingRight: theme.spacing(2),
  },
}));

const FullscreenBtn = styled(IconButton)(({ theme }) => ({
  height: '44px',
  width: '44px',
  marginRight: 0,
  marginLeft: theme.spacing(2),
  color: theme.palette.mode === 'light' ? theme.palette.secondary.main : '#fff',
  border: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.paper,
  '&.diagram': {
    backgroundColor: '#fff',
    borderColor: '#EBF4F9',
    color: theme.palette.secondary.main,
  },
  '&.active': {
    backgroundColor: theme.palette.secondary.main,
    color: '#fff',
  },
}));

function CreateConfigration() {
  const theme = useTheme();
  const handleRight = useFullScreenHandle();
  const handleLeft = useFullScreenHandle();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));
  const [configName, setConfigName] = useState('');
  const [textValue, setTextValue] = useState<string>('');
  const [configNameInvalid, setConfigNameInvalid] = useState(false);
  const [fullscreenRight, setFullscreenRight] = useState(false);
  const [fullscreenLeft, setFullscreenLeft] = useState(false);

  const [jsonData, setJsonData] = useState<any>();
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState({ message: '', reason: '' });
  const [isFileUpload, setIsFileUpload] = useState(false);
  const [isComponentsMovement, setIsComponentsMovement] = useState(false);
  const [isEditor, setIsEditor] = useState(true);
  const [isPaste, setIsPaste] = useState(false);
  const [isDeleteWindowOpen, setIsDeleteWindowOpen] = useState(false);
  const [libOpened, setLibOpened] = useState(false);
  const [morePopupElement, setMorePopupElement] = useState<null | HTMLElement>(
    null,
  );
  const [configData, setConfigData] = useState({ name: '', description: '' });
  const [isImport, setIsImport] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingVisuals, setIsLoadingVisuals] = useState(true);
  const [isFirstRender, setIsFirstRender] = useState(false);
  const [selectedNodeId, setSelectedNodeId] = useState<undefined | string>();
  const [nodesChanges, setNodesChanges] = useState([]);
  const [edgeNodesChanges, setEdgeNodesChanges] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);
  const [isFirstInit, setIsFirstInit] = useState(false);
  const [isFirstLoading, setIsFirstLoadnig] = useState(true);
  const [yamlInstance, setYamlInstance] =
    useState<editor.IStandaloneCodeEditor>();

  const { showSuccess, showError } = useSnackbar();

  const {
    data: systemConfigurationTemplates = [],
    error: systemConfigurationErrors,
  } = useGetSystemConfigurationTemplatesQuery(undefined);
  const [
    deleteSystemConfiguration,
    {
      isSuccess: isConfigurationDeletionSuccess,
      error: configurationDeletionError,
    },
  ] = useDeleteSystemConfigurationTemplateMutation();
  const { data: visualsSelector } = useGetVisualsQuery();
  const [
    deleteProject,
    { isSuccess: isProjectDeletionSuccess, error: projectDeletionError },
  ] = useDeleteProjectMutation();
  const redirect = useSelector((state: IAppState) => state.redirect);
  const fileInputRef = useRef(null);

  const quickAccess = useAppSelector((state: IAppState) => state.quickAccess);

  const history = useHistory();

  const { id: configIdParam } = useParams<{ id: string }>();

  const configType = useMemo(
    () => (configIdParam?.indexOf('PROJ') > -1 ? 'project' : 'template'),
    [configIdParam],
  );

  const { isActionGranted: isEditorHandlingGranted } = useGetPermissions({
    permissionsPath:
      configType === 'template'
        ? 'structure:systemConfigurationTemplates:create'
        : 'structure:projects:update',
  });

  useEffect(() => {
    if (!configIdParam) {
      setTimeout(() => {
        setIsFirstInit(true);
      }, 1500);
    }
  }, []);

  useEffect(() => {
    if (isFirstInit && !isFirstRender && !isLoading && isFirstLoading) {
      setTimeout(() => {
        setIsFirstLoadnig(false);
      }, 1500);
    }
  }, [isFirstInit, isFirstRender, isFirstLoading]);

  useEffect(() => {
    if (isUpdated) {
      setIsUpdated(false);
    }
  }, [isUpdated]);
  const isSuccess = useMemo(
    () => isConfigurationDeletionSuccess || isProjectDeletionSuccess,
    [isProjectDeletionSuccess, isConfigurationDeletionSuccess],
  );

  const handleErrorReset = () => {
    setIsError(false);
    setError({ message: '', reason: '' });
  };

  const handleUndo = () => {
    if (yamlInstance) {
      yamlInstance.trigger('source', 'undo', undefined);
    }
  };

  const handleRedo = () => {
    if (yamlInstance) {
      yamlInstance.trigger('myapp', 'redo', undefined);
    }
  };

  const handleDebounceFn = (inputValue: string) => {
    setTextValue((prev) => {
      if (prev !== inputValue) {
        try {
          yaml.load(textValue);
        } catch (e: any) {
          setIsError(true);
          // @ts-ignore
          setError((prevE) => (prevE.mark.line === e.mark.line ? prevE : e));
        }
        return inputValue;
      }
      return prev;
    });
  };

  const debounceFn = useCallback(_.debounce(handleDebounceFn, 1000), []);

  useEffect(() => {
    if (visualsSelector?.length > 0) {
      setIsLoadingVisuals(false);
    } else {
      setIsLoadingVisuals(true);
    }
  }, [visualsSelector]);

  useEffect(() => {
    if (configIdParam) {
      (async () => {
        setIsLoading(true);
        let res;

        try {
          if (configIdParam.startsWith('PROJ'))
            res = await getProjectConfigurationTemplateByIdDocument(
              configIdParam,
            );
          if (configType === 'template' && !configIdParam.startsWith('PROJ'))
            res = await getSystemConfigurationTemplateByIdDocument(
              configIdParam,
            );

          if (res) {
            setTextValue(res);
            setIsFirstRender(true);
            setTimeout(() => {
              setIsFirstRender(false);
              setIsFirstInit(true);
            }, 1500);
          }
          setIsLoading(false);
        } catch (e) {
          setIsLoading(false);
        }
      })();
    }
  }, [configIdParam]);

  useEffect(() => {
    if (systemConfigurationTemplates) {
      const newConfig = systemConfigurationTemplates.filter(
        ({ id }) => id === configIdParam,
      );
      if (newConfig[0]) {
        // @ts-ignore
        setConfigData((prev) => {
          if (JSON.stringify(prev) !== JSON.stringify(newConfig[0])) {
            setIsFileUpload(true);
            return newConfig[0];
          }
          return prev;
        });
      }
    }
  }, [systemConfigurationTemplates]);

  useEffect(() => {
    if (!configIdParam) {
      const currentCreateConfig = localStorage.getItem('currentCreateConfig');
      localStorage.removeItem('currentCreateConfig');

      const parsedData =
        currentCreateConfig || createConfigTemplate(configData);
      if (parsedData) {
        handleDebounceFn(parsedData);
        setIsFileUpload(true);
      }
    }
  }, [configData]);

  const dispatch = useAppDispatch();

  const handleChange = (newVal: string) => {
    setConfigNameInvalid(false);
    setConfigName(newVal);
  };

  const handleEditorMode = () => {
    setIsEditor((prev) => !prev);
  };

  const handleValue = (ev: string) => {
    if (!isLoading) debounceFn(ev);
  };

  const nodesDiff = (newState, oldState) => {
    const newObject = newState
      .map((newStateComponent) => {
        const oldData = oldState.find(({ id }) => id === newStateComponent.id);
        return {
          ...newStateComponent,
          fromPosition: {
            x: oldData?.position?.x,
            y: oldData?.position?.y,
          },
        };
      })
      .filter((newStateComponent) => {
        const oldData = oldState.find(({ id }) => id === newStateComponent.id);
        return (
          JSON.stringify(newStateComponent?.position) !==
            JSON.stringify(newStateComponent?.fromPosition) ||
          oldData?.position === undefined
        );
      });
    return newObject;
  };

  const handlePopupClose = () => {
    setMorePopupElement(null);
  };

  const handleDeleteConfigWindow = () => {
    handlePopupClose();
    setIsDeleteWindowOpen((prev) => !prev);
  };

  const handleExit = () => {
    if (configType === 'template') {
      dispatch(
        setRedirect({
          route: ROUTES_PATH.PROJECTS,
          state: { addDialogOpened: true },
        }),
      );
    }
    if (configType === 'project') {
      dispatch(
        setRedirect({
          route: `${ROUTES_PATH.PROJECT_DETAIL}/${configIdParam}`,
          state: {},
        }),
      );
    }
  };

  const handleRemoveLocalStorageData = () => {
    const createProjectData = JSON.parse(
      sessionStorage.getItem('createProjectData'),
    );
    sessionStorage.setItem(
      'createProjectData',
      JSON.stringify({
        ...createProjectData,
        basedOn: '',
      }),
    );
  };

  const quickAccessId = quickAccess.map(
    (link) =>
      link.split('/').filter((entinity) => entinity.startsWith('PROJ'))[0],
  );

  const deletedFav = useMemo(
    () => getDeletedFav(quickAccess, configIdParam),
    [quickAccess, configIdParam],
  );
  const handleConfigDelete = () => {
    if (configIdParam) {
      if (quickAccessId.find((entId) => entId === configIdParam)) {
        dispatch(setQuickAccess(deletedFav));
      }
      if (configType === 'template') {
        deleteSystemConfiguration(configIdParam);
      }
      if (configType === 'project') {
        deleteProject(configIdParam);
      }
    }
  };

  useEffect(() => {
    if (isSuccess) {
      showSuccess(`${configName} was successfully deleted`);
      handleRemoveLocalStorageData();
      handleExit();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (
      isForbiddenError(projectDeletionError) ||
      isForbiddenError(configurationDeletionError)
    ) {
      showError('You do not have permission to delete this configuration');
      return;
    }
    if (projectDeletionError || configurationDeletionError) {
      showError(`${configName} was not deleted`);
    }
  }, [projectDeletionError, configurationDeletionError]);

  useEffect(() => {
    if (redirect?.state?.editDialogOpened) {
      setLibOpened(true);
    }
    if (redirect.route !== '') {
      const createProjectData = JSON.parse(
        sessionStorage.getItem('createProjectData'),
      );
      sessionStorage.setItem(
        'createProjectData',
        JSON.stringify({
          ...createProjectData,
          basedOn: createProjectData?.basedOn || '',
        }),
      );

      history.push(redirect.route, redirect.state);
    }
  }, [redirect]);

  const handleClickNode = (_ev: any, node: Node & { id: string }) => {
    if (node === undefined) {
      setSelectedNodeId(undefined);
      return;
    }
    setSelectedNodeId((prev) => (prev === node.id ? undefined : node.id));
  };

  const [tabValue, setTabValue] = useState<string>('0');

  const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    setJsonData((prev) => {
      let newVal;
      try {
        newVal = yaml.load(textValue);
        handleErrorReset();
      } catch (err: any) {
        setError(err);
        setIsError(true);
      }

      if (!newVal) return prev;
      return newVal;
    });
  }, [textValue]);

  useEffect(() => {
    if (jsonData?.name) {
      setConfigName(jsonData.name);
    }
  }, [jsonData]);

  useEffect(() => {
    const beforeUnLoad = () => {
      localStorage.setItem('currentCreateConfig', textValue);
    };

    if (!configIdParam) {
      window.addEventListener('beforeunload', beforeUnLoad);
    }

    return () => {
      window.removeEventListener('beforeunload', beforeUnLoad);
    };
  }, [textValue, configIdParam]);

  return (
    <Root>
      <HeadWrap>
        <Head>
          <ConfigrationActions
            desktop={desktop}
            libOpened={libOpened}
            isLoadingVisuals={isLoadingVisuals}
            fileInputRef={fileInputRef}
            textValue={textValue}
            configName={configName}
            configData={configData}
            configID={configIdParam}
            configType={configType}
            setMorePopupElement={setMorePopupElement}
            setLibOpened={setLibOpened}
            setIsImport={setIsImport}
            setTextValue={setTextValue}
            setIsFileUpload={setIsFileUpload}
            setConfigNameInvalid={setConfigNameInvalid}
            morePopupElement={morePopupElement}
            handleDeleteConfigWindow={handleDeleteConfigWindow}
            setIsUpdated={setIsUpdated}
            handleExit={handleExit}
            isUpdated={isUpdated}
            isFirstInit={isFirstInit}
            onUndo={handleUndo}
            onRedo={handleRedo}
            yamlInstance={yamlInstance}
            isEditable={isEditorHandlingGranted}
            isForMobile
            isForDesktop
          />
        </Head>
      </HeadWrap>
      <InnerWrap
        sx={{
          '& > div': {
            display: desktop || tabValue === '0' ? 'block' : 'none',
            width: !desktop ? '100% !important' : '',
            '& aside': {
              margin: !desktop ? 0 : '',
            },
          },
          '& > section': {
            display: desktop || tabValue === '1' ? 'block' : 'none',
            margin: !desktop ? 0 : '',
          },
        }}
      >
        <Resizable
          style={{
            width: '50%',
            minWidth: '200px',
            backgroundColor: 'bg.main',
          }}
          handleWrapperClass="handle"
          defaultSize={{
            width: '50%',
            height: '100%',
          }}
          enable={{ right: true }}
        >
          <Sidebar
            component="aside"
            sx={{
              '& .fullscreen': {
                width: '100%',
                height: '100%',
              },
            }}
          >
            <FullScreen handle={handleLeft}>
              <LeftContent className={fullscreenLeft ? 'fullscreen' : ''}>
                <LeftContentBlock
                  fullscreenLeft={fullscreenLeft}
                  configNameInvalid={configNameInvalid}
                  configName={configName}
                  handleChange={handleChange}
                  handleEditorMode={handleEditorMode}
                  isEditor={isEditor}
                  isIOS={isIOS}
                  handleLeft={handleLeft}
                  setFullscreenLeft={setFullscreenLeft}
                  FullscreenBtn={FullscreenBtn}
                  isEditable={isEditorHandlingGranted}
                />
                <Box
                  sx={{
                    border: '1px solid',
                    borderColor: 'divider',
                    height: 'calc(100% - 60px)',
                  }}
                >
                  {isLoading ? (
                    <Loader />
                  ) : (
                    <>
                      {isEditor ? (
                        <EditArea
                          value={textValue}
                          nodesChanges={nodesChanges}
                          setNodesChanges={setNodesChanges}
                          edgeNodesChanges={edgeNodesChanges}
                          setEdgeNodesChanges={setEdgeNodesChanges}
                          handleValue={handleValue}
                          language="yaml"
                          name={configName}
                          handleConfigNameChange={handleChange}
                          setIsFileUpload={setIsFileUpload}
                          setIsPaste={setIsPaste}
                          isFileUpload={isFileUpload}
                          isComponentsMovement={isComponentsMovement}
                          setIsComponentsMovement={setIsComponentsMovement}
                          isImport={isImport}
                          setIsImport={setIsImport}
                          isFirstRender={isFirstRender}
                          // @ts-ignore
                          errors={systemConfigurationErrors?.data?.details}
                          selectedNodeId={selectedNodeId}
                          isUpdated={isUpdated}
                          isFirstInit={isFirstInit}
                          yamlInstance={yamlInstance}
                          setYamlInstance={setYamlInstance}
                          isEditable={isEditorHandlingGranted}
                        />
                      ) : (
                        <Table data={jsonData} />
                      )}
                    </>
                  )}
                </Box>
              </LeftContent>
            </FullScreen>
          </Sidebar>
        </Resizable>
        <Content
          component="section"
          sx={{
            // eslint-disable-next-line no-nested-ternary
            // display: desktop ? 'block' : tabValue === '1' ? 'block' : 'none',
            p: 0,
            '& .fullscreen': {
              width: '100%',
              height: '100%',
            },
          }}
        >
          <FullScreen handle={handleRight}>
            <RightContentBlock
              isError={isError}
              isIOS={isIOS}
              handleRight={handleRight}
              FullscreenBtn={FullscreenBtn}
              fullscreenRight={fullscreenRight}
              setFullscreenRight={setFullscreenRight}
              isLoading={isLoading}
              isFirstLoading={isFirstLoading}
              textValue={textValue}
              handleChange={handleChange}
              isFileUpload={isFileUpload}
              isPaste={isPaste}
              setIsPaste={setIsPaste}
              setError={setError}
              handleClickNode={handleClickNode}
              error={error}
              setIsImport={setIsImport}
              setTextValue={setTextValue}
              setIsError={setIsError}
              nodesDiff={nodesDiff}
              setNodesChanges={setNodesChanges}
              setEdgeNodesChanges={setEdgeNodesChanges}
              isEditable={isEditorHandlingGranted}
            />
          </FullScreen>
        </Content>
      </InnerWrap>
      {configIdParam && (
        <DeleteConfigTemplate
          entity={{ name: configName }}
          open={isDeleteWindowOpen}
          onClose={handleDeleteConfigWindow}
          handleAction={handleConfigDelete}
        />
      )}
      {!desktop && <ConfigTabs onChange={handleTabChange} value={tabValue} />}
    </Root>
  );
}

export default CreateConfigration;
