import React from 'react';
import { Box } from '@mui/material';

interface Props {
  className?: string;
  data?: any;
}

function Label(props: Props) {
  const { className, data } = props;

  return (
    <Box
      className={className}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
      }}
    >
      <span
        style={{
          zIndex: 99,
          textAlign: 'center',
          color: '#fff',
        }}
      >
        <span
          style={{
            // position: 'absolute',
            display: 'block',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            backgroundColor: '#702F8A',
            padding: '0 2px',
            fontSize: '1.25rem',
            borderRadius: '2px',
            lineHeight: 1.5,
            textAnchor: 'middle',
            dominantBaseline: 'middle',
            color: 'white',
          }}
        >
          {data?.label || 'Label'}
          {data?.suffix || ''}
        </span>
      </span>
    </Box>
  );
}

Label.defaultProps = {
  className: '',
  data: {},
};

export default Label;
