import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import yaml from 'js-yaml';
import { Box, styled, useTheme } from '@mui/material';
import { editor } from 'monaco-editor/esm/vs/editor/editor.api';
import { Prompt } from 'react-router';
import { setDiagnosticsOptions } from 'monaco-yaml';
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import EditorWorker from 'worker-loader!monaco-editor/esm/vs/editor/editor.worker.js';
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import YamlWorker from 'worker-loader!monaco-yaml/yaml.worker.js';
import * as monaco from 'monaco-editor';
import { diffLines } from 'diff';
import './index.css';
import { templateSchema } from './schema';
import { resetDecorations } from './utils';
import useDebounce from '../../../hooks/useDebounce';
import { REDIRECT_URI } from '../../../constants';

const Root = styled(Box)(({ theme }) => ({
  position: 'relative',
  height: '100%',
  '&>section': {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100% !important',
  },
  '& .margin-view-overlays, .monaco-editor-background': {
    backgroundColor: theme.palette.background.default,
  },
  '& .monaco-editor .scrollbar > .slider': {
    backgroundColor: theme.palette.mode === 'dark' ? '#555e6e' : '#d8e1e5',
  },
  '& .iPadShowKeyboard': {
    display: 'none !important',
  },
  '& .monaco-editor .margin-view-overlays .line-numbers': {
    textAlign: 'left',
    paddingLeft: theme.spacing(1),
  },
  '& .monaco-editor .cldr.codicon': {
    [theme.breakpoints.down('md')]: {
      opacity: '1 !important',
    },
  },
}));

window.MonacoEnvironment = {
  getWorker(moduleId, label) {
    if (label.toLocaleLowerCase() === 'yaml') {
      return new YamlWorker();
    }
    return new EditorWorker();
  },
};

setDiagnosticsOptions({
  validate: true,
  enableSchemaRequest: true,
  format: true,
  hover: true,
  completion: true,
  schemas: templateSchema,
});

interface Props {
  value: string;
  name: string;
  handleValue: (newValue: string) => void;
  handleConfigNameChange: (newValue: string) => void;
  setIsFileUpload: (fileUpload: boolean) => void;
  setIsPaste?: (flag: boolean) => void;
  isFileUpload: boolean;
  language?: string;
  isComponentsMovement: boolean;
  setIsComponentsMovement: (isComponentsMovement: boolean) => void;
  isImport: boolean;
  isFirstRender: boolean;
  isFirstInit: boolean;
  setIsImport: (bool: boolean) => void;
  errors: any[];
  selectedNodeId: string | undefined;
  nodesChanges: any;
  setNodesChanges: (changes: any) => void;
  edgeNodesChanges: any;
  setEdgeNodesChanges: (changes: any) => void;
  isUpdated: boolean;
  yamlInstance: editor.IStandaloneCodeEditor;
  setYamlInstance: Dispatch<SetStateAction<editor.IStandaloneCodeEditor>>;
  isEditable: boolean;
}

const EditArea = (props: Props) => {
  const {
    language,
    value,
    name,
    handleConfigNameChange,
    setIsFileUpload,
    isFileUpload,
    isComponentsMovement,
    setIsComponentsMovement,
    isImport,
    setIsImport,
    isFirstRender,
    isFirstInit,
    errors,
    selectedNodeId,
    handleValue,
    nodesChanges,
    setNodesChanges,
    edgeNodesChanges,
    setEdgeNodesChanges,
    isUpdated,
    yamlInstance,
    setYamlInstance,
    isEditable,
  } = props;

  const theme = useTheme();
  const ref = useRef(null);
  const [localValue, setLocalValue] = useState(value);
  const [oldValue, setOldValue] = useState(value);
  const [highlightedValue, setHighlightedValue] = useState([]);
  const [decorations, setDecorations] = useState<string[]>([]);
  const [markers, setMarkers] = useState<monaco.editor.IMarker[]>([]);
  const editVersion = useRef(1);

  const debouncedNodesChanges = useDebounce(nodesChanges, 100);
  const debouncedEdgeNodesChanges = useDebounce(edgeNodesChanges, 100);

  useEffect(() => {
    if (!oldValue && value) {
      setOldValue(value);
    }
  }, [oldValue, value]);

  useEffect(() => {
    if (isFirstInit) {
      setOldValue(value);
    }
  }, [isFirstInit]);

  useEffect(() => {
    setLocalValue((prev) => (isImport ? prev : value));
  }, [value, isImport]);

  const handleCleanDecorations = () => {
    if (yamlInstance) {
      const allDecorations = yamlInstance.getModel().getAllDecorations();
      yamlInstance.removeDecorations(allDecorations.map(({ id }) => id));
    }
  };

  useEffect(() => {
    if (yamlInstance) {
      const namePosition = yamlInstance
        .getModel()
        .findMatches('name: ', true, false, true, null, false)
        .filter(({ range }) => range.startColumn === 1);

      if (namePosition.length > 0) {
        const nameValue = yamlInstance.getModel().getValueInRange({
          startLineNumber: namePosition[0].range.endLineNumber,
          startColumn: namePosition[0].range.endColumn,
          endLineNumber: namePosition[0].range.endLineNumber,
          endColumn: 100000,
        });

        if (name !== nameValue) {
          const range = new monaco.Range(
            namePosition[0].range.endLineNumber,
            namePosition[0].range.endColumn,
            namePosition[0].range.endLineNumber,
            1000,
          );
          const op = {
            identifier: {
              major: editVersion.current,
              minor: editVersion.current,
            },
            range,
            text: `${name}`,
            forceMoveMarkers: false,
          };

          editVersion.current += 1;

          yamlInstance.executeEdits('source', [op]);
        }
      }
    }
  }, [name]);

  const diffLinesAdded = useCallback(
    (diffs, idx, diff) => {
      let changesPositions = yamlInstance
        .getModel()
        .findMatches(diffs[idx - 1].value, true, false, true, null, false);
      if (changesPositions.length === 0) {
        changesPositions = yamlInstance
          .getModel()
          .findMatches(diffs[0].value, true, false, true, null, false);
      }
      if (changesPositions.length > 0) {
        const range = new monaco.Range(
          changesPositions[0].range.endLineNumber,
          changesPositions[0].range.endColumn,
          changesPositions[0].range.endLineNumber,
          changesPositions[0].range.endColumn,
        );
        const op = {
          identifier: {
            major: editVersion.current,
            minor: editVersion.current,
          },
          range,
          text: `${diff.value}`,
          forceMoveMarkers: true,
        };
        editVersion.current += 1;
        yamlInstance.executeEdits('source', [op]);
        if (isImport) {
          const posForHighlight = yamlInstance
            .getModel()
            .findMatches(diff.value, true, false, true, null, false);
          const positionForHighlight = JSON.parse(
            JSON.stringify(posForHighlight),
          )[0];
          positionForHighlight.range.endLineNumber -= 1;
          setHighlightedValue((prev) =>
            [...prev, positionForHighlight].map((val) => ({
              ...val,
              isImport: true,
            })),
          );
          setIsImport(false);
        }
      }
    },
    [yamlInstance, isImport],
  );

  const diffLinesRemoved = useCallback(
    (diff) => {
      const changesPositions = yamlInstance
        .getModel()
        .findMatches(diff.value, true, false, true, null, false);
      if (changesPositions.length > 0) {
        const range = new monaco.Range(
          changesPositions[0].range.startLineNumber,
          changesPositions[0].range.startColumn,
          changesPositions[0].range.endLineNumber,
          changesPositions[0].range.endColumn,
        );
        const op = {
          identifier: {
            major: editVersion.current,
            minor: editVersion.current,
          },
          range,
          text: null,
          forceMoveMarkers: true,
        };
        editVersion.current += 1;
        yamlInstance.executeEdits('source', [op]);
      }
    },
    [yamlInstance],
  );

  const diffLinesChanged = (diffs, idx, diff) => {
    if (diff.added) {
      diffLinesAdded(diffs, idx, diff);
    }
    if (diff.removed) {
      diffLinesRemoved(diff);
    }
  };

  useEffect(() => {
    if (yamlInstance)
      if (value !== localValue) {
        setHighlightedValue([]);
        resetDecorations(yamlInstance, decorations);
        if (typeof localValue !== 'string' || typeof value !== 'string')
          return undefined;
        const diffs = diffLines(localValue, value);
        diffs.forEach((diff, idx) => {
          if (idx > 0) {
            diffLinesChanged(diffs, idx, diff);
          }
        });
      }
    return undefined;
  }, [value, isImport]);

  const highlightedValueHandler = useCallback(
    (node, model, matchNode) => {
      if (node.fromPosition.x !== undefined && !isFirstRender) {
        const matchRenderNode = model
          .findMatches('render:', true, false, true, null, false)
          .filter(
            ({ range }) =>
              range.startLineNumber > matchNode.range.startLineNumber,
          )
          .sort(
            (a, b) =>
              Math.abs(
                a.range.startLineNumber - matchNode.range.startLineNumber,
              ) -
              Math.abs(
                b.range.startLineNumber - matchNode.range.startLineNumber,
              ),
          )[0];

        const range = new monaco.Range(
          matchRenderNode.range.startLineNumber,
          5,
          matchRenderNode.range.endLineNumber + 2,
          100,
        );

        const op = {
          identifier: {
            major: editVersion.current,
            minor: editVersion.current,
          },
          range,
          text: `render:
    - ${node.position.x.toFixed(0)}
    - ${node.position.y.toFixed(0)}`,
          forceMoveMarkers: true,
        };

        editVersion.current += 1;

        yamlInstance.executeEdits('source', [op]);
        handleCleanDecorations();
        yamlInstance.revealLineInCenter(op.range.startLineNumber);
        const highlightItem = {
          range: {
            startLineNumber: op.range.startLineNumber,
            endLineNumber: op.range.endLineNumber,
          },
        };
        yamlInstance.setPosition({
          lineNumber: op.range.endLineNumber || 0,
          column: op.range.endColumn || 0,
        });

        setHighlightedValue((prevState) =>
          prevState.every((item) => item.isImport)
            ? [...prevState, highlightItem]
            : [highlightItem],
        );
      }
    },
    [monaco, yamlInstance],
  );

  useEffect(() => {
    const ifNodesAndYamlExist =
      debouncedNodesChanges.length > 0 && yamlInstance;
    if (ifNodesAndYamlExist) {
      const model = yamlInstance.getModel();
      const componentsPosition = model
        .findMatches(`components:`, true, true, false, null, false)
        .find(({ range }) => range.startColumn === 1);

      debouncedNodesChanges.forEach((node) => {
        let matchNode = model
          .findMatches(`${node.id}:`, true, false, true, null, false)
          .filter(
            ({ range }) =>
              (range.startColumn === 3 || range.startColumn === 4) &&
              range.startLineNumber > componentsPosition.range.startLineNumber,
          )
          .sort(
            (a, b) =>
              Math.abs(
                a.range.startLineNumber -
                  componentsPosition.range.startLineNumber,
              ) -
              Math.abs(
                b.range.startLineNumber -
                  componentsPosition.range.startLineNumber,
              ),
          )[0];

        if (!matchNode)
          [matchNode] = model
            .findMatches(`"${node.id}":`, true, false, true, null, false)
            .filter(
              ({ range }) =>
                (range.startColumn === 3 || range.startColumn === 4) &&
                range.startLineNumber >
                  componentsPosition.range.startLineNumber,
            )
            .sort(
              (a, b) =>
                Math.abs(
                  a.range.startLineNumber -
                    componentsPosition.range.startLineNumber,
                ) -
                Math.abs(
                  b.range.startLineNumber -
                    componentsPosition.range.startLineNumber,
                ),
            );

        if (matchNode) {
          let range: monaco.Range;
          if (Number.isInteger(node.fromPosition.x)) {
            const [newPositionRange] = model
              .findMatches(
                `${node.fromPosition.x}`,
                true,
                false,
                true,
                null,
                false,
              )
              .filter(
                ({ range: positionRange }) =>
                  positionRange.startLineNumber >
                  matchNode.range.startLineNumber,
              );
            range = new monaco.Range(
              newPositionRange.range.startLineNumber - 1,
              5,
              newPositionRange.range.endLineNumber + 2,
              5,
            );
          }
          if (node.fromPosition.x === undefined) {
            range = new monaco.Range(
              matchNode.range.startLineNumber + 1,
              5,
              matchNode.range.endLineNumber + 1,
              5,
            );
          }
          if (range) {
            const op = {
              identifier: {
                major: editVersion.current,
                minor: editVersion.current,
              },
              range,
              text: `render:
      - ${node.position.x.toFixed(0)}
      - ${node.position.y.toFixed(0)}
    `,
              forceMoveMarkers: true,
            };

            editVersion.current += 1;

            yamlInstance.executeEdits('source', [op]);
          }
          highlightedValueHandler(node, model, matchNode);
        }
      });
      setNodesChanges([]);
    }
  }, [debouncedNodesChanges]);

  useEffect(() => {
    const ifNodesAndYamlExist =
      debouncedEdgeNodesChanges.length > 0 && yamlInstance;
    let nameConnectors;

    try {
      nameConnectors = Object.keys(yaml.load(value)?.connectors || {});
    } catch (e) {
      console.error(e);
    }

    if (ifNodesAndYamlExist && nameConnectors?.length) {
      const model = yamlInstance.getModel();
      const connectorsPosition = model
        .findMatches(`connectors:`, true, true, false, null, false)
        .find(({ range }) => range.startColumn === 1);
      const allConnectors = nameConnectors.map((id) => {
        const matchNode = model
          .findMatches(`${id}:`, true, false, true, null, false)
          .filter(
            ({ range }) =>
              (range.startColumn === 3 || range.startColumn === 4) &&
              range.startLineNumber > connectorsPosition.range.startLineNumber,
          )
          .sort(
            (a, b) =>
              Math.abs(
                a.range.startLineNumber -
                  connectorsPosition.range.startLineNumber,
              ) -
              Math.abs(
                b.range.startLineNumber -
                  connectorsPosition.range.startLineNumber,
              ),
          )[0];

        return matchNode;
      });

      debouncedEdgeNodesChanges.forEach((node) => {
        const foundIndexConnector = nameConnectors.findIndex(
          (id) => id === node.id,
        );
        const matchNode = allConnectors[foundIndexConnector];

        let matchNodeWaypoints = model.findNextMatch(
          'waypoints:',
          { lineNumber: matchNode?.range?.startLineNumber, column: 0 },
          false,
          true,
          null,
          false,
        );
        if (
          matchNodeWaypoints &&
          allConnectors[foundIndexConnector + 1] &&
          matchNodeWaypoints.range.startLineNumber >
            allConnectors[foundIndexConnector + 1].range.startLineNumber
        ) {
          matchNodeWaypoints = undefined;
        }

        if (matchNode) {
          const range = new monaco.Range(
            matchNodeWaypoints
              ? matchNodeWaypoints.range.startLineNumber
              : matchNode.range.startLineNumber + 1,
            0,
            matchNodeWaypoints
              ? matchNodeWaypoints.range.endLineNumber +
                ((node.prevWaypoints?.length || 0) * 2 + 1)
              : matchNode.range.endLineNumber + 1,
            0,
          );

          const op = {
            identifier: {
              major: editVersion.current,
              minor: editVersion.current,
            },
            range,
            text: `    waypoints:
    ${node.waypoints.map(
      (p) => `- x: ${p.x}
      y: ${p.y}`,
    ).join(`
    `)}\n`,
            forceMoveMarkers: true,
          };

          editVersion.current += 1;

          yamlInstance.executeEdits('source', [op]);
        }
      });

      setEdgeNodesChanges([]);
    }
  }, [debouncedEdgeNodesChanges]);

  const debouncedLocalValue = useDebounce(localValue, 100);

  useEffect(() => {
    if (debouncedLocalValue && yamlInstance) {
      const namePosition = yamlInstance
        .getModel()
        .findMatches('name: ', true, false, true, null, false)
        .filter(({ range }) => range.startColumn === 1);

      if (namePosition.length > 0) {
        const nameValue = yamlInstance.getModel().getValueInRange({
          startLineNumber: namePosition[0].range.endLineNumber,
          startColumn: namePosition[0].range.endColumn,
          endLineNumber: namePosition[0].range.endLineNumber,
          endColumn: 100000,
        });

        if (name !== nameValue) {
          handleConfigNameChange(nameValue);
        }
      }

      if (namePosition.length === 0) {
        handleConfigNameChange('');
      }
    }
    if (value !== debouncedLocalValue) {
      handleValue(debouncedLocalValue);
    }
  }, [debouncedLocalValue]);
  const isDark = theme.palette.mode === 'dark';

  useEffect(() => {
    const yamlInstanceLocal = editor?.create?.(ref.current, {
      automaticLayout: true,
      language,
      fontSize: 15,
      minimap: {
        scale: 1.5,
      },
      contextmenu: false,
      tabSize: 2,
      readOnly: !isEditable,
      domReadOnly: !isEditable,
      scrollBeyondLastLine: false,
    });

    setYamlInstance(yamlInstanceLocal);

    return () => {
      setYamlInstance(undefined);
    };
  }, []);

  const findAllPositions = (locs, text) => {
    if (typeof locs !== 'string')
      return locs.map((l) => findAllPositions(l, text));
    const pos = text.findMatches(locs, true, false, false, null, true);
    return pos;
  };

  const findPositivePosition = (
    positions: {
      range: Range & { startLineNumber: number };
      matches: string[] | null;
    }[][],
  ) => {
    const res = [];
    positions.forEach((d, idx) => {
      if (idx === 0) {
        res.push(d[0]);
        return;
      }
      res.push(
        d.find(
          (innerPos) =>
            innerPos.range.startLineNumber > res[idx - 1].range.startLineNumber,
        ),
      );
    });
    return res;
  };

  const getPositivePositions = (positivePositions, msg) => {
    if (positivePositions[positivePositions.length - 1] === undefined) {
      return {
        position: {
          startLineNumber: 1,
          startColumn: 1,
          endLineNumber: 1,
          endColumn: 1000,
        },
        message: msg,
      };
    }
    return {
      position: {
        startLineNumber:
          positivePositions[positivePositions.length - 1].range.startLineNumber,
        startColumn:
          positivePositions[positivePositions.length - 1].range.startColumn,
        endLineNumber:
          positivePositions[positivePositions.length - 1].range.endLineNumber,
        endColumn:
          positivePositions[positivePositions.length - 1].range.endColumn,
      },
      message: msg,
    };
  };

  const filterPosition = (
    errorsPositionItem: any,
    depth: number,
    maxDepth: number,
    errPositions: any[],
  ) => {
    if (depth === maxDepth) return errorsPositionItem;
    return filterPosition(
      errPositions[depth],
      depth + 1,
      maxDepth,
      errPositions,
    );
  };

  const getPosition = (errPositions, loc, msg, firstMention) => {
    let errPosition = filterPosition(
      errPositions[0],
      0,
      errPositions.length - 1,
      errPositions,
    );
    if (errPosition.length === 0) {
      errPosition = filterPosition(
        errPositions[0],
        0,
        errPositions.length - 2,
        errPositions,
      );
    }
    if (errPosition.length > 0) {
      const err = errPosition[0];
      if (err)
        return {
          position: {
            startLineNumber: err.range.startLineNumber,
            startColumn: err.range.startColumn,
            endLineNumber: err.range.endLineNumber,
            endColumn: err.range.endColumn,
          },
          message: `${loc[loc.length - 1]}: ${msg}`,
        };
    }
    return {
      position: {
        startLineNumber: firstMention[0].range.startLineNumber,
        startColumn: 1,
        endLineNumber: firstMention[0].range.endLineNumber,
        endColumn: firstMention[0].range.endColumn,
      },
      message: `${loc[loc.length - 1]}: ${msg}`,
    };
  };

  const getMarker = (prev, errMarkers) => {
    if (JSON.stringify(prev) !== JSON.stringify(errMarkers)) {
      return errMarkers;
    }
    return prev;
  };

  useEffect(() => {
    if (typeof errors !== 'string' && Array.isArray(errors) && yamlInstance) {
      const locations = errors.map(({ loc, msg }) => {
        const text = yamlInstance.getModel();

        const errPositions = findAllPositions(loc, text);

        const positivePositions = findPositivePosition(errPositions);

        if (positivePositions.length > 0) {
          return getPositivePositions(positivePositions, msg);
        }

        const firstMention = errPositions[0].filter(
          ({ range }) => range.startColumn === 1,
        );

        if (loc[0] === '__root__' || loc[0] === 'body') {
          return {
            position: {
              startLineNumber: 1,
              startColumn: 1,
              endLineNumber: 1,
              endColumn: 1000,
            },
            message: msg,
          };
        }

        if (firstMention.length > 0) {
          return getPosition(errPositions, loc, msg, firstMention);
        }
        return loc;
      });

      if (locations.filter(({ position }) => position).length > 0) {
        const errMarkers = locations
          .filter(({ position }) => position)
          .map((m) => ({
            ...m.position,
            message: m.message,
            code: '0',
            severity: 8,
            source: 'YAML',
          }));
        setMarkers((prev) => getMarker(prev, errMarkers));
      }
    }
  }, [errors, yamlInstance]);

  if (yamlInstance)
    yamlInstance.onDidChangeModelContent(() => {
      const newVal = yamlInstance.getModel().getValue();
      setLocalValue(typeof newVal === 'string' ? newVal : '');
    });

  useEffect(() => {
    if (yamlInstance) editor.setTheme(isDark ? 'vs-dark' : 'vs');
  }, [yamlInstance, theme]);

  useEffect(() => {
    if (yamlInstance) {
      const editorValue = yamlInstance.getModel().getValue();

      if ((!editorValue && value) || isFileUpload) {
        yamlInstance.setValue(value);
        setIsFileUpload(false);
      }

      if (editorValue !== value && isComponentsMovement) {
        yamlInstance.setValue(value);
        setIsComponentsMovement(false);
      }
    }
  }, [yamlInstance, isFileUpload, isComponentsMovement, value]);

  useEffect(() => {
    if (editor && yamlInstance) {
      const data = [...markers];

      const unique = data.filter(
        (val, index, self) =>
          index ===
          self.findIndex(
            (t) =>
              t.message === val.message &&
              t.startLineNumber === val.startLineNumber,
          ),
      );

      editor.setModelMarkers(yamlInstance.getModel(), 'yaml', unique);
    }
  }, [markers]);

  useEffect(() => {
    if (!yamlInstance) return;
    const errs = editor.getModelMarkers({});
    console.info({ errs });
  }, [yamlInstance]);

  useEffect(() => {
    const keyPressHandler = () => {
      if (yamlInstance) {
        setHighlightedValue([]);
        resetDecorations(yamlInstance, decorations);
      }
    };
    document.addEventListener('keypress', keyPressHandler);

    return () => document.removeEventListener('keypress', keyPressHandler);
  }, [yamlInstance, decorations]);

  useEffect(() => {
    if (yamlInstance) {
      resetDecorations(yamlInstance, decorations);
      if (selectedNodeId === undefined) {
        handleCleanDecorations();
        setHighlightedValue([]);
        return;
      }

      const possiblePositions = yamlInstance
        .getModel()
        .findMatches(`${selectedNodeId}:`, true, false, true, null, false);

      const componentsSectionPosition = yamlInstance
        .getModel()
        .findMatches('components:', true, false, true, null, false);

      const newHighlight = possiblePositions.filter(
        ({ range }) =>
          componentsSectionPosition[0].range.startLineNumber <
          range.endLineNumber,
      );

      yamlInstance.focus();
      yamlInstance.setPosition({
        lineNumber: newHighlight[0]?.range?.endLineNumber || 0,
        column: newHighlight[0]?.range?.endColumn || 0,
      });
      yamlInstance.revealLineInCenter(
        newHighlight[0]?.range?.endLineNumber || 0,
        1,
      );

      setHighlightedValue(newHighlight);
    }
  }, [selectedNodeId]);

  useEffect(() => {
    if (yamlInstance) {
      yamlInstance.updateOptions({
        readOnly: !isEditable,
        domReadOnly: !isEditable,
      });
    }
  }, [isEditable]);

  useEffect(() => {
    if (yamlInstance) {
      const highlights = highlightedValue.map((v) => ({
        range: new monaco.Range(
          v.range.startLineNumber,
          1,
          v.range.endLineNumber,
          1,
        ),
        options: {
          isWholeLine: true,
          className: 'myLineDecoration',
          minimap: { position: 1, color: '#4c9fc840', darkColor: '#4c9fc840' },
        },
      }));

      const newDecorations =
        yamlInstance.createDecorationsCollection(highlights);
      //@ts-ignore
      setDecorations(newDecorations?._decorationIds);
    }
  }, [yamlInstance, highlightedValue]);

  useEffect(() => {
    setOldValue(value);
  }, [isUpdated]);

  useEffect(() => {
    ref.current.childNodes.forEach((el, index) => {
      if (index === ref.current.childNodes.length - 1) {
        el.id = 'EditorView_Area-EditorAreaComponent';
        el.querySelectorAll('.margin')[0].id = 'EditorView_Area-LinesCounter';
        el.querySelectorAll('.monaco-scrollable-element')[0].id =
          'EditorView_Area-EditArea';
        el.querySelectorAll('.view-line').forEach((line) => {
          line.id = 'EditorView_Area-Line_Number';
        });
      } else {
        el.remove();
      }
    });
  }, [ref.current]);

  return (
    <Root>
      <Box ref={ref} sx={{ width: '100%', height: '100%' }} />
      <Prompt
        message="Do you want to leave the page without saving?"
        when={
          oldValue !== value &&
          !isUpdated &&
          !sessionStorage.getItem(REDIRECT_URI)
        }
      />
    </Root>
  );
};

EditArea.defaultProps = {
  setIsPaste: () => {},
  language: '',
};

export default EditArea;
