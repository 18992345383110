import { rootApi } from '..';
import { apiBaseUrlV1 } from '../../../env';
import { ISystemConfigurationTemplate } from '../../../typescript/interfaces/ISystemConfigurationTemplate.interface';

const BASE_URL = `${apiBaseUrlV1('structure/v1')}/systemConfigurationTemplates`;

const extendedApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getSystemConfigurationTemplates: builder.query<
      ISystemConfigurationTemplate[],
      undefined
    >({
      query: () => `${BASE_URL}`,
      providesTags: ['SystemConfigurationTemplates'],
    }),
    getSystemConfigurationTemplateById: builder.query<
      ISystemConfigurationTemplate[],
      string
    >({
      query: (id) => `${BASE_URL}/${id}`,
      providesTags: ['SystemConfigurationTemplates'],
    }),
    createSystemConfigurationTemplate: builder.mutation<
      ISystemConfigurationTemplate,
      string
    >({
      query: (data: string) => ({
        url: `${BASE_URL}`,
        method: 'POST',
        body: data,
        headers: { 'Content-Type': 'application/yaml' },
      }),
      invalidatesTags: ['SystemConfigurationTemplates'],
    }),
    updateSystemConfigurationTemplate: builder.mutation<
      ISystemConfigurationTemplate,
      { id: string; data: string }
    >({
      query: ({ id, data }) => ({
        url: `${BASE_URL}/${id}`,
        method: 'PUT',
        body: data,
        headers: { 'Content-Type': 'application/yaml' },
      }),
      invalidatesTags: ['SystemConfigurationTemplates'],
    }),
    deleteSystemConfigurationTemplate: builder.mutation<
      ISystemConfigurationTemplate,
      string
    >({
      query: (id) => ({
        url: `${BASE_URL}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['SystemConfigurationTemplates'],
    }),
  }),
});

export const {
  useGetSystemConfigurationTemplatesQuery,
  useGetSystemConfigurationTemplateByIdQuery,
  useCreateSystemConfigurationTemplateMutation,
  useUpdateSystemConfigurationTemplateMutation,
  useDeleteSystemConfigurationTemplateMutation,
} = extendedApi;
