import React from 'react';
import { Box } from '@mui/material';

interface Props {
  className?: string;
}

function PortAnchor(props: Props) {
  const { className } = props;

  return (
    <Box
      className={className}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
      }}
    >
      <div
        style={{
          transform: 'none',
          backgroundColor: '#C51C1C',
          boxSizing: 'inherit',
          border: '1px solid #fff',
          borderRadius: '100%',
          height: '6px',
          width: '6px',
        }}
      />
    </Box>
  );
}

PortAnchor.defaultProps = {
  className: '',
};

export default PortAnchor;
