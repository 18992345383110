import { SchemasSettings } from 'monaco-yaml';

const uri = 'https://procaaso/template-schema.json';

enum Types {
  Boolean = 'boolean',
  String = 'string',
  Number = 'number',
  Object = 'object',
  Array = 'array',
  Null = 'null',
}

const refSchema = {
  type: Types.String,
  description: 'Ref to visuals to get image and default flairs',
};

const flairsSchema = {
  type: Types.Object,
  description: 'Block to declare flairs for component',
  patternProperties: {
    '^.*$': {
      title: 'Component flair',
      anyOf: [Types.Object, Types.Null],
      properties: {
        backgroundColor: {
          type: Types.String,
          description: 'Background color for flair. Can be transparent',
        },
        default: {
          type: [Types.String, Types.Number, Types.Boolean],
          description: 'Default value for flair',
        },
        placement: {
          type: Types.Object,
          description: 'Flair position',
          properties: {
            x: {
              type: Types.Number,
              description: 'Horizontal position',
            },
            y: {
              type: Types.Number,
              description: 'Vertical position',
            },
          },
        },
        prefix: {
          type: Types.String,
          description: 'Flair prefix for value',
        },
        suffix: {
          type: Types.String,
          description: 'Flair suffix for value',
        },
        textColor: {
          type: Types.String,
          description: 'Text color for flair',
        },
        textSize: {
          type: Types.String,
          description: 'Size of the value for flair',
        },
        type: {
          type: Types.String,
          description: 'Flair type',
        },
        maximum: {
          type: Types.Number,
          description: 'Maximum value for bar flair',
        },
        orientation: {
          type: Types.String,
          description: `Orientation for bar flair. Might be VERTICAL or HORIZONTAL`,
        },
      },
    },
  },
};

const inputSchema = {
  type: Types.Object,
  patternProperties: {
    '^.*$': {
      title: 'Input property',
      type: Types.String,
      pattern: '\\$\\..+',
    },
  },
};

const sizeSchema = {
  type: Types.Object,
  description: 'Size of component',
  required: ['height', 'width'],
  properties: {
    height: { type: Types.Number },
    width: { type: Types.Number },
  },
};

const componentVisualsSchema = {
  title: 'Visuals',
  type: Types.Object,
  properties: {
    $ref: refSchema,
    flairs: flairsSchema,
    input: inputSchema,
    size: sizeSchema,
  },
};

const renderSchema = {
  type: Types.Array,
  title: 'Render',
  desciption: 'Array of positions. 1st element - x, 2nd - y',
  items: Types.Number,
  minItems: 2,
  maxItems: 2,
};

const portsSchema = {
  title: 'Ports',
  desciption: 'Ports',
  type: Types.Object,
  patternProperties: {
    '^.*$': {
      title: 'Port name',
      type: Types.Object,
      properties: {
        anchor: { type: Types.String },
      },
    },
  },
};

const attributesSchema = {
  title: 'Attributes',
  desciption: 'Attributes ',
  type: Types.Object,
  patternProperties: {
    '^.*$': {
      title: 'Attribute name',
      type: Types.Object,
      required: ['stateSchemaId', 'default'],
      properties: {
        stateSchemaId: { type: Types.String, description: 'State schema id' },
        default: {
          type: Types.Object,
          description: 'Default value',
          patternProperties: {
            '^.*$': {
              title: 'Attribute metrics',
              type: [Types.String, Types.Number, Types.Boolean],
            },
          },
        },
      },
    },
  },
};

const instrumentsSchema = {
  title: 'Instruments',
  type: Types.Object,
  required: true,
  patternProperties: {
    '^.*$': {
      title: 'Instrument',
      desciption: 'Name of instruments',
      type: Types.Object,
      properties: {
        attributes: attributesSchema,
      },
    },
  },
};

const componentsSchema = {
  type: Types.Object,
  required: ['instruments', 'ports', 'render'],
  properties: {
    instruments: instrumentsSchema,
    ports: portsSchema,
    render: renderSchema,
    visual: componentVisualsSchema,
  },
};

const connectorPortsSchema = {
  type: Types.Array,
  items: {
    type: Types.Object,
    properties: {
      $ref: {
        type: Types.String,
        pattern: '\\#\\/components',
      },
    },
  },
  minItems: 2,
  maxItems: 2,
};

const waypointsSchema = {
  type: Types.Array,
  items: {
    type: Types.Object,
    properties: {
      x: {
        type: Types.Number,
      },
      y: {
        type: Types.Number,
      },
    },
  },
};

const connectorsSchema = {
  type: Types.Object,
  desciption: 'Connector description',
  properties: {
    ports: connectorPortsSchema,
    waypoints: waypointsSchema,
    instruments: instrumentsSchema,
  },
};

const visualsSchema = {
  type: Types.Object,
  desciption: 'Connector description',
  properties: {
    flairs: flairsSchema,
    id: { type: Types.String },
    version: { type: Types.Number },
  },
};

const chartMetricsSchema = {
  type: Types.Array,
  items: {
    type: Types.Object,
    properties: {
      group: { type: Types.String },
      metrics: { type: Types.String },
    },
  },
};

const chartsSchema = {
  type: Types.Object,
  description: 'Used to select default metrics on chart',
  properties: {
    'Chart 1': chartMetricsSchema,
    'Chart 2': chartMetricsSchema,
  },
};
const metricsSchema = {
  type: Types.Object,
  description: 'Metrics name to compare with real attribute for charts filter',
  patternProperties: {
    '^.*$': {
      title: 'Instrument name',
      desciption: 'Name of instruments',
      type: Types.Object,
      patternProperties: {
        '^.*$': {
          title: 'Component name',
          type: Types.Object,
          properties: {
            query: {
              title: 'Query string',
              description: 'Used to connect with existing attribute',
              type: Types.String,
              pattern: '\\$\\..+',
            },
          },
        },
      },
    },
  },
};

export const templateSchema: SchemasSettings[] = [
  {
    uri,
    fileMatch: ['*'],
    schema: {
      type: Types.Object,
      required: ['name', 'description'],
      properties: {
        name: {
          type: Types.String,
          description: 'Name for template',
        },
        description: {
          type: Types.String,
          description: 'Description for template.',
        },
        operationMode: {
          type: Types.String,
          enum: ['BATCH_BASED'],
        },
        trend: {
          title: 'Trends properties',
          type: Types.Object,
          description: 'Describe properties for trends tab for child systems.',
          uniqueItems: true,
          properties: {
            charts: chartsSchema,
            metrics: metricsSchema,
          },
        },
        components: {
          title: 'Components properties',
          type: Types.Object,
          description:
            'Components field describes components of the system template.',
          uniqueItems: true,
          patternProperties: { '^.*$': { $ref: '#/$defs/components' } },
        },
        connectors: {
          title: 'Connectors properties',
          type: Types.Object,
          description:
            'Connectors block describes connectors of the system template.',
          uniqueItems: true,
          patternProperties: { '^.*$': { $ref: '#/$defs/connectors' } },
        },
        visuals: {
          title: 'Visuals properties',
          type: Types.Object,
          description: 'Visuals field describes',
          uniqueItems: true,
          patternProperties: { '^.*$': { $ref: '#/$defs/visuals' } },
        },
      },
      $defs: {
        components: componentsSchema,
        connectors: connectorsSchema,
        visuals: visualsSchema,
      },
    },
  },
];

/* eslint-disable no-useless-escape */
export const yamlSchema: SchemasSettings[] = [
  {
    uri,
    fileMatch: ['*'],
    schema: {
      id: uri,
      type: Types.Object,
      properties: {
        name: {
          type: Types.String,
        },
        description: {
          type: Types.String,
        },
        components: {
          title: 'Components',
          description:
            'Components field describes components of the system template.',
          patternProperties: {
            '^.*$': {
              description: 'This property will be used as component name',
              type: Types.Object,
              properties: {
                instruments: {
                  title: 'Component instruments',
                },
                render: {
                  title: 'Component render property',
                  description: `
Rendering position starting from the center of component
x - first argument, y - second argument
    position:
      - -300
      - 300`,
                },
                ports: {
                  title: 'Used ports description',
                  patternProperties: {
                    '^.*$': {
                      description:
                        "This property will be used as component's port name",
                      properties: {
                        anchor: {
                          type: Types.String,
                        },
                      },
                      required: ['anchor'],
                    },
                  },
                },
                visual: {
                  type: Types.Object,
                  properties: {
                    $ref: {
                      description:
                        'ref to some visual from visuals root property',
                    },
                    flairs: {
                      type: Types.Object,
                      patternProperties: {
                        '^.*$': {
                          type: Types.Object,
                          placement: {
                            type: Types.Object,
                            description: `
Rendering position starting from the center of component
x - first argument, y - second argument
    position:
      x: -300
      y: 300`,
                            properties: {
                              x: { type: Types.Number },
                              y: { type: Types.Number },
                            },
                          },
                          default: { type: [Types.String, Types.Number] },
                          suffix: {
                            type: Types.String,
                          },
                          textSize: {
                            type: Types.String,
                          },
                          required: ['placement'],
                        },
                      },
                    },
                    input: {},
                    size: {
                      type: Types.Object,
                      description: `
Size of the component in pixels

    size:
      width: 300
      height: 300`,
                      properties: {
                        width: { type: Types.Number },
                        height: { type: Types.Number },
                      },
                      reqired: ['width', 'height'],
                    },
                  },
                },
                required: ['instruments', 'render'],
              },
            },
          },
        },
        connectors: {
          title: 'Connectors properties',
          description:
            'Connectors field describes connectors of the system template.',
          patternProperties: {
            '^.*$': {
              description: 'This property will be used as connector name',
              type: Types.Object,
              properties: {
                instruments: {
                  type: Types.Object,
                  required: true,
                },
                ports: {
                  title: 'Connection with 2 refs from/to',
                },
              },
              required: ['instruments', 'ports'],
            },
          },
        },
        visuals: {
          type: Types.Object,
          patternProperties: {
            '^.*$': {
              type: Types.Object,
              title: 'Visuals name',
              properties: {
                flairs: {
                  type: Types.Object,
                  required: true,
                },
                id: {
                  type: Types.String,
                  required: true,
                },
                version: {
                  type: Types.Number,
                  required: true,
                },
              },
              reqired: ['flairs', 'id', 'version'],
              additionalProperties: false,
            },
          },
        },
      },
    },
  },
];
