import React, { useEffect, useState } from 'react';
import { Resizable } from 're-resizable';
import { Handle, Position } from 'react-flow-renderer';
import { Box, styled } from '@mui/material';
import { useGetPortsQuery } from '../../../../redux/services/ports/api';

const SVG = styled('svg')({
  width: '100%',
  height: '100%',
});
interface Props {
  id: string;
}

function Valve(props: Props) {
  const { id } = props;

  const { data: ports } = useGetPortsQuery(undefined);

  const [port, setPort] = useState([]);
  useEffect(() => {
    if (ports) {
      setPort(ports.filter(({ parentAggregate }) => parentAggregate === id));
    }
  }, [ports]);

  return (
    <Box
      component={Resizable}
      defaultSize={{
        width: 64,
        height: 50,
      }}
      lockAspectRatio
      enable={{
        top: false,
        right: false,
        bottom: false,
        left: false,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false,
      }}
      handleClasses={{
        bottomRight: 'resize-handle',
      }}
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        '&:hover .edit-btn': {
          opacity: 1,
        },
        '&:hover .resize-handle': {
          display: 'inline-block',
        },
        '& .resize-handle': {
          width: '20% !important',
          height: '20% !important',
          right: '-13% !important',
          bottom: '-16% !important',
          display: 'none',
        },
      }}
    >
      <div>
        {port.length ? (
          port.map((p, i) => (
            <Handle
              key={p._id}
              className="point"
              type={p.name === 'Outlet' ? 'target' : 'source'}
              position={
                [Object.values(Position)[0], Object.values(Position)[2]][i]
              }
              style={{ top: '65%' }}
            />
          ))
        ) : (
          <>
            <Handle
              className="point"
              type="target"
              position={Position.Left}
              style={{ top: '65%' }}
            />
            <Handle
              className="point"
              type="source"
              position={Position.Right}
              style={{ top: '65%' }}
            />
          </>
        )}
        <SVG
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          // width="64"
          // height="50"
          viewBox="0 0 257 203.436"
        >
          <defs>
            <linearGradient
              id="linear-gradient"
              x1="0.5"
              y1="1"
              x2="0.5"
              gradientUnits="objectBoundingBox"
            >
              <stop offset="0" stopColor="#ccc" />
              <stop offset="0.16" stopColor="#dbdbdb" />
              <stop offset="0.44" stopColor="#fff" />
              <stop offset="0.61" stopColor="#fbfbfb" />
              <stop offset="0.76" stopColor="#f1f1f1" />
              <stop offset="0.9" stopColor="#dfdfdf" />
              <stop offset="1" stopColor="#ccc" />
            </linearGradient>
          </defs>
          <g id="valve" transform="translate(-332.9 -316.1)">
            <path
              id="Path_22"
              data-name="Path 22"
              d="M461.339,568.463,333.4,494.6V642.325Z"
              transform="translate(0 -123.656)"
              stroke="#000"
              strokeMiterlimit="10"
              strokeWidth="1"
              fill="url(#linear-gradient)"
            />
            <path
              id="Path_23"
              data-name="Path 23"
              d="M750.2,568.463,878.139,494.6V642.325Z"
              transform="translate(-288.739 -123.656)"
              stroke="#000"
              strokeMiterlimit="10"
              strokeWidth="1"
              fill="url(#linear-gradient)"
            />
            <path
              id="Path_24"
              data-name="Path 24"
              d="M743.034,451.326l-3.134-1.782.031-123.944h6.145l-.031,124.067Z"
              transform="translate(-281.603 -6.581)"
            />
            <rect
              id="Rectangle_35"
              data-name="Rectangle 35"
              width="80.253"
              height="6.145"
              transform="translate(421.273 316.1)"
            />
          </g>
        </SVG>
      </div>
    </Box>
  );
}

export default Valve;
