import React, { useState, useMemo } from 'react';
import {
  Box,
  Grid,
  IconButton,
  Typography,
  alpha,
  styled,
  Tooltip as RegularTooltip,
} from '@mui/material';
import {
  BrokenImageRounded,
  KeyboardArrowRight,
  SearchRounded,
} from '@mui/icons-material';
import Search from '../../../common/Search';
import { LightTooltip } from '../../../common/LightTooltip';
import {
  useGetVisualAssetQuery,
  useGetVisualsQuery,
} from '../../../redux/services/visuals/api';
import { apiBaseUrlV1 } from '../../../env';
import { EmptyBox, Bold, Light } from '../../../common/styles/empty';
import { VisualResponse } from '../../../redux/services/visuals/types';
import { PreviewItemComponent } from '../../../common/Diagram/library/Modal';

const Root = styled(Box)(({ theme }) => ({
  position: 'absolute',
  right: 0,
  top: 0,
  bottom: 0,
  zIndex: 9,
  display: 'flex',
  flexDirection: 'column',
  width: '300px',
  backgroundColor: theme.palette.background.default,
  borderLeft: `1px solid ${theme.palette.divider}`,
  paddingBottom: theme.spacing(1),
}));

const GridItem = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '80px',
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: '#fff',
  '&.active': {
    backgroundColor:
      theme.palette.mode === 'light'
        ? alpha(theme.palette.secondary.main, 0.15)
        : '#e9f1f7',
    borderColor: theme.palette.secondary.main,
  },
  '&:hover': {
    backgroundColor:
      theme.palette.mode === 'light'
        ? alpha(theme.palette.secondary.main, 0.15)
        : '#e9f1f7',
  },
}));

const ImagePreview = styled('img')(() => ({
  maxWidth: '100%',
  maxHeight: '100%',
}));

const CloseBtn = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: 'calc(50% - 20px)',
  height: 40,
  width: 32,
  left: -32,
  borderTopRightRadius: 0,
  borderBottomRightRadius: 0,
  borderRight: '0 !important',
  backgroundColor: theme.palette.background.default,
  '&:hover': {
    backgroundColor: theme.palette.background.default,
  },
}));

const Tooltip = styled(LightTooltip)({
  '& .MuiTooltip-tooltip': {
    backgroundColor: '#fff',
    borderColor: '#EBF4F9',
  },
});

interface TooltipProps {
  name: string;
  visual: VisualResponse;
}

function TooltipContent(props: TooltipProps) {
  const { name, visual } = props;
  const { id, version } = visual;
  const { data: imageText, isLoading } = useGetVisualAssetQuery({
    id,
    version,
  });

  const image = useMemo(() => {
    const svg = new Blob([imageText], { type: 'image/svg+xml' });
    const url = URL.createObjectURL(svg);
    return url;
  }, [imageText]);

  return (
    <Box sx={{ textAlign: 'center' }}>
      <Box
        sx={{
          width: 100,
          height: 100,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          mb: 2,
          mx: 'auto',
        }}
      >
        {!image || isLoading ? (
          <BrokenImageRounded
            sx={{
              color: 'secondary.main',
              opacity: 0.75,
              fontSize: '3rem',
            }}
          />
        ) : (
          <ImagePreview
            src={image}
            alt={name}
            id={`FlairsTooltip-${name.replace(/\s+/g, '-').toLowerCase()}`}
          />
        )}
      </Box>
      <Typography>{name}</Typography>
    </Box>
  );
}

interface Props {
  onHide: () => void;
}

function getVisualAssetUrl(id, version) {
  return `${apiBaseUrlV1('structure/v1')}/visuals/${id}/asset/${version}`;
}

export default function Sidebar({ onHide }: Props) {
  const [searchValue, setSearchValue] = useState('');
  const [activeVisualIdx, setActiveVisualIdx] = useState<number>(0);

  const { data: visuals = [] } = useGetVisualsQuery();

  const localVisuals = useMemo(
    () =>
      visuals
        .map((visual) => ({
          ...visual,
          image: getVisualAssetUrl(visual.id, visual.activeAssetVersion),
        }))
        .filter(
          (visual) =>
            !searchValue ||
            visual?.name?.toLowerCase().indexOf(searchValue?.toLowerCase()) >
              -1,
        ),
    [searchValue, visuals],
  );

  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData('application/reactflow', nodeType);
    event.dataTransfer.effectAllowed = 'move';
  };

  const handleActive = (value: number) => {
    setActiveVisualIdx(value);
  };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleClear = () => {
    setSearchValue('');
  };

  return (
    <Root>
      <RegularTooltip title="Close" placement="left">
        <CloseBtn className="outlined" onClick={onHide}>
          <KeyboardArrowRight />
        </CloseBtn>
      </RegularTooltip>
      <Box sx={{ p: 2 }}>
        <Search
          value={searchValue}
          onCancel={handleClear}
          onSearch={handleSearchChange}
          defaultExpanded
          placeholder="Search visuals"
        />
      </Box>
      <Box sx={{ flex: 1, overflow: 'auto', px: 2 }}>
        <Grid container spacing={1}>
          {localVisuals.length > 0 ? (
            localVisuals.map((v, index) => (
              <Grid key={v.id} item xs={4}>
                <Tooltip
                  placement="left"
                  title={<TooltipContent name={v.name} visual={v} />}
                  sx={{
                    transform: 'none !important',
                    top: '50% !important',
                    right: '350px !important',
                  }}
                >
                  <GridItem
                    className={index === activeVisualIdx ? 'active' : ''}
                    onClick={() => handleActive(index)}
                    onDragStart={(event) => onDragStart(event, v.id)}
                    draggable
                  >
                    <PreviewItemComponent visual={v} />
                  </GridItem>
                </Tooltip>
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <EmptyBox sx={{ height: '100%' }}>
                <SearchRounded className="icon" />
                <Bold>No element found</Bold>
                <Light>Try searching different word</Light>
              </EmptyBox>
            </Grid>
          )}
        </Grid>
      </Box>
    </Root>
  );
}
