import axios from 'axios';
import { apiBaseUrlV1 as urlv1 } from '../env';

export const getSystemConfigurationTemplateByIdDocument = async (
  id: string,
  urlString = 'structure/v1',
): Promise<any> => {
  const response = await axios.get(
    `${urlv1(urlString)}/systemConfigurationTemplates/${id}/document`,
  );
  return response.data;
};
export const getProjectConfigurationTemplateByIdDocument = async (
  id: string,
  urlString = 'structure/v1',
): Promise<any> => {
  const response = await axios.get(
    `${urlv1(urlString)}/projects/${id}/document`,
  );
  return response.data;
};
