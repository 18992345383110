import { VisualResponse, GetVisualAssetParams } from './types';
import { apiBaseUrlV1 } from '../../../env';
import { rootApi } from '..';

const BASE_URL = apiBaseUrlV1('structure/v1/visuals');

const extendedApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getVisuals: builder.query<VisualResponse[], void>({
      query: () => `${BASE_URL}`,
      providesTags: ['Visuals'],
    }),
    getVisual: builder.query<VisualResponse, string>({
      query: (id) => `${BASE_URL}/${id}`,
      providesTags: (_, __, id) => [{ type: 'Visuals', id }],
    }),
    getVisualDocument: builder.query<string, string>({
      query: (id) => ({
        url: `${BASE_URL}/${id}/document`,
        responseHandler: (response) => response.text(),
      }),
      providesTags: (_, __, id) => [{ type: 'VisualDocuments', id }],
    }),
    getVisualAsset: builder.query<string, GetVisualAssetParams>({
      query: ({ id, version }) => ({
        url: `${BASE_URL}/${id}/asset/${version}`,
        responseHandler: (response) => response.text(),
      }),
      providesTags: (_, __, params) => [
        { type: 'VisualAssets', id: params.id },
      ],
    }),
    createVisual: builder.mutation<VisualResponse, FormData>({
      query: (form) => ({
        url: BASE_URL,
        method: 'POST',
        body: form,
      }),
      invalidatesTags: ['Visuals', 'VisualDocuments', 'VisualAssets'],
    }),
    updateVisual: builder.mutation<
      VisualResponse,
      { id: string; form: FormData }
    >({
      query: ({ id, form }) => ({
        url: `${BASE_URL}/${id}`,
        method: 'PUT',
        body: form,
      }),
      invalidatesTags: ['Visuals', 'VisualDocuments', 'VisualAssets'],
    }),
    deleteVisual: builder.mutation<void, string>({
      query: (id) => ({
        url: `${BASE_URL}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Visuals', 'VisualDocuments', 'VisualAssets'],
    }),
  }),
});

export const {
  useGetVisualsQuery,
  useGetVisualQuery,
  useGetVisualDocumentQuery,
  useGetVisualAssetQuery,
  useCreateVisualMutation,
  useUpdateVisualMutation,
  useDeleteVisualMutation,
} = extendedApi;
