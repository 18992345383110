import { handleActions } from 'redux-actions';
import { setIsDemo } from './actions';

const initialState = JSON.parse(localStorage.getItem('isDemo') || 'false');

const isDemoReducer = handleActions(
  {
    [setIsDemo]: (state) => !state,
  },
  initialState,
);

export default isDemoReducer;
