import { rootApi } from '..';
import { apiBaseUrlV1 } from '../../../env';

const BASE_URL = apiBaseUrlV1('ubiety/v1/env/');

type EnvMetadata = {
  environmentName: string;
};

const extendedApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getMetadata: builder.query<EnvMetadata, void>({
      query: () => BASE_URL,
    }),
  }),
});

export const { useGetMetadataQuery } = extendedApi;
export const envLogoSrc = `${BASE_URL}logo.svg`;
