import { Box, styled } from '@mui/material';

export const Root = styled(Box)(({ theme }) => ({
  background:
    theme.palette.mode === 'dark'
      ? theme.palette.gradient.main
      : theme.palette.background.paper,
  border: '1px solid',
  borderRadius: theme.shape.borderRadius,
  borderColor: theme.palette.divider,
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  maxHeight: '640px',
  padding: theme.spacing(3),
  width: '100%',
  [theme.breakpoints.down('md')]: {
    background: theme.palette.background.default,
    border: 0,
    height: '100%',
    maxHeight: '100%',
    padding: 0,
    paddingTop: theme.palette.mode === 'dark' ? '0.95rem' : 0,
  },
  '& .map-root': {
    height: 'auto',
    minHeight: '280px',
    flex: 1,
    width: '100% !important',
    borderRadius: theme.shape.borderRadius,
    [theme.breakpoints.down('md')]: {
      height: 'calc(100% - 26px)',
    },
  },
  '& .MuiInputLabel-root': {
    display: 'none',
  },
  '@media(min-width: 1920.1px)': {
    maxHeight: 'calc(60vh - 160px)',
  },
}));

export const Head = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(1),
  position: 'relative',
}));
