import React, { memo } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { ListTabs } from './styles';
import SystemItem from '../Items/SystemListItem';
import { ISystem } from '../../../typescript/interfaces/system.interface';

interface ISystemList {
  systems: ISystem[];
  onClickAction: (id: string) => void;
  selectedItem?: string;
  size: { height: number; width: number };
  className?: string;
}

const SystemListNew = (props: ISystemList) => {
  const { systems, onClickAction, selectedItem, size, className } = props;
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const { height } = size;

  if (systems.length === 0) {
    return null;
  }
  return (
    <ListTabs
      height={height}
      itemCount={systems.length}
      itemSize={78}
      className={className}
      overscanCount={30}
      width="100%"
    >
      {({ index, style }) => (
        <SystemItem
          key={systems[index].id}
          systemId={systems[index].id}
          systemName={systems[index].name}
          systemLock={systems[index].locked}
          style={style}
          onClickAction={onClickAction}
          selectedItem={isDesktop ? selectedItem : undefined}
        />
      )}
    </ListTabs>
  );
};

SystemListNew.defaultProps = {
  selectedItem: undefined,
  className: '',
};

export default memo(
  SystemListNew,
  (prevProps, nextProps) =>
    !(
      JSON.stringify(prevProps.systems) !== JSON.stringify(nextProps.systems) ||
      prevProps.onClickAction !== nextProps.onClickAction ||
      prevProps.selectedItem !== nextProps.selectedItem ||
      prevProps.size !== nextProps.size
    ),
);
