import {
  Box,
  FormControl,
  IconButton,
  styled,
  Typography,
} from '@mui/material';
import { Link, LinkProps } from 'react-router-dom';

export const Container = styled(Box)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? theme.palette.background.default
      : '#FCFBFD',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  flexDirection: 'column',
}));

export const AuthBox = styled(Box)(({ theme }) => ({
  width: '90%',
  maxWidth: '436px',
  padding: '30px 40px',
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`,
  background:
    theme.palette.mode === 'dark'
      ? theme.palette.gradient.main
      : theme.palette.background.paper,
  margin: '16px 0',
  [theme.breakpoints.down('sm')]: {
    maxWidth: '352px',
  },
  '& a': {
    color: 'red',
  },
}));

export const LogoWrap = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: theme.spacing(4),
}));

export const Logo = styled('img')({
  width: '100%',
  maxWidth: '170px',
});

export const SubTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.5rem',
  color: theme.palette.text.secondary,
  textAlign: 'center',
  marginBottom: '1.75rem',
  fontWeight: 600,
}));

export const Text = styled(Typography)(({ theme }) => ({
  fontSize: '1.125rem',
  color: theme.palette.text.secondary,
  textAlign: 'center',
  marginBottom: '2.5rem',
}));

export const BackBtn = styled(IconButton)<LinkProps>(({ theme }) => ({
  width: '2.5rem',
  height: '3rem',
  border: `1px solid ${theme.palette.divider}`,
  color: theme.palette.text.secondary,
  position: 'absolute',
  top: 50,
  left: 128,
  [theme.breakpoints.down('md')]: {
    left: 20,
  },
}));

export const Control = styled(FormControl)(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginBottom: 0,
  width: '100%',
}));

export const ErrorInfo = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.error.main,
  justifyContent: 'center',
  width: '100%',
  fontSize: '1.125rem',
  '& svg': {
    marginRight: theme.spacing(1),
  },
}));

export const AuthLink = styled(Link)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '1.125rem',
  textDecoration: 'none',
}));

export const RememberWrap = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: theme.spacing(3),
  marginBottom: '2.5rem',
}));
